import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '@app/common/angular-material.module';
import { TranslateModule } from '@ngx-translate/core';

export interface ProjectWorkflowDialogData {
  title: string;
  titleIcon?: string;
  titleIconColor?: string;
  textUp?: string;
  textDown?: string;
  image?: string;
  comment?: string;
  commentPlaceHolder?: string;
  checkboxText?: string;
  informations?: string;
  buttonText?: string;
  button2Text?: string;
  cancelButtonText: string;
  showImage?: boolean;
  textarea?: boolean;
}

export interface ProjectWorkflowReturnValue {
  comment?: string;
  buttonId: number;
}

@Component({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    AngularMaterialModule,
    TranslateModule,
  ],
  standalone: true,
  selector: 'parteng-project-workflow-dialog',
  template: `
    <h2 mat-dialog-title align="end" class="px-5">
      <mat-icon
        aria-hidden="false"
        matDialogClose
        [attr.aria-label]="'shared.dialog.closeAriaLabel' | translate"
        class="text-neutral-500 cursor-pointer picto"
        >close</mat-icon
      >
    </h2>

    <div class="text-center">
      <div class="flex items-center w-full text-center justify-center">
        <mat-icon [ngStyle]="{ color: data.titleIconColor }">{{ data.titleIcon }}</mat-icon>
        <span class="ml-2 text-lg"> {{ data.title }}</span>
      </div>
    </div>

    <div mat-dialog-content>
      <div class="text-center mx-auto w-4/5 text-base mb-5" *ngIf="data.textUp" [innerHTML]="data.textUp"></div>

      <ng-container *ngIf="!!data.image">
        <div *ngIf="!data.showImage" class="my-5 text-center font-semibold cursor-pointer">
          <div *ngIf="!showInstructions" (click)="showInstructions = true" class="flex items-center justify-center">
            <mat-icon>visibility</mat-icon>
            <span class="ml-2">{{ 'project.workflow.showInstructions' | translate }}</span>
          </div>
          <div *ngIf="showInstructions" (click)="showInstructions = false">
            <div class="inline-flex items-center">
              <span><mat-icon>visibility_off</mat-icon></span>
              <span class="ml-2">{{ 'project.workflow.hideInstructions' | translate }}</span>
            </div>
            <img [src]="data.image" alt="" class="block mx-auto my-4" />
            <div
              class="text-center mx-auto w-4/5  mb-5 font-normal"
              *ngIf="data.textDown"
              [innerHTML]="data.textDown"
            ></div>
          </div>
        </div>

        <div *ngIf="data.showImage" class="block mx-auto mb-5">
          <img [src]="data.image" alt="" class="mx-auto" />
        </div>
      </ng-container>

      <div *ngIf="data.comment !== undefined" class="list-detail mb-5">
        <div class="text-xs text-neutral-400">
          {{ data.commentPlaceHolder }}
        </div>
        <div>
          {{ data.comment }}
        </div>
      </div>

      <mat-form-field *ngIf="!!data.textarea" appearance="fill" class="w-full">
        <textarea
          matInput
          class="w-full h-20"
          [placeholder]="data.commentPlaceHolder || ''"
          [(ngModel)]="comment"
        ></textarea>
      </mat-form-field>

      <div *ngIf="data.checkboxText" class="mb-4">
        <mat-checkbox
          class="mr-2"
          data-testId="workflow-checkbox"
          id="workflow-checkbox"
          [(ngModel)]="checkboxChecked"
          #cb
        >
        </mat-checkbox>
        <!-- why not inside the mat-checkbox? -->
        <!-- because it does not word break! (https://github.com/angular/components/issues/8416) -->
        <span class="cursor-pointer" (click)="cb.toggle()"> {{ data.checkboxText }}</span>
      </div>

      <div *ngIf="data.informations" class="mb-4">{{ data.informations }}</div>

      <div class="flex items-center justify-around">
        <button
          *ngIf="!!data.buttonText"
          type="button"
          mat-raised-button
          color="primary"
          data-testId="confirm-workflow-button"
          [disabled]="(!!data.textarea && !comment) || (!!data.checkboxText && !checkboxChecked)"
          (click)="submitWorkflow(1)"
        >
          {{ data.buttonText }}
        </button>

        <button
          *ngIf="!!data.button2Text"
          type="button"
          mat-raised-button
          color="primary"
          [disabled]="(!!data.textarea && !comment) || (!!data.checkboxText && !checkboxChecked)"
          (click)="submitWorkflow(2)"
          data-testId="confirm-workflow-2-button"
        >
          {{ data.button2Text }}
        </button>

        <button *ngIf="!!data.cancelButtonText" type="button" matDialogClose mat-flat-button>
          {{ data.cancelButtonText }}
        </button>
      </div>
    </div>
  `,
})
export class ProjectWorkflowDialogComponent {
  showInstructions = false;
  comment = '';
  checkboxChecked = false;

  constructor(
    private dialogRef: MatDialogRef<ProjectWorkflowDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProjectWorkflowDialogData
  ) {}

  submitWorkflow(buttonId: number) {
    this.dialogRef.close({ buttonId, comment: this.comment });
  }
}
