import { Injectable } from '@angular/core';
import { SettingsService } from '@app/data-entry/services/settings.service';
import { ProjectValidationStatusFiltersGroup } from '@app/project/components/projects-list-combined-filters/projects-list-combined-filters.component';
import {
  ProjectValidationStatus,
  ProjectValidationStatusColors,
  ProjectValidationStatusId,
} from '@app/project/models/project-validation-status.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ProjectValidationStatusService {
  constructor(private settingsService: SettingsService, private translateService: TranslateService) {}

  getProjectValidationStatusId(projectValidationStatus: ProjectValidationStatus): ProjectValidationStatusId {
    return this.settingsService.get<ProjectValidationStatusId>(projectValidationStatus)!;
  }

  getProjectValidationStatusLabel(projectValidationStatus: ProjectValidationStatus): string {
    return this.translateService.instant(`project.validationStatus.${projectValidationStatus}`);
  }

  getProjectValidationStatusColors(
    projectValidationStatusId: ProjectValidationStatusId
  ): ProjectValidationStatusColors {
    const colors = {
      [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_CREATED')!]: {
        textColor: 'green',
        backgroundColor: 'white',
        borderColor: 'green',
      },
      [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_INPUT')!]: {
        textColor: 'white',
        backgroundColor: 'green',
        borderColor: 'green',
      },
      [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_VERIFICATION_REJECTED')!]: {
        textColor: 'white',
        backgroundColor: 'red',
        borderColor: 'red',
      },
      [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_VALIDATION_REJECTED')!]: {
        textColor: 'white',
        backgroundColor: 'red',
        borderColor: 'red',
      },
      [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_VERIFICATION')!]: {
        textColor: 'white',
        backgroundColor: 'green',
        borderColor: 'green',
      },
      [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_VALIDATION')!]: {
        textColor: 'white',
        backgroundColor: 'green',
        borderColor: 'green',
      },
      [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_VALIDATED')!]: {
        textColor: 'grey',
        backgroundColor: 'white',
        borderColor: 'grey',
      },
      [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_CLOSED')!]: {
        textColor: 'white',
        backgroundColor: 'grey',
        borderColor: 'grey',
      },
    }[projectValidationStatusId];
    if (!colors) {
      throw new Error(`No colors found for project validation status ${projectValidationStatusId}`);
    }
    return colors;
  }

  getAllProjectValidationStatusGroups(): ProjectValidationStatusFiltersGroup[] {
    return [
      {
        name: this.translateService.instant('project.validationStatusBlock.creation'),
        status: ['PROJECT_VALIDATION_STATUS_CREATED'],
      },
      {
        name: this.translateService.instant('project.validationStatusBlock.input'),
        status: ['PROJECT_VALIDATION_STATUS_INPUT', 'PROJECT_VALIDATION_STATUS_VERIFICATION_REJECTED'],
      },
      {
        name: this.translateService.instant('project.validationStatusBlock.verification'),
        status: ['PROJECT_VALIDATION_STATUS_VERIFICATION', 'PROJECT_VALIDATION_STATUS_VALIDATION_REJECTED'],
      },
      {
        name: this.translateService.instant('project.validationStatusBlock.validation'),
        status: ['PROJECT_VALIDATION_STATUS_VALIDATION'],
      },
      {
        name: this.translateService.instant('project.validationStatusBlock.validated'),
        status: ['PROJECT_VALIDATION_STATUS_VALIDATED', 'PROJECT_VALIDATION_STATUS_CLOSED'],
      },
    ];
  }
}
