import { SelectionModel } from '@angular/cdk/collections';
import { NgForOf, NgIf, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { EntityWithId, TableColumnDef } from '@app/common/models';
import { ApplyPipePipe } from '@app/common/pipes/apply-pipe.pipe';
import { FillEmptyPipe } from '@app/common/pipes/fill-empty.pipe';
import { PersonTypeToTranslatedLabelPipe } from '@app/common/pipes/person-type-to-translated-label.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [MatTableModule, MatCheckboxModule, TranslateModule, NgStyle, NgIf, ApplyPipePipe, NgForOf, MatSortModule],
  selector: 'parteng-item-selector-table',
  providers: [FillEmptyPipe, PersonTypeToTranslatedLabelPipe],
  template: `
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortDisableClear
      [fixedLayout]="true"
      class="w-full item-selector-table"
    >
      <!-- Checkbox Column -->
      <ng-container *ngIf="isMonoSelection === false" matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="onCheckboxClick(row)"
            [checked]="checkboxSelection.isSelected(row)"
            [aria-label]="
              (checkboxSelection.isSelected(row)
                ? 'shared.dialogItemSelector.ariaLabel.itemSelected'
                : 'shared.dialogItemSelector.ariaLabel.itemUnselected'
              ) | translate
            "
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- TH/TD templates -->
      <ng-container *ngFor="let columnDef of columnDefs" [matColumnDef]="columnDef.key">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="text-blue-ptg-secondary-500"
          [ngStyle]="columnDef.width ? { width: columnDef.width } : {}"
        >
          {{ columnDef.labelTranslateKey | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="columnDef.pipeName">
            {{ element[columnDef.key] | applyPipe: columnDef.pipeName:columnDef.pipeArgs }}
          </ng-container>
          <ng-container *ngIf="!columnDef.pipeName">{{ element[columnDef.key] }}</ng-container>
        </td>
      </ng-container>
      <!-- TR templates -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        (click)="onRowClick(row)"
        (dblclick)="onCheckboxClick(row)"
        class="select-none cursor-pointer"
        [class.bg-neutral-100]="isHighlighted(row)"
        *matRowDef="let row; columns: displayedColumns; let i = index"
        [attr.data-testId-row]="i"
      ></tr>
    </table>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemSelectorTableComponent<T extends EntityWithId> implements OnInit, AfterViewInit, OnChanges {
  @Input() columnDefs: TableColumnDef[] = [];
  @Input() highlightedItem: T | undefined;
  @Input() isMonoSelection: boolean = true;
  @Input() items: T[] = [];
  @Input() selectedItems: T[] = [];
  @Input() filterItemFn!: (item: T, filter: string) => boolean;
  @Input() filterText: string | undefined;

  @Output() rowClicked = new EventEmitter<T>();
  @Output() checkboxClicked = new EventEmitter<T[]>();

  @ViewChild(MatSort) sort!: MatSort;

  dataSource: MatTableDataSource<T> = new MatTableDataSource();
  displayedColumns!: string[];
  checkboxSelection = new SelectionModel<T>(true, [], true);

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    const columnKeys = this.columnDefs.map((def) => def.key);
    this.displayedColumns = this.isMonoSelection === true ? columnKeys : ['select', ...columnKeys];
  }

  // Needed because mat-sort
  ngAfterViewInit(): void {
    this.dataSource.filterPredicate = this.filterItemFn;
    this.setDataSource(this.items);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes !== undefined && (changes['items'] !== undefined || changes['selectedItems'] !== undefined)) {
      this.setDataSource(this.items);
    }

    if (changes !== undefined && changes['filterText'] !== undefined) {
      this.dataSource.filter = this.filterText ?? '';
    }
  }

  setDataSource(items: T[]): void {
    // Update the data source based on the input items array after filtering and accounting for any previously selected items
    const newData = items.filter((item) => this.isMonoSelection === true || this.isSelected(item) === false);
    this.dataSource.data = newData;
    // Preserve the previous selection by clearing checkboxSelection and re-selecting based on matching IDs in newData
    const selectedItemIds = this.checkboxSelection.selected.map((selectedItem) => selectedItem.id);

    this.checkboxSelection.setSelection(...newData.filter((item) => selectedItemIds.includes(item.id)));
    this.dataSource.sort = this.sort;
  }

  onRowClick(item: T) {
    this.rowClicked.emit(item);
  }

  onCheckboxClick(item: T) {
    this.checkboxSelection.toggle(item);
    this.checkboxClicked.emit(this.checkboxSelection.selected);
  }

  isHighlighted(item: T): boolean {
    return this.highlightedItem?.id === item.id;
  }

  isSelected(item: T): boolean {
    return this.selectedItems.some((i) => i.id === item.id);
  }

  // Clear all checkboxes (called from DialogItemSelectorComponent)
  clearCheckboxSelection() {
    this.checkboxSelection.clear();
    this.cdr.detectChanges();
  }
}
