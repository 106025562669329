import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Feature, RoleMatrix } from '@app/common/models/role.model';
import { catchError, firstValueFrom, of } from 'rxjs';
import { BackendError, ErrorService } from './error.service';
import { PermissionService } from './permission.service';

@Injectable({ providedIn: 'root' })
export class RoleService {
  private roleMatrix: RoleMatrix;

  constructor(
    private httpClient: HttpClient,
    private permissionService: PermissionService,
    private errorService: ErrorService
  ) {}

  async loadRoleMatrix() {
    this.roleMatrix = await firstValueFrom(
      this.httpClient.get<RoleMatrix>('assets/auth/role-matrix.json').pipe(
        catchError((error: HttpErrorResponse) => {
          const backendError: BackendError = {
            ...error,
            error: {
              errors: error.error,
              detail: error.message,
              status: error.status,
              title: error.statusText,
              type: error.url || '',
            },
          };
          this.errorService.onError(backendError);
          return of({} as RoleMatrix);
        })
      )
    );
  }

  canAccessFeature({ feature }: { feature: Feature | undefined }) {
    const userRoles = this.permissionService.getRoles();
    return feature !== undefined &&
      this.roleMatrix !== undefined &&
      feature in this.roleMatrix &&
      'readWrite' in this.roleMatrix[feature] &&
      'readonly' in this.roleMatrix[feature]
      ? userRoles.some(
          (role) =>
            (this.roleMatrix as NonNullable<RoleMatrix>)[feature]['readWrite'].includes(role) ||
            (this.roleMatrix as NonNullable<RoleMatrix>)[feature]['readonly'].includes(role)
        )
      : false;
  }

  canShowFeature({ feature }: { feature: Feature | undefined }) {
    const userRoles = this.permissionService.getRoles();
    return feature !== undefined &&
      this.roleMatrix !== undefined &&
      feature in this.roleMatrix &&
      'show' in this.roleMatrix[feature]
      ? userRoles.some((role) => (this.roleMatrix as NonNullable<RoleMatrix>)[feature]['show'].includes(role))
      : false;
  }

  canEditFeature({ feature }: { feature: Feature | undefined }) {
    const userRoles = this.permissionService.getRoles();
    return feature !== undefined &&
      this.roleMatrix !== undefined &&
      feature in this.roleMatrix &&
      'readWrite' in this.roleMatrix[feature]
      ? userRoles.some((role) => (this.roleMatrix as NonNullable<RoleMatrix>)[feature]['readWrite'].includes(role))
      : false;
  }

  canReadFeature({ feature }: { feature: Feature | undefined }) {
    const userRoles = this.permissionService.getRoles();
    return feature !== undefined &&
      this.roleMatrix !== undefined &&
      feature in this.roleMatrix &&
      'readonly' in this.roleMatrix[feature]
      ? userRoles.some((role) => (this.roleMatrix as NonNullable<RoleMatrix>)[feature]['readonly'].includes(role))
      : false;
  }
}
