import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, Observable } from 'rxjs';
import { BackendError, ErrorContext } from './error.service';

export interface IWaitService {
  startWait(task: any, param: any): void;
  stopWait(task: any, param: any): void;
}

export interface IErrorService {
  onError(error: BackendError, context?: ErrorContext): void;
}

export interface ICompletedService {
  onCompleted(param: string): void;
}

@Injectable({ providedIn: 'root' })
export class TaskService {
  private waitService: IWaitService | undefined;
  private errorService: IErrorService | undefined;
  private completedService: ICompletedService | undefined;

  constructor(private translateService: TranslateService) {}

  setWaitService(waitService: IWaitService) {
    this.waitService = waitService;
  }

  setErrorService(errorService: IErrorService) {
    this.errorService = errorService;
  }

  setCompletedService(completedService: ICompletedService) {
    this.completedService = completedService;
  }

  async do(promise: Promise<any>, params?: { wait?: any; errorContext?: ErrorContext; completed?: any }): Promise<any> {
    if (this.waitService !== undefined) {
      this.waitService.startWait(promise, params?.wait);
    }
    try {
      const data = await promise;
      if (this.waitService !== undefined) {
        this.waitService.stopWait(promise, params?.wait);
      }
      if (this.completedService !== undefined) {
        this.completedService.onCompleted(params?.completed);
      }
      return data;
    } catch (error: unknown) {
      if (this.waitService !== undefined) {
        this.waitService.stopWait(promise, params?.wait);
      }
      if (this.errorService !== undefined) {
        this.errorService.onError(error as BackendError, params?.errorContext);
      }
      throw error;
    }
  }

  doSave(promise: Promise<any>, errorContext?: ErrorContext): Promise<any> {
    return this.do(promise, {
      completed: this.translateService.instant('shared.success'),
      errorContext: errorContext === undefined ? undefined : errorContext,
    });
  }

  doDelete(promise: Promise<any>, errorContext?: ErrorContext): Promise<any> {
    return this.do(promise, {
      completed: this.translateService.instant('shared.deleteSuccess'),
      errorContext: errorContext === undefined ? undefined : errorContext,
    });
  }

  do$<T>(obs$: Observable<T>, params?: { wait?: any; errorContext?: any; completed?: any }): Promise<T> {
    return this.do(lastValueFrom(obs$), params);
  }

  doSave$(obs$: Observable<unknown>, errorContext?: ErrorContext): Promise<any> {
    return this.do(lastValueFrom(obs$), {
      completed: this.translateService.instant('shared.success'),
      errorContext: errorContext === undefined ? undefined : errorContext,
    });
  }

  doDelete$(obs$: Observable<unknown>, errorContext?: ErrorContext): Promise<any> {
    return this.do(lastValueFrom(obs$), {
      completed: this.translateService.instant('shared.deleteSuccess'),
      errorContext: errorContext === undefined ? undefined : errorContext,
    });
  }
}
