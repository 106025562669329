import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProjectValidationStatusService } from '@app/common/services/project-validation-status.service';
import { SettingsService } from '@app/data-entry/services/settings.service';
import { ProjectValidationStatusId } from '@app/project/models/project-validation-status.model';
import { TranslateService } from '@ngx-translate/core';

interface ValidationStatusPill {
  symbol: string;
  textColor: string;
  borderColor: string;
  backgroundColor: string;
  tooltip: string;
}

@Component({  standalone: true, imports:[NgStyle, MatTooltipModule],
  selector: 'parteng-project-validation-status',
  template: `
    <span
      [ngStyle]="{
        color: validationStatus.textColor,
        'border-color': validationStatus.borderColor,
        'background-color': validationStatus.backgroundColor
      }"
      class="border text-sm w-[22px] h-[22px] leading-[22px] text-center inline-block rounded-md"
      style="border-radius: 50%"
      [matTooltip]="validationStatus.tooltip"
      [attr.data-testId]="'parteng-project-validation-status-' + validationStatus.symbol"
    >
      {{ validationStatus.symbol }}
    </span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectValidationStatusComponent {
  @Input() set projectValidationStatusId(statusId: ProjectValidationStatusId) {
    this.validationStatus = {
      ...this.pills[statusId],
      ...this.projectValidationStatusService.getProjectValidationStatusColors(statusId),
    };
  }

  validationStatus!: ValidationStatusPill;

  pills: { [key: string]: { symbol: string; tooltip: string } } = {
    [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_CREATED')!]: {
      symbol: '1',
      tooltip: this.translateService.instant('project.validationStatusTooltip.PROJECT_VALIDATION_STATUS_CREATED'),
    },
    [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_INPUT')!]: {
      symbol: '2',
      tooltip: this.translateService.instant('project.validationStatusTooltip.PROJECT_VALIDATION_STATUS_INPUT'),
    },
    [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_VERIFICATION_REJECTED')!]: {
      symbol: '2',
      tooltip: this.translateService.instant(
        'project.validationStatusTooltip.PROJECT_VALIDATION_STATUS_VERIFICATION_REJECTED'
      ),
    },
    [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_VALIDATION_REJECTED')!]: {
      symbol: '3',
      tooltip: this.translateService.instant(
        'project.validationStatusTooltip.PROJECT_VALIDATION_STATUS_VALIDATION_REJECTED'
      ),
    },
    [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_VERIFICATION')!]: {
      symbol: '3',
      tooltip: this.translateService.instant('project.validationStatusTooltip.PROJECT_VALIDATION_STATUS_VERIFICATION'),
    },
    [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_VALIDATION')!]: {
      symbol: '4',
      tooltip: this.translateService.instant('project.validationStatusTooltip.PROJECT_VALIDATION_STATUS_VALIDATION'),
    },
    [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_VALIDATED')!]: {
      symbol: '5',
      tooltip: this.translateService.instant('project.validationStatusTooltip.PROJECT_VALIDATION_STATUS_VALIDATED'),
    },
    [this.settingsService.get<number>('PROJECT_VALIDATION_STATUS_CLOSED')!]: {
      symbol: '5',
      tooltip: this.translateService.instant('project.validationStatusTooltip.PROJECT_VALIDATION_STATUS_CLOSED'),
    },
  };

  constructor(
    private settingsService: SettingsService,
    private translateService: TranslateService,
    private projectValidationStatusService: ProjectValidationStatusService
  ) {}
}
