import { EntityWithId, EntityWithIdDto, VALIDATION_STATUS, VALIDATION_STATUS_DEFAULT } from '@app/common/models';
import { Project } from '.';

export type PersonType =
  | 'PERSON_TYPE_LEGAL_PERSON'
  | 'PERSON_TYPE_NATURAL_PERSON'
  | 'PERSON_TYPE_INVESTMENT_FUND'
  | 'PERSON_TYPE_GROUP';

export const personTypes: PersonType[] = [
  'PERSON_TYPE_GROUP',
  'PERSON_TYPE_INVESTMENT_FUND',
  'PERSON_TYPE_LEGAL_PERSON',
  'PERSON_TYPE_NATURAL_PERSON',
];

export type PersonTypeId = number;

export interface PersonDto extends EntityWithIdDto {
  first_name: string;
  last_name: string;
  company_name: string;
  name: string;
  short_name: string;
  person_type: PersonTypeId;
  creation_projects_id: number;
  creationProject: Project;
  has_balance_sheet_impacted: number;
  has_capitalization_table_impacted: number;

  // legal
  legal_entity_types_id: number;
  legal_entity_identifier: string;
  legal_entity_country_code: string;
  legal_entity_pending_registration: number;

  // fund
  fund_types_id: number;
  fund_manager_id: number;
  $fundManagerName: string;

  validation_status: VALIDATION_STATUS | undefined;
  comment: string;

  // group
  personsInGroup?: Person[];
  groups?: Person[];
}

export class Person extends EntityWithId {
  first_name: string;
  last_name: string;
  company_name: string;
  name: string; // required - computed value -- do not expose in forms
  short_name: string;
  person_type: PersonTypeId; // required
  creation_projects_id: number | undefined; // FK - required
  creationProject: Project | undefined;
  has_balance_sheet_impacted: number;
  has_capitalization_table_impacted: number;

  // legal
  legal_entity_types_id: number | undefined; // FK
  legal_entity_identifier: string | undefined;
  legal_entity_country_code: string | undefined;
  legal_entity_pending_registration: number | undefined;

  // fund
  fund_types_id: number | undefined; // FK
  fund_manager_id: number | undefined; // FK
  $fundManagerName: string | undefined; // frontend-specific prop

  validation_status: VALIDATION_STATUS | undefined;
  comment: string | undefined;

  // group
  personsInGroup: Person[] | undefined;

  // groups
  groups: Person[] | undefined;

  constructor(opts: Partial<PersonDto> = {}) {
    super(opts);

    this.$fundManagerName = opts.$fundManagerName;
    this.comment = opts.comment;
    this.company_name = opts.company_name!;
    this.creationProject = opts.creationProject;
    this.creation_projects_id = opts.creation_projects_id!;
    this.first_name = opts.first_name!;
    this.fund_manager_id = opts.fund_manager_id;
    this.fund_types_id = opts.fund_types_id;
    this.has_balance_sheet_impacted = opts.has_balance_sheet_impacted || 0;
    this.has_capitalization_table_impacted = opts.has_capitalization_table_impacted || 0;
    this.last_name = opts.last_name!;
    this.legal_entity_country_code = opts.legal_entity_country_code;
    this.legal_entity_identifier = opts.legal_entity_identifier;
    this.legal_entity_pending_registration = opts.legal_entity_pending_registration;
    this.legal_entity_types_id = opts.legal_entity_types_id;
    this.name = opts.name!;
    this.person_type = opts.person_type!;
    this.personsInGroup = opts.personsInGroup;
    this.groups = opts.groups;
    this.short_name = opts.short_name!;
    this.validation_status = opts.validation_status ?? VALIDATION_STATUS_DEFAULT;
  }

  clone(opts: Partial<PersonDto> = {}): Person {
    const clone = new Person({
      ...this.cloneEntityProps(opts),
      first_name: opts.first_name || this.first_name,
      last_name: opts.last_name || this.last_name,
      company_name: opts.company_name || this.company_name,
      name: opts.name || this.name,
      short_name: opts.short_name || this.short_name,
      person_type: opts.person_type || this.person_type,
      creation_projects_id: opts.creation_projects_id || this.creation_projects_id,
      legal_entity_types_id: opts.legal_entity_types_id || this.legal_entity_types_id,
      fund_types_id: opts.fund_types_id || this.fund_types_id,
      fund_manager_id: opts.fund_manager_id || this.fund_manager_id,
      $fundManagerName: opts.$fundManagerName || this.$fundManagerName,
      legal_entity_identifier: opts.legal_entity_identifier || this.legal_entity_identifier,
      legal_entity_country_code: opts.legal_entity_country_code || this.legal_entity_country_code,
      legal_entity_pending_registration:
        opts.legal_entity_pending_registration || this.legal_entity_pending_registration,
      comment: opts.comment || this.comment,
      validation_status: opts.validation_status ?? this.validation_status,
      creationProject: opts.creationProject || this.creationProject,
      has_balance_sheet_impacted: opts.has_balance_sheet_impacted || this.has_balance_sheet_impacted,
      has_capitalization_table_impacted:
        opts.has_capitalization_table_impacted || this.has_capitalization_table_impacted,
      personsInGroup: opts.personsInGroup || this.personsInGroup,
      groups: opts.groups || this.groups,
    });
    return clone;
  }
}
