import { Pipe, PipeTransform } from '@angular/core';
import { PersonTypeId, personTypes } from '@app/project/models';
import { PersonService } from '@app/project/services/person.service';
import { TranslateService } from '@ngx-translate/core';

@Pipe({  standalone: true,
  name: 'personTypeToTranslatedLabel',
})
export class PersonTypeToTranslatedLabelPipe implements PipeTransform {
  constructor(private translate: TranslateService, private personService: PersonService) {}

  transform(personTypeId: PersonTypeId): string {
    const personType = personTypes.find((pt) => this.personService.getPersonTypeId(pt) === personTypeId);
    if (!personType) {
      throw new Error(`Unknown person type id: ${personTypeId}`);
    }
    return this.translate.instant(`shared.personType.${personType}`);
  }
}
