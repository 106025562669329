import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[parteng-footer]',
  template: ``,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        min-height: 5vh;
        --tw-bg-opacity: 1;
        background-color: rgb(245 247 249 / var(--tw-bg-opacity));
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartengFooterComponent {}
