import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TaskService } from '@app/common/services/task.service';
import { Person, Project } from '@app/project/models';
import { PersonService } from '@app/project/services/person.service';
import { ProjectSelectorService } from '@app/project/services/project-selector.service';
import { TranslateModule } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { DialogComponent } from '../dialog.component';

@Component({
  standalone: true,
  imports: [DialogComponent, TranslateModule, NgIf, MatButtonModule],
  selector: 'parteng-dialog-person-project',
  template: `
    <parteng-dialog
      [showDialogButtons]="false"
      [title]="'shared.personDialog.changeTitle' | translate: { personName: this.data.person.name }"
      [subtitle]="'shared.personDialog.subtitle' | translate"
      *ngIf="selectedProject === null; else confirmDialog"
      (cancel)="onCancelButtonClick()"
      (safeClose)="onCancelButtonClick()"
    >
      <div class="flex justify-between items-center rounded-md border-solid border border-gray-300 p-4">
        <div class="w-2/3">
          {{
            'shared.personDialog.project'
              | translate: { creationProjectName: this.data.person.creationProject?.longName }
          }}
        </div>
        <button
          mat-stroked-button
          color="primary"
          type="button"
          (click)="onChangeProjectButtonClick()"
          data-testId="change-project-button"
        >
          {{ 'shared.personDialog.buttonLabel' | translate }}
        </button>
      </div>
      <div class="mt-4">
        <button type="button" (click)="onCancelButtonClick()" class="mx-auto block">
          {{ 'persons.movePerson.projectSelector.cancel' | translate }}
        </button>
      </div>
    </parteng-dialog>

    <ng-template #confirmDialog>
      <div class="flex flex-col justify-center items-center gap-3.5 p-8" data-testId="synthesis-container">
        <h1 class="text-center">{{ 'persons.movePerson.projectSelector.warning' | translate }}</h1>
        <div class="border-solid border border-gray-300 w-6/12"></div>
        <!-- prettier-ignore -->
        <p class="text-center whitespace-pre">{{
            'persons.movePerson.projectSelector.warningMessage'
              | translate
                : {
                    initialProject: this.data.person.creationProject?.longName,
                    newProjet: this.selectedProject!.longName
                  }
          }}</p>
        <div class="border-solid border border-gray-300 w-6/12"></div>
        <div class="flex items-center justify-center gap-3.5">
          <button type="button" (click)="onCancelButtonClick()">
            {{ 'persons.movePerson.projectSelector.cancel' | translate }}
          </button>
          <button
            mat-stroked-button
            color="primary"
            type="button"
            data-testId="confirm-button"
            (click)="onConfirmButtonClick()"
          >
            {{ 'persons.movePerson.projectSelector.confirm' | translate }}
          </button>
        </div>
      </div>
    </ng-template>
  `,
})
export class DialogPersonProjectComponent {
  selectedProject: Project | null | undefined = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { person: Person },
    private projectSelectorService: ProjectSelectorService,
    private personService: PersonService,
    private dialogRef: MatDialogRef<DialogPersonProjectComponent>,
    private taskService: TaskService
  ) {}

  async onConfirmButtonClick(): Promise<void> {
    await this.taskService.doSave$(
      this.personService.updatePersonProjectCreation$(this.data.person, this.selectedProject!.id)
    );
    this.dialogRef.close();
  }

  onCancelButtonClick(): void {
    this.dialogRef.close();
  }

  async onChangeProjectButtonClick(): Promise<void> {
    this.selectedProject = await firstValueFrom(
      this.projectSelectorService.openProjectSelectorForPersonMove(this.data.person.name)
    );
  }
}
