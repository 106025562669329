import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { map, Observable } from 'rxjs';

import { HalApiService } from '@app/common/services/hal-api.service';
import { PersonQuality } from '../models/person-quality.model';

@Injectable({ providedIn: 'root' })
export class PersonQualityService {
  constructor(private halApiService: HalApiService) {}

  getAll$(): Observable<PersonQuality[]> {
    return this.halApiService
      .getCollection$(PersonQuality, '/person-qualities', {}, 'person_qualities')
      .pipe(map((personQualities) => _.sortBy(personQualities, 'order')));
  }
}
