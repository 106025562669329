<section class="person-form">
  <form [formGroup]="form" *ngIf="form" (ngSubmit)="submit()">
    <ng-container [ngSwitch]="person.person_type">
      <parteng-dynamic-input
        *ngIf="mode !== 'create'"
        [label]="'project.personForm.' + PERSON_TYPE_NATURAL_PERSON + '.id' | translate"
        [mode]="fieldMode"
        [displayValue]="form.get('id')!.value"
      >
        <mat-form-field appearance="fill" class="w-full">
          <mat-label data-testId="person-id-label">{{
            'project.personForm.' + PERSON_TYPE_NATURAL_PERSON + '.id' | translate
          }}</mat-label>
          <input matInput formControlName="id" cdkFocusInitial data-testId="person-id" />
        </mat-form-field>
      </parteng-dynamic-input>
      <parteng-dynamic-input
        *ngIf="mode !== 'create'"
        [label]="'dataEntry.pagePersonsList.personsTable.creationProjectName' | translate"
        [mode]="fieldMode"
        [displayValue]="form.get('projectCreation')!.value"
      >
        <mat-form-field appearance="fill" class="w-full">
          <mat-label data-testId="person-project-label">{{
            'dataEntry.pagePersonsList.personsTable.creationProjectName' | translate
          }}</mat-label>
          <input matInput formControlName="projectCreation" cdkFocusInitial data-testId="form-person-projectCreation" />
        </mat-form-field>
      </parteng-dynamic-input>

      <!-- Natural Person -->
      <ng-container *ngSwitchCase="PERSON_TYPE_NATURAL_PERSON">
        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_NATURAL_PERSON + '.lastName' | translate"
          [mode]="fieldMode"
          [displayValue]="form.get('last_name')!.value"
        >
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{ 'project.personForm.' + PERSON_TYPE_NATURAL_PERSON + '.lastName' | translate }}</mat-label>
            <input matInput formControlName="last_name" cdkFocusInitial data-testId="natural-person-last-name" />
          </mat-form-field>
        </parteng-dynamic-input>
        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_NATURAL_PERSON + '.firstName' | translate"
          [mode]="fieldMode"
          [displayValue]="form.get('first_name')!.value"
        >
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{ 'project.personForm.' + PERSON_TYPE_NATURAL_PERSON + '.firstName' | translate }}</mat-label>
            <input matInput formControlName="first_name" data-testId="natural-person-first-name" />
          </mat-form-field>
        </parteng-dynamic-input>

        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_NATURAL_PERSON + '.shortName' | translate"
          [mode]="fieldMode"
          [displayValue]="form.get('short_name')!.value"
        >
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{ 'project.personForm.' + PERSON_TYPE_NATURAL_PERSON + '.shortName' | translate }}</mat-label>
            <input matInput formControlName="short_name" data-testId="natural-person-short-name" />
          </mat-form-field>
        </parteng-dynamic-input>
      </ng-container>

      <!-- Legal Person -->
      <ng-container *ngSwitchCase="PERSON_TYPE_LEGAL_PERSON">
        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_LEGAL_PERSON + '.companyName' | translate"
          [mode]="fieldMode"
          [displayValue]="form.get('company_name')!.value"
        >
          <mat-form-field appearance="fill" class="w-full" data-testId="legal-person-company-name">
            <mat-label>{{ 'project.personForm.' + PERSON_TYPE_LEGAL_PERSON + '.companyName' | translate }}</mat-label>
            <input matInput formControlName="company_name" cdkFocusInitial />
          </mat-form-field>
        </parteng-dynamic-input>

        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_LEGAL_PERSON + '.shortName' | translate"
          [mode]="fieldMode"
          [displayValue]="form.get('short_name')!.value"
        >
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{ 'project.personForm.' + PERSON_TYPE_LEGAL_PERSON + '.shortName' | translate }}</mat-label>
            <input matInput formControlName="short_name" data-testId="legal-person-short-name" />
          </mat-form-field>
        </parteng-dynamic-input>

        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_LEGAL_PERSON + '.registrationPending' | translate"
          [mode]="fieldMode"
          [displayValue]="!form.get('legal_entity_identifier')!.value ? 'Oui' : 'Non'"
        >
          <div class="mx-1 mt-2 mb-5">
            <mat-checkbox
              class="example-margin"
              formControlName="legal_entity_pending_registration"
              (change)="onRegistrationPendingChanged($event.checked)"
              data-testId="legal-person-pending-registration"
            >
              {{ 'project.personForm.' + PERSON_TYPE_LEGAL_PERSON + '.registrationPending' | translate }}
            </mat-checkbox>
          </div>
        </parteng-dynamic-input>

        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_LEGAL_PERSON + '.country' | translate"
          [mode]="fieldMode"
          [displayValue]="form.get('legal_entity_country_code')!.value"
        >
          <div class="field-country pb-6">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'project.personForm.' + PERSON_TYPE_LEGAL_PERSON + '.country' | translate }}</mat-label>
              <input
                matInput
                formControlName="legal_entity_country_code"
                value="FR"
                maxlength="2"
                data-testId="legal-person-country-code"
              />
              <mat-hint>
                <span
                  class="text-sm"
                  [innerHTML]="
                    'project.personForm.' + PERSON_TYPE_LEGAL_PERSON + '.countryHint'
                      | translate: { isoCodesUrl: 'https://fr.wikipedia.org/wiki/ISO_3166-1#Table_de_codage' }
                  "
                ></span>
              </mat-hint>
            </mat-form-field>
          </div>
        </parteng-dynamic-input>

        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_LEGAL_PERSON + '.identifier' | translate"
          [mode]="fieldMode"
          [displayValue]="form.get('legal_entity_identifier')!.value"
        >
          <mat-form-field appearance="fill" class="w-full" data-testId="legal-person-identifier">
            <mat-label>{{ 'project.personForm.' + PERSON_TYPE_LEGAL_PERSON + '.identifier' | translate }}</mat-label>
            <input matInput formControlName="legal_entity_identifier" />
          </mat-form-field>
        </parteng-dynamic-input>

        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_LEGAL_PERSON + '.legalEntityType' | translate"
          [mode]="fieldMode"
          [displayValue]="getEntityTypeName(form.get('legal_entity_types_id')!.value)"
          data-testId="legal-person-type-id"
        >
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{
              'project.personForm.' + PERSON_TYPE_LEGAL_PERSON + '.legalEntityType' | translate
            }}</mat-label>
            <mat-select required formControlName="legal_entity_types_id">
              <mat-option value="">---</mat-option>
              <mat-option *ngFor="let legalEntityType of allLegalEntityTypes" [value]="legalEntityType.id">
                {{ legalEntityType.short_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </parteng-dynamic-input>
      </ng-container>

      <!-- Investment Fund -->
      <ng-container *ngSwitchCase="PERSON_TYPE_INVESTMENT_FUND">
        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_INVESTMENT_FUND + '.companyName' | translate"
          [mode]="fieldMode"
          [displayValue]="form.get('company_name')!.value"
        >
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{
              'project.personForm.' + PERSON_TYPE_INVESTMENT_FUND + '.companyName' | translate
            }}</mat-label>
            <input matInput formControlName="company_name" cdkFocusInitial data-testId="investment-fund-company-name" />
          </mat-form-field>
        </parteng-dynamic-input>

        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_INVESTMENT_FUND + '.shortName' | translate"
          [mode]="fieldMode"
          [displayValue]="form.get('short_name')!.value"
        >
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{ 'project.personForm.' + PERSON_TYPE_INVESTMENT_FUND + '.shortName' | translate }}</mat-label>
            <input matInput formControlName="short_name" data-testId="investment-fund-short-name" />
          </mat-form-field>
        </parteng-dynamic-input>

        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_INVESTMENT_FUND + '.fundType' | translate"
          [mode]="fieldMode"
          [displayValue]="getFundTypeName(form.get('fund_types_id')!.value)"
          data-testId="investment-fund-instrument-type"
        >
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{ 'project.personForm.' + PERSON_TYPE_INVESTMENT_FUND + '.fundType' | translate }}</mat-label>
            <mat-select required formControlName="fund_types_id">
              <mat-option value="">---</mat-option>
              <mat-option *ngFor="let fundType of allFundTypes" [value]="fundType.id">
                {{ fundType.short_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </parteng-dynamic-input>

        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_INVESTMENT_FUND + '.fundManager' | translate"
          [mode]="fieldMode"
          [displayValue]="getFundManager(form.get('fund_manager_id')?.value)?.name"
        >
          <div class="mb-4">
            <ng-container *ngIf="form.get('fund_manager_id')?.value">
              <div class="text-xs text-neutral-500">
                {{ 'project.personForm.' + PERSON_TYPE_INVESTMENT_FUND + '.fundManager' | translate }}
              </div>
              <input
                type="text"
                matInput
                readonly="readonly"
                [disabled]="isPersonReviewed"
                class="mb-3"
                [value]="getFundManager(form.get('fund_manager_id')?.value)?.name"
              />
            </ng-container>
            <button
              mat-stroked-button
              color="primary"
              type="button"
              class="w-full"
              (click)="selectFundManager()"
              data-testId="investment-fund-manager-button"
              *ngIf="!isPersonReviewed"
            >
              {{
                (getFundManager(form.get('fund_manager_id')?.value)
                  ? 'project.personForm.' + PERSON_TYPE_INVESTMENT_FUND + '.changeFundManager'
                  : 'project.personForm.' + PERSON_TYPE_INVESTMENT_FUND + '.addFundManager'
                ) | translate
              }}
            </button>
          </div>
        </parteng-dynamic-input>
      </ng-container>

      <!-- Person Group -->
      <ng-container *ngSwitchCase="PERSON_TYPE_GROUP">
        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_GROUP + '.groupName' | translate"
          [mode]="fieldMode"
          [displayValue]="form.get('groupName')!.value"
        >
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{ 'project.personForm.' + PERSON_TYPE_GROUP + '.groupName' | translate }}</mat-label>
            <input matInput formControlName="groupName" cdkFocusInitial data-testId="person-group-name" />
          </mat-form-field>
        </parteng-dynamic-input>

        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_GROUP + '.groupShortName' | translate"
          [mode]="fieldMode"
          [displayValue]="form.get('groupShortName')!.value"
        >
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{ 'project.personForm.' + PERSON_TYPE_GROUP + '.groupShortName' | translate }}</mat-label>
            <input matInput formControlName="groupShortName" data-testId="person-group-short-name" />
          </mat-form-field>
        </parteng-dynamic-input>

        <parteng-dynamic-input
          [label]="'project.personForm.' + PERSON_TYPE_GROUP + '.personIds' | translate"
          [mode]="fieldMode"
          [displayValue]="selectedPersonsGroup | personsGroupToString"
        >
          <div class="mb-4 w-full">
            <ng-container *ngIf="selectedPersonsGroup !== undefined && selectedPersonsGroup.length !== 0">
              <div class="text-xs text-neutral-500">
                {{ 'project.personForm.' + PERSON_TYPE_GROUP + '.personIds' | translate }}
              </div>
              <div class="flex items-center justify-between mt-1">
                <mat-chip-listbox>
                  <mat-chip
                    *ngFor="let person of selectedPersonsGroup | personGroupChipSort"
                    class="bg-blue-ptg-secondary-060 rounded-[20px]"
                    >{{ person.name }}
                    <button
                      *ngIf="isPersonReviewed === false && isGroupPersonLocked === false"
                      matChipRemove
                      (click)="removePersonFromPersonGroup(person.id)"
                    >
                      <mat-icon>cancel</mat-icon>
                    </button></mat-chip
                  >
                  <button
                    *ngIf="isPersonReviewed === false && isGroupPersonLocked === false"
                    mat-stroked-button
                    color="primary"
                    type="button"
                    class="min-w-min m-1 !h-[36px]"
                    (click)="selectPersonsForGroup()"
                    data-testId="person-group-add-button"
                  >
                    {{ 'project.personForm.' + PERSON_TYPE_GROUP + '.addPersonButton' | translate }}
                  </button>
                </mat-chip-listbox>
              </div>
            </ng-container>
            <button
              *ngIf="selectedPersonsGroup === undefined || selectedPersonsGroup.length === 0"
              mat-stroked-button
              color="primary"
              type="button"
              class="w-full"
              (click)="selectPersonsForGroup()"
              data-testId="person-group-add-button"
            >
              {{ 'project.personForm.' + PERSON_TYPE_GROUP + '.addPersonButtonWhenNoPersonSelected' | translate }}
            </button>
          </div>
        </parteng-dynamic-input>
      </ng-container>
    </ng-container>
    <div>
      <parteng-dynamic-input
        [label]="'project.personForm.' + PERSON_TYPE_GROUP + '.comment' | translate"
        [mode]="fieldMode"
        [displayValue]="form.get('comment')!.value"
        [enableBreakLine]="true"
      >
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>{{ 'project.personForm.' + PERSON_TYPE_GROUP + '.comment' | translate }}</mat-label>
          <textarea
            [readonly]="mode === 'view' || mode === 'verification'"
            matInput
            formControlName="comment"
            rows="4"
            data-testId="person-group-comment-input"
          ></textarea>
        </mat-form-field>
      </parteng-dynamic-input>
    </div>
    <div class="pt-2 flex justify-end gap-5">
      <!-- cancel / back button -->
      <button type="button" class="ml-4" (click)="cancel.emit()" data-testId="person-cancel-button">
        {{ 'shared.personDialog.back_' + mode | translate }}
      </button>
      <!-- change project button -->
      <button
        *ngIf="showChangeProjectButton && (person | isPersonReviewed) === false && mode === 'view'"
        type="button"
        class="ml-4"
        mat-stroked-button
        color="primary"
        (click)="onChangeProjectButtonClick()"
        [can-edit-feature]="contextFeature"
        data-testId="person-change-project-button"
      >
        {{ 'shared.personDialog.change' | translate }}
      </button>
      <!-- delete button -->
      <button
        *ngIf="mode === 'view' && showDeleteButton"
        type="button"
        class="ml-4"
        mat-stroked-button
        color="warn"
        (click)="onDeleteButtonClick()"
        [can-edit-feature]="contextFeature"
        data-testId="person-delete-button"
      >
        <ng-container
          *ngTemplateOutlet="
            deletePersonTemplate;
            context: {
              personType:
                person.person_type === PERSON_TYPE_GROUP
                  ? '_group'
                  : person.person_type === PERSON_TYPE_INVESTMENT_FUND
                  ? '_investment_fund'
                  : ''
            }
          "
        ></ng-container>
      </button>
      <!-- submit button -->
      <button
        *ngIf="mode === 'create' || mode === 'edit' || mode === 'verification'"
        mat-raised-button
        color="primary"
        type="submit"
        data-testId="person-update-button"
        [disabled]="form.invalid || (!form.dirty && initialMode !== 'verification')"
        [can-edit-feature]="contextFeature"
      >
        <ng-container
          *ngTemplateOutlet="
            initialMode === 'verification' ? verificationTemplate : personTemplate;
            context: {
              personGroup: person.person_type === PERSON_TYPE_GROUP ? '_group' : '',
              investmentFund: person.person_type === PERSON_TYPE_INVESTMENT_FUND ? '_investment_fund' : '',
              mode: initialMode === 'verification' && mode === 'edit' ? 'edit_verification' : mode
            }
          "
        ></ng-container>
      </button>
    </div>
  </form>
</section>

<ng-template #personTemplate let-personGroup="personGroup" let-investmentFund="investmentFund" let-mode="mode">
  <!-- NB: a person cannot be a group and a fund simultaneously -->
  {{ 'persons.buttonLabels.validate_' + mode + personGroup + investmentFund | translate }}
</ng-template>

<ng-template #verificationTemplate let-mode="mode">
  {{ 'persons.buttonLabels.validate_' + mode | translate }}
</ng-template>

<ng-template #deletePersonTemplate let-personType="personType">
  {{ 'shared.personDialog.delete' + personType | translate }}
</ng-template>
