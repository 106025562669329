import { APP_BASE_HREF, DatePipe, PlatformLocation, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Inject, LOCALE_ID, NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from '@app/common/angular-material.module';
import { TaskInterceptor } from '@app/common/interceptors/http.interceptor';
import { RoleService } from '@app/common/services/role.service';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { of } from 'rxjs';
import { translations } from '../../assets/i18n/index';
import { AccessDeniedComponent } from './access-denied.component';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login.component';
import { LogOutComponent } from './logout.component';
import { NoConnectionComponent } from './no-connection.component';
import { OAuthComponent } from './oauth.component';
import { RootComponent } from './root.component';

registerLocaleData(localeFr);

export class StaticLoader implements TranslateLoader {
  public getTranslation(lang: string): any {
    return of((translations as any)[lang]);
  }
}

export function initializeServices(roleService: RoleService) {
  return async () => {
    await roleService.loadRoleMatrix();
  };
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthRoutingModule,
    AngularMaterialModule,
    MatCardModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: () => new StaticLoader(),
      },
    }),
  ],
  declarations: [
    RootComponent,
    LoginComponent,
    LogOutComponent,
    OAuthComponent,
    AccessDeniedComponent,
    NoConnectionComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    DatePipe,
    RoleService,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TaskInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeServices,
      deps: [RoleService],
      multi: true,
    },
    provideStore(
      {},
      {
        runtimeChecks: {
          strictActionImmutability: false,
        },
      }
    ),
    provideStoreDevtools({
      name: 'parteng',
      maxAge: 25, // Retains last 25 states
    }),
    provideEffects([]),
  ],
  bootstrap: [RootComponent],
})
export class AuthModule {
  constructor(@Inject(LOCALE_ID) localeId: string, translate: TranslateService) {
    // TODO: replace by a regex and use defensive code
    const langId = localeId.split('-')[0];
    translate.setDefaultLang(langId);
  }
}
