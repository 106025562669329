import { createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import {
  FundType,
  Goal2,
  LegalEntityType,
  Person,
  Project,
  ProjectTemplate2,
  StandardFolder2,
  World,
  standardFolder2sToFolder2s,
} from '../models';
import { ProjectType } from '../models/project-type.model';
import { ProjectFormState, computeProjectFormSlice, projectFormStateDefaults } from './project-form-state-helper';
import * as projectFormActions from './project-form.actions';

export interface State {
  projectsList: {
    isLoading: boolean;
    allProjects: Project[];
    highlightedProject: Project | undefined;
  };
  // main form
  projectForm: ProjectFormState;
  dialogFundManagerSelector: {
    legalPersons: Person[];
    selectedLegalPerson: Person;
    newLegalPerson: Person;
  };
  dialogPersonForGoalForm: {
    person: Person;
    fundManager: Person;
  };
  dialogFundManagerForm: {
    person: Person;
  };
  // related domain data
  db: {
    allTemplates: ProjectTemplate2[];
    // allScopes: Scope2[];
    allWorlds: World[];
    allGoals: Goal2[];
    allLegalEntityTypes: LegalEntityType[];
    allFundTypes: FundType[];
    allStandardFolders: StandardFolder2[];
    allProjectTypes: ProjectType[];
  };
  // This is more of a setting than a state value, but we need to put this here
  // because we can't use hardcoded values in the selectors.
  defaults: {
    hideAddItemButtonInItemSelector: boolean;
  };
}

export const initialState: State = {
  projectsList: {
    isLoading: false,
    allProjects: [],
    highlightedProject: undefined,
  },
  // main form
  projectForm: { ...projectFormStateDefaults },
  dialogFundManagerSelector: {
    legalPersons: [],
    selectedLegalPerson: undefined as any,
    newLegalPerson: undefined as any,
  },
  dialogPersonForGoalForm: {
    person: undefined as any,
    fundManager: undefined as any,
  },
  dialogFundManagerForm: {
    person: undefined as any,
  },
  // related domain data
  db: {
    allTemplates: [],
    // allScopes: [],
    allWorlds: [],
    allGoals: [],
    allLegalEntityTypes: [],
    allFundTypes: [],
    allStandardFolders: [],
    allProjectTypes: [],
  },
  defaults: {
    hideAddItemButtonInItemSelector: false,
  },
};

//
//
//

export const reducer = createReducer(
  initialState,

  //
  // ---------- Projects List
  //

  on(
    projectFormActions.loadProjectsList,
    (state): State => ({
      ...state,
      projectsList: { ...initialState.projectsList, isLoading: true },
      projectForm: { ...initialState.projectForm },
    })
  ),
  on(
    projectFormActions.loadProjectsListSuccess,
    (state, { allProjects }): State => ({
      ...state,
      projectsList: {
        isLoading: false,
        allProjects,
        highlightedProject: state.projectsList.highlightedProject,
      },
    })
  ),
  on(
    projectFormActions.loadProjectsListError,
    (state, { error }): State => ({
      ...state,
      projectsList: {
        isLoading: false,
        allProjects: state.projectsList.allProjects,
        highlightedProject: state.projectsList.highlightedProject,
      },
    })
  ),
  on(
    projectFormActions.loadProjectHighlightedInProjectsListSuccess,
    (state, { project }): State => ({
      ...state,
      projectsList: {
        isLoading: state.projectsList.isLoading,
        allProjects: state.projectsList.allProjects,
        highlightedProject: project,
      },
    })
  ),
  on(
    projectFormActions.loadProjectHighlightedInProjectsListError,
    (state, { error }): State => ({
      ...state,
      projectsList: {
        isLoading: state.projectsList.isLoading,
        allProjects: state.projectsList.allProjects,
        highlightedProject: initialState.projectsList.highlightedProject,
      },
    })
  ),
  on(
    projectFormActions.resetProjectHighlightedInProjectsList,
    (state): State => ({
      ...state,
      projectsList: {
        isLoading: state.projectsList.isLoading,
        allProjects: state.projectsList.allProjects,
        highlightedProject: initialState.projectsList.highlightedProject,
      },
    })
  ),

  //
  // ---------- Project Form
  //

  on(
    projectFormActions.loadProjectFormDataSuccess,
    (
      state,
      {
        isNew,
        project,
        allTemplates,
        allWorlds,
        allGoals,
        allLegalEntityTypes,
        allFundTypes,
        allStandardFolders,
        allProjectTypes,
      }
    ): State => {
      return {
        ...state,
        projectForm: computeProjectFormSlice(state.projectForm, { project, isNew, reset: true }),
        db: {
          allTemplates,
          allWorlds,
          allGoals,
          allLegalEntityTypes,
          allFundTypes,
          allStandardFolders,
          allProjectTypes,
        },
      };
    }
  ),
  on(projectFormActions.validateProjectTemplate, (state, { templateId }): State => {
    // Apply the chosen template, if any, to the current project
    if (templateId !== undefined) {
      const template = state.db.allTemplates.find((t) => t.id === templateId)!;
      const project = state.projectForm.project.clone({
        name: template.projectNameTemplate,
        projectTypeId: template.projectTypeId,
        folders: standardFolder2sToFolder2s(
          template.relProjectTemplateToStandardFolders.map((rel) => rel.standard_folder)
        ),
        goals: template.relProjectTemplateToGoals.map((rel) => rel.goal),
      });
      return {
        ...state,
        projectForm: computeProjectFormSlice(state.projectForm, {
          project,
          templateId,
          wasTemplateApplied: true,
        }),
      };
    } else {
      return {
        ...state,
        projectForm: computeProjectFormSlice(state.projectForm, { wasTemplateApplied: true }),
      };
    }
  }),
  on(
    projectFormActions.changeProjectIdentification,
    (state, { ordinary, name, comment, firstClosingDate, projectTypeId }): State => ({
      ...state,
      projectForm: computeProjectFormSlice(state.projectForm, {
        project: state.projectForm.project.clone({ ordinary, name, comment, firstClosingDate, projectTypeId }),
        projectIdentificationChanged: true,
      }),
    })
  ),
  on(
    projectFormActions.submitProjectIdentification,
    (state, { project }): State => ({
      ...state,
      projectForm: computeProjectFormSlice(state.projectForm, {
        project,
        projectIdentificationSubmitted: true,
      }),
    })
  ),
  on(
    projectFormActions.saveProjectDraftSuccess,
    (state, { project }): State => ({
      ...state,
      projectForm: computeProjectFormSlice(state.projectForm, { project }),
    })
  ),
  on(
    projectFormActions.saveProjectSuccess,
    (state): State => ({
      ...state,
      projectForm: computeProjectFormSlice(state.projectForm, {}),
    })
  ),
  on(
    projectFormActions.saveProjectError,
    (state, { error }): State => ({
      ...state,
      projectForm: computeProjectFormSlice(state.projectForm, {}),
    })
  ),

  //
  // ---------- Scope Selector
  //

  on(projectFormActions.submitSelectedScopesForProject, (state, { scopes }): State => {
    scopes = _.sortBy(scopes, 'code');
    return {
      ...state,
      projectForm: computeProjectFormSlice(state.projectForm, {
        project: state.projectForm.project.clone({ scopes }),
        projectScopesSubmitted: true,
      }),
    };
  }),

  //
  // ---------- Project Goals
  //

  // Validating selected goals will add them to the project
  on(projectFormActions.validateSelectedGoals, (state, { goals }): State => {
    return {
      ...state,
      projectForm: computeProjectFormSlice(state.projectForm, {
        project: state.projectForm.project.updateGoals(goals),
      }),
    };
  }),
  on(projectFormActions.updateProjectGoal, (state, { relProjectToGoal }): State => {
    return {
      ...state,
      projectForm: computeProjectFormSlice(state.projectForm, {
        project: state.projectForm.project.updateRelProjectToGoal(relProjectToGoal),
      }),
    };
  }),
  on(projectFormActions.removeProjectGoal, (state, { goal }): State => {
    return {
      ...state,
      projectForm: computeProjectFormSlice(state.projectForm, {
        project: state.projectForm.project.removeGoal(goal),
      }),
    };
  }),

  //
  // ---------- Project Folders
  //

  on(
    projectFormActions.saveProjectFolder,
    (state, { folder, folderIndex }): State => ({
      ...state,
      projectForm: computeProjectFormSlice(state.projectForm, {
        project: state.projectForm.project.saveProjectFolder(folder, folderIndex),
        isFoldersSubmitted: true,
        wasDialogFolderFormAndListOpenedBefore: true,
      }),
    })
  ),
  on(
    projectFormActions.removeProjectFolder,
    (state, { folderIndex }): State => ({
      ...state,
      projectForm: computeProjectFormSlice(state.projectForm, {
        project: state.projectForm.project.removeProjectFolder(folderIndex),
      }),
    })
  ),
  on(
    projectFormActions.reorderProjectFolders,
    (state, { folders }): State => ({
      ...state,
      projectForm: computeProjectFormSlice(state.projectForm, {
        project: state.projectForm.project.reorderProjectFolders(folders),
      }),
    })
  )
);
