import { Pipe, PipeTransform } from '@angular/core';
import { ProjectValidationStatusService } from '@app/common/services/project-validation-status.service';
import { ProjectValidationStatus } from '@app/project/models/project-validation-status.model';

@Pipe({  standalone: true,
  name: 'projectValidationStatusLabel',
})
export class ProjectValidationStatusLabelPipe implements PipeTransform {
  constructor(private projectValidationStatusService: ProjectValidationStatusService) {}

  transform(value: ProjectValidationStatus): string {
    return this.projectValidationStatusService.getProjectValidationStatusLabel(value);
  }
}
