import { Pipe, PipeTransform, inject } from '@angular/core';
import { Person } from '@app/project/models';
import { PersonService } from '@app/project/services/person.service';

@Pipe({  standalone: true,
  name: 'isPersonReviewed',
})
export class IsPersonReviewedPipe implements PipeTransform {
  personService = inject(PersonService);

  transform(person: Person): boolean {
    return this.personService.isPersonReviewed(person);
  }
}
