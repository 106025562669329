import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'parteng-no-connection',
  template: `
    <div class="text-center mt-10">
      <h1 class="m-5">
        {{ 'logout.title' | translate }}
      </h1>
      <button
        type="button"
        mat-stroked-button
        color="primary"
        (click)="onClick()"
        data-testId="parteng-reconnect-button"
      >
        {{ 'logout.button.text' | translate }}
      </button>
    </div>
  `,
})
export class LogOutComponent {
  constructor(private ngxSpinnerService: NgxSpinnerService, private authenticationService: AuthenticationService) {
    this.ngxSpinnerService.hide();
  }

  onClick() {
    this.authenticationService.login();
  }
}
