import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EnvironmentInfo } from '@app/common/models';
import { ConfigService } from '@app/common/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom, fromEvent } from 'rxjs';
import { HealthcheckService } from './services/healthcheck.service';

@Component({
  selector: 'parteng-root',
  template: `
    <router-outlet *ngIf="isApiReady === true"></router-outlet>
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.6)"
      size="medium"
      color="#fff"
      type="ball-pulse"
      [fullScreen]="true"
    ></ngx-spinner>
    <ng-container *ngIf="isChecking === false && isApiReady === false">
      <h1 class="mt-10 w-[50%] mx-auto">
        Parteng n’est pas disponible, veuillez remonter l’erreur au service Technologie d’Arpilabe et suivre les
        instructions qui vous seront données.
      </h1>

      <mat-card class="mx-auto m-10 p-10 w-[50%] text-xl">
        <mat-card-header>
          <mat-card-subtitle>Le {{ errorReport.date | date }}</mat-card-subtitle>
          <mat-card-title>Rapport d'erreur</mat-card-title>
        </mat-card-header>
        <mat-card-content class="m-5">
          <p>{{ errorReport.dateText }}{{ errorReport.date | date }},</p>
          <p>{{ errorReport.title }}</p>
          <p>{{ errorReport.environmentText }}{{ envInfo?.env?.envName }}</p>
          <p>{{ errorReport.versionBackHash }}{{ envInfo?.apiVersion }} / {{ envInfo?.apiCommitHash }}</p>
          <p>{{ errorReport.versionFrontHash }}{{ envInfo?.frontVersion }} / {{ envInfo?.frontCommitHash }}</p>
        </mat-card-content>
        <mat-card-actions class="m-5">
          <mat-icon
            (click)="copyReportToClipboard()"
            matTooltip="Cliquez pour copier le rapport d'erreur"
            aria-hidden="true"
            class="w-[48px] h-[48px] text-[48px] cursor-pointer"
            >file_copy</mat-icon
          >
        </mat-card-actions>
      </mat-card>
    </ng-container>
  `,
})
export class RootComponent implements OnInit {
  isChecking = true;
  isApiReady: boolean = false;
  currentUrl: string = '';

  envInfo: EnvironmentInfo | undefined;
  errorReport = {
    date: new Date(),
    dateText: 'A la date du ',
    environmentText: "Dans l'environnement : ",
    title: 'Une erreur système est survenue.',
    versionBackHash: 'Back (version / hash) : ',
    versionFrontHash: 'Front (version / hash) : ',
  };

  constructor(
    private healthcheckService: HealthcheckService,
    private configService: ConfigService,
    private titleService: Title,
    private router: Router,
    private dialog: MatDialog,
    private ngxSpinnerService: NgxSpinnerService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  async ngOnInit() {
    this.ngxSpinnerService.show();
    fromEvent(window, 'offline').subscribe(() => {
      // Close all dialogs.
      // Note: leave warning are disabled when offline.
      this.dialog.closeAll();
      this.currentUrl = this.router.url;
      this.router.navigateByUrl('/no-connection');
    });

    fromEvent(window, 'online').subscribe(() => {
      this.router.navigateByUrl(this.currentUrl);
    });

    try {
      const pageTestErrorAsked = new URLSearchParams(this.document.location.search).get('pageTestError');
      const isPageTestErrorAsked = pageTestErrorAsked === 'true';

      this.titleService.setTitle(`PARTENG Chargement...`);
      const healthcheckInfos = await firstValueFrom(
        this.healthcheckService.loadHealthcheckInfos$({ errorTest: isPageTestErrorAsked })
      );
      this.envInfo = this.configService.getEnvironmentInfo();
      this.isApiReady = !!healthcheckInfos;
      this.isChecking = false;
      this.ngxSpinnerService.hide();
    } catch (err) {
      this.isApiReady = false;
      this.ngxSpinnerService.hide();
      this.isChecking = false;
      this.envInfo = this.configService.getEnvironmentInfo();
    }
  }

  copyReportToClipboard() {
    const date = this.errorReport.date.toLocaleDateString();

    const text = `
    Rapport d'erreur :
    ${this.errorReport.title}
    ${this.errorReport.dateText}${date}
    ${this.errorReport.environmentText}${this.envInfo?.env?.envName}
    ${this.errorReport.versionBackHash}${this.envInfo?.apiVersion} / ${this.envInfo?.apiCommitHash}
    ${this.errorReport.versionFrontHash}${this.envInfo?.frontVersion}} / ${this.envInfo?.frontCommitHash}
    `;

    navigator.clipboard
      .writeText(text)
      .then(function () {
        console.log(`Text copied to clipboard : ${text}`);
      })
      .catch(function (error) {
        console.error('Error occurred while copying text: ', error);
      });
  }
}
