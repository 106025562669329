import { createAction, props } from '@ngrx/store';
import { Observable } from 'rxjs';

export const serviceRequestAction = createAction(
  '[SERVICE] REQUEST',
  props<{
    operation$: Observable<unknown>;
    stateSliceKey: string;
    successMessage?: string;
    failMessage?: string;
  }>()
);

export const serviceResponseAction = createAction(
  '[SERVICE] RESPONSE',
  props<{
    stateSliceKey: string;
    result: unknown;
  }>()
);

export const serviceErrorAction = createAction(
  '[SERVICE] ERROR',
  props<{
    stateSliceKey: string;
    error: unknown;
  }>()
);
