import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { AccessDeniedComponent } from './access-denied.component';
import { LogoutGuard } from './guards/logout.guard';
import { PermissionsGuard } from './guards/permissions.guard';
import { LoginComponent } from './login.component';
import { LogOutComponent } from './logout.component';
import { NoConnectionComponent } from './no-connection.component';
import { OAuthComponent } from './oauth.component';

export const ROUTES: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  { path: 'logout', component: LogOutComponent, canActivate: [LogoutGuard] },
  { path: 'oauth2', component: OAuthComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: 'no-connection', component: NoConnectionComponent },
  {
    path: '',
    component: AppComponent,
    canLoad: [PermissionsGuard],
    loadChildren: () => import('../app.module').then((m) => m.AppModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
    paramsInheritanceStrategy: 'always',
    enableTracing: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
}),
  ],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
