/**
 * @file
 * A pipe to apply another pipe dynamically.
 *
 * The name of the pipe to apply, and its arguments if any,
 * are passed as arguments to this pipe.
 *
 * Example:
 *
 *   {{ myVar | applyPipe: columnDef.pipeName }}
 */
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';

import { FillEmptyPipe } from './fill-empty.pipe';
import { PersonTypeToTranslatedLabelPipe } from './person-type-to-translated-label.pipe';

// List of supported dynamic pipes
const dynamicPipes = {
  personTypeToTranslatedLabel: 'personTypeToTranslatedLabel' as const,
  date: 'date' as const,
  fillEmpty: 'fillEmpty' as const,
};

export type DynamicPipes = keyof typeof dynamicPipes;

@Pipe({
  standalone: true,
  name: 'applyPipe',
})
export class ApplyPipePipe implements PipeTransform {
  private dynamicPipeMapping: Record<DynamicPipes, PipeTransform> = {
    personTypeToTranslatedLabel: inject(PersonTypeToTranslatedLabelPipe),
    date: inject(DatePipe),
    fillEmpty: inject(FillEmptyPipe),
  };

  transform(value: any, pipeName: DynamicPipes, pipeArgs: any[] | undefined): unknown {
    const pipeService = this.getPipeService(pipeName);
    return pipeService.transform(value, ...(pipeArgs || []));
  }

  private getPipeService(pipeName: DynamicPipes): any {
    return this.dynamicPipeMapping[pipeName];
  }
}
