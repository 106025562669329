import { Pipe, PipeTransform } from '@angular/core';
import { Person } from '@app/project/models';
import { PersonsSortByNamePipe } from './persons-sort-by-name.pipe';

@Pipe({  standalone: true,
  name: 'personsGroupToString',
})
export class PersonsGroupToStringPipe implements PipeTransform {
  constructor(private personsSortByNamePipe: PersonsSortByNamePipe) {}

  transform(personsGroup: Person[] | undefined) {
    return personsGroup === undefined
      ? ''
      : this.personsSortByNamePipe
          .transform(personsGroup)
          .map((person) => person.name)
          .join(' | ');
  }
}
