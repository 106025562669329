import { Pipe, PipeTransform } from '@angular/core';
import { ProjectTypeService } from '@app/project/services/project-type.service';

@Pipe({  standalone: true,
  name: 'projectTypeNamePipe',
})
export class ProjectTypeNamePipe implements PipeTransform {
  constructor(private projectTypeService: ProjectTypeService) {}

  transform(projectTypeId: number): string {
    return this.projectTypeService.getProjectTypeById(projectTypeId)?.name || '';
  }
}
