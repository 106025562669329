import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HALResource } from '@app/common/models';
import { PartengApiService, RestService, SerializerService } from '@app/common/services';
import { Goal, GoalDto } from '../models/goal.model';

@Injectable({ providedIn: 'root' })
export class GoalSerializerService extends SerializerService<Goal, GoalDto> {
  fromDto(json: GoalDto | HALResource<GoalDto>): Goal {
    return new Goal(json);
  }

  toDto(entity: Goal): any {
    return {
      ...this.getDtoBaseProps(entity),
      name: entity.name,
      order: entity.order,
      comment: entity.comment!,
    };
  }
}

@Injectable({ providedIn: 'root' })
export class GoalService extends PartengApiService<Goal, HALResource<GoalDto>> {
  constructor(rest: RestService, serializer: GoalSerializerService) {
    super(rest, serializer, '/goals', 'goals');
  }

  getAll$(): Observable<Goal[]> {
    return this.getCollection$();
  }
}
