import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DIALOG_WIDTH_SMALL } from '@app/common/common.constants';
import { RestService } from '@app/common/services';
import { HalApiService } from '@app/common/services/hal-api.service';
import { SettingsService } from '@app/data-entry/services/settings.service';
import _ from 'lodash';
import { filter, forkJoin, iif, map, mergeAll, mergeMap, Observable, of, switchMap, tap, toArray } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  ProjectWorkflowDialogComponent,
  ProjectWorkflowDialogData,
  ProjectWorkflowReturnValue,
} from '../components/project-workflow/project-workflow-dialog.component';
import { Project } from '../models';
import { ProjectValidationStatus, ProjectValidationStatusId } from '../models/project-validation-status.model';
import { FolderService2 } from './folder2.service';
import { ProjectGoalService2 } from './project-goal2.service';
import { ScopeService } from './scope.service';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  allProjectList: Project[] = [];
  isAllProjectNeedLoaded = true;

  constructor(
    private halApiService: HalApiService,
    private restService: RestService,
    private settingsService: SettingsService,
    private scopeService: ScopeService,
    private projectGoalService: ProjectGoalService2,
    private folderService: FolderService2,
    private dialog: MatDialog
  ) {}

  newProject(): Project {
    const project = new Project();
    project.status = this.settingsService.get<number>('STATUS_ACTIVE')!;
    project.validationStatus = this.settingsService.get<ProjectValidationStatusId>(
      'PROJECT_VALIDATION_STATUS_CREATED'
    )!;
    return project;
  }

  getAll$(validationStatusIds: ProjectValidationStatusId[] = []): Observable<Project[]> {
    return of(this.allProjectList).pipe(
      mergeMap((projects) =>
        iif(
          () => this.isAllProjectNeedLoaded === true,
          of(projects).pipe(
            switchMap(() => this.halApiService.getCollection$<Project>(Project, '/projects', {}, 'projects')),
            map((projects) => _.reverse(_.sortBy(projects, ['dateMin', 'id']))),
            tap((projects) => {
              this.isAllProjectNeedLoaded = false;
              this.allProjectList = projects;
            })
          ),
          of(projects)
        )
      ),
      mergeMap((projects) =>
        iif(
          () => validationStatusIds.length !== 0,
          of(projects).pipe(
            mergeAll(),
            filter((project) => validationStatusIds.includes(project.validationStatus)),
            toArray()
          ),
          of(projects)
        )
      )
    );
  }

  getAllWithoutEmbeds$(): Observable<Project[]> {
    return this.halApiService
      .getCollection$<Project>(Project, '/projects', {}, 'projects')
      .pipe(map((projects) => _.reverse(_.sortBy(projects, ['dateMin', 'id']))));
  }

  getNotReviewProjectsWithAtLeastOneFolder$(): Observable<Project[]> {
    return this.halApiService
      .getCollection$(Project, '/projects', { sets: 'full', not_reviewed_only: 'true' }, 'projects')
      .pipe(map((projects) => _.reverse(_.sortBy(projects, ['dateMin', 'id']))));
  }

  getById$(projectId: number): Observable<Project> {
    return this.halApiService.getOne$<Project>(Project, `/projects/${projectId}`, { sets: 'full' }).pipe(
      // Re-hydrate project goals completely, if any
      mergeMap((project) =>
        project.relProjectToGoals.length > 0
          ? forkJoin(
              project.relProjectToGoals.map((relProjectToGoal) =>
                this.projectGoalService.enrichProjectGoalWithPersons$(project.id, relProjectToGoal)
              )
            ).pipe(map((relProjectToGoals) => project.updateAllRelProjectToGoals(relProjectToGoals)))
          : of(project)
      )
    );
  }

  private save$(project: Project): Observable<Project> {
    this.isAllProjectNeedLoaded = true;
    // Because of the workflow when creating a project we have to save the project with incomplete data first.
    // The firstClosingDate is mandatory, so we set a default value : 2001-01-01
    project.firstClosingDate = !!project.firstClosingDate ? project.firstClosingDate : '2000-01-01';
    const loadExistingProject$ = project.id ? this.getById$(project.id) : of(this.newProject());
    const saveProject$: Observable<Project> = project.id
      ? this.halApiService.putOne$<Project>(`/projects/${project.id}`, {}, project)
      : this.halApiService.postOne$<Project>('/projects', {}, project);

    return loadExistingProject$.pipe(
      mergeMap((presaveProject) => saveProject$.pipe(map((savedProject) => ({ savedProject, presaveProject })))),
      // inject the id into the project to save, in case it's a new project
      mergeMap(({ savedProject, presaveProject }) => {
        const projectWithId = project.clone({ id: savedProject.id });
        return this.saveProjectRelations$(projectWithId, presaveProject).pipe(
          mergeMap(() => this.getById$(projectWithId.id))
        );
      })
    );
  }

  private saveProjectRelations$(projectWithId: Project, presaveProject: Project) {
    return forkJoin([
      this.scopeService.saveProjectScopes$(projectWithId, presaveProject),
      this.projectGoalService.saveProjectGoals$(projectWithId, presaveProject),
      this.folderService.saveProjectFolders$(projectWithId, presaveProject),
    ]);
  }

  saveAsDraft$(project: Project): Observable<Project> {
    return this.save$(project.clone({ status: this.settingsService.get<number>('STATUS_DRAFT')! }));
  }

  saveAsActive$(project: Project): Observable<Project> {
    return this.save$(project.clone({ status: this.settingsService.get<number>('STATUS_ACTIVE')! }));
  }

  delete$(project: Project) {
    this.isAllProjectNeedLoaded = true;
    return this.deleteById$(project.id);
  }

  private deleteById$(projectId: number) {
    return this.restService.delete({ url: `${environment.api.baseURL}/projects/${projectId}` });
  }

  showWorkflowDialog(data: ProjectWorkflowDialogData): Observable<ProjectWorkflowReturnValue | undefined> {
    return this.dialog
      .open<ProjectWorkflowDialogComponent, ProjectWorkflowDialogData, ProjectWorkflowReturnValue>(
        ProjectWorkflowDialogComponent,
        {
          width: DIALOG_WIDTH_SMALL,
          data,
        }
      )
      .afterClosed();
  }

  updateProjectValidationStatus(
    projectId: number,
    validationStatus: ProjectValidationStatus,
    comment?: string
  ): Observable<unknown> {
    this.isAllProjectNeedLoaded = true;
    return this.halApiService.patch$(
      `/projects/${projectId}`,
      { projects_id: projectId.toString() },
      {
        validation_status: this.settingsService.get<ProjectValidationStatusId>(validationStatus),
        history_comment: comment,
      }
    );
  }

  // Group/Pill (1)
  isProjectCreated(project: Project): boolean {
    return (
      project.validationStatus ===
      this.settingsService.get<ProjectValidationStatusId>('PROJECT_VALIDATION_STATUS_CREATED')
    );
  }

  // Group/Pill (2)
  isProjectInInput(project: Project): boolean {
    return [
      this.settingsService.get<ProjectValidationStatusId>('PROJECT_VALIDATION_STATUS_INPUT'),
      this.settingsService.get<ProjectValidationStatusId>('PROJECT_VALIDATION_STATUS_VERIFICATION_REJECTED'),
    ].includes(project.validationStatus);
  }

  // Group/Pill (3)
  isProjectInVerification(project: Project): boolean {
    return [
      this.settingsService.get<ProjectValidationStatusId>('PROJECT_VALIDATION_STATUS_VERIFICATION'),
      this.settingsService.get<ProjectValidationStatusId>('PROJECT_VALIDATION_STATUS_VALIDATION_REJECTED'),
    ].includes(project.validationStatus);
  }

  // Group/Pill (4)
  isProjectInValidation(project: Project): boolean {
    return (
      project.validationStatus ===
      this.settingsService.get<ProjectValidationStatusId>('PROJECT_VALIDATION_STATUS_VALIDATION')
    );
  }

  // Group/Pill (5) - Validated
  isProjectValidated(project: Project): boolean {
    return (
      project.validationStatus ===
      this.settingsService.get<ProjectValidationStatusId>('PROJECT_VALIDATION_STATUS_VALIDATED')
    );
  }

  // Group/Pill (5) - Closed
  isProjectClosed(project: Project): boolean {
    return (
      project.validationStatus ===
      this.settingsService.get<ProjectValidationStatusId>('PROJECT_VALIDATION_STATUS_CLOSED')
    );
  }

  isProjectVerificationRejected(project: Project): boolean {
    return project.validationStatus === this.settingsService.get('PROJECT_VALIDATION_STATUS_VERIFICATION_REJECTED');
  }

  isProjectValidationRejected(project: Project): boolean {
    return project.validationStatus === this.settingsService.get('PROJECT_VALIDATION_STATUS_VALIDATION_REJECTED');
  }

  hasProjectAtLeastOneFolder(project: Project): boolean {
    return project.folders && !!project.folders.length && project.folders.length > 0;
  }

  hasProjectAtLeastOneFolderWithATransfer(project: Project): boolean {
    return (
      project.folders &&
      !!project.folders.length &&
      project.folders.length > 0 &&
      project.folders.some((p) => !!p.dateMin)
    );
  }

  canProjectBeClosed(project: Project): boolean {
    return (
      this.isProjectValidated(project) && project.folders.every((folder) => !this.folderService.isFolderEmpty(folder))
    );
  }
}
