<section class="scope-form2">
  <div *ngIf="mode === 'view'; else showForm" class="flex justify-between w-full">
    <!-- Fields -->
    <div class="fields">
      <div class="field">
        <span class="label">{{ 'project.scopeForm.code' | translate }}</span>
        <span class="value">{{ scope.code }}</span>
      </div>
      <div class="field">
        <span class="label">{{ 'project.scopeForm.name' | translate }}</span>
        <span class="value">{{ scope.name }}</span>
      </div>
      <div class="field">
        <span class="label">{{ 'project.scopeForm.historicalNames' | translate }}</span>
        <span class="value">{{ scope.historicalName | fillEmpty }}</span>
      </div>
      <div class="field">
        <span class="label">{{ 'project.scopeForm.city' | translate }}</span>
        <span class="value">{{ scope.city | fillEmpty }}</span>
      </div>
      <div class="field">
        <span class="label">{{ 'project.scopeForm.worlds' | translate }}</span>
        <span class="value">{{ scope | scopeWorlds }}</span>
      </div>
      <div class="field">
        <span class="label">{{ 'project.scopeForm.comment' | translate }}</span
        ><br />
        <span class="value">{{ scope.comment | fillEmpty }}</span>
      </div>
    </div>
    <!-- Edit Icon -->
    <div>
      <mat-icon
        aria-hidden="false"
        [attr.aria-label]="'dataEntry.pageTransferForm.personsBlock.selectPerson' | translate"
        class="text-neutral-400 cursor-pointer ml-2 picto"
        (click)="switchToEditMode()"
        [can-edit-feature]="Feature.scopes"
        data-testId="scope-update-button"
        >edit</mat-icon
      >
    </div>
  </div>

  <ng-template #showForm>
    <form [formGroup]="form" *ngIf="form" (ngSubmit)="submit()">
      <div>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>{{ 'project.scopeForm.code' | translate }}</mat-label>
          <input matInput formControlName="code" cdkFocusInitial maxlength="30" data-testId="scope-code-input" />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>{{ 'project.scopeForm.name' | translate }}</mat-label>
          <input matInput formControlName="name" maxlength="100" data-testId="scope-name-input" />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>{{ 'project.scopeForm.historicalNames' | translate }}</mat-label>
          <input matInput formControlName="historical_name" maxlength="100" data-testId="scope-historical-name-input" />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>{{ 'project.scopeForm.city' | translate }}</mat-label>
          <input matInput formControlName="city" maxlength="50" data-testId="scope-city-input" />
        </mat-form-field>
      </div>

      <div formGroupName="worlds" *ngIf="isWorldsFieldReady">
        <mat-label [ngClass]="{ 'text-neutral-600': !isWorldsInvalid, 'text-red-ptg-alert': isWorldsInvalid }"
          >{{ 'project.scopeForm.worlds' | translate }} *</mat-label
        >
        <parteng-error-block
          *ngIf="isWorldsInvalid"
          [error]="'project.scopeForm.atLeastOneWorldRequired' | translate"
        ></parteng-error-block>
        <div class="columns-2 py-2">
          <div *ngFor="let world of worlds">
            <mat-checkbox [formControlName]="world.id" data-testId="scope-world-checkbox">{{
              world.name
            }}</mat-checkbox>
          </div>
        </div>
      </div>

      <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{ 'project.scopeForm.comment' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="comment"
          rows="4"
          maxlength="1000"
          data-testId="scope-comment-input"
        ></textarea>
      </mat-form-field>
    </form>
  </ng-template>
</section>
