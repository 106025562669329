import { Pipe, PipeTransform } from '@angular/core';
import { ProjectValidationStatusService } from '@app/common/services/project-validation-status.service';
import {
  ProjectValidationStatus,
  ProjectValidationStatusId,
} from '@app/project/models/project-validation-status.model';

@Pipe({  standalone: true,
  name: 'projectValidationStatusId',
})
export class ProjectValidationStatusIdPipe implements PipeTransform {
  constructor(private projectValidationStatusService: ProjectValidationStatusService) {}

  transform(value: ProjectValidationStatus): ProjectValidationStatusId {
    return this.projectValidationStatusService.getProjectValidationStatusId(value);
  }
}
