import { VALIDATION_STATUS } from '@app/common/models';
import {
  HALDate,
  HALDeserializeFrom,
  HALOptional,
  HALSerializeTo,
  HALSerializeValueToNewValue,
} from '@app/common/services/hal-serializer.service';
import { Project } from '@app/project/models';
import { Attribute } from './attribute.model';

export enum AccessModeVersion {
  AddVersion = 'AddVersion',
}

export class InstrumentVersion {
  @HALDeserializeFrom()
  id: number = 0;

  @HALDeserializeFrom()
  @HALSerializeTo()
  name: string = '';

  @HALDeserializeFrom()
  order: number = 0;

  @HALDeserializeFrom()
  @HALSerializeTo()
  @HALOptional()
  changes: string = '';

  @HALDeserializeFrom('validation_status')
  @HALSerializeTo('validation_status')
  validationStatus: VALIDATION_STATUS = VALIDATION_STATUS.NOT_REVIEWED;

  @HALDeserializeFrom()
  @HALSerializeTo()
  @HALOptional()
  status: number = 0;

  @HALDeserializeFrom('creation_projects_id')
  @HALSerializeTo('creation_projects_id')
  @HALSerializeValueToNewValue({ 0: null })
  creationProjectId: number = 0;

  @HALDeserializeFrom('project')
  @HALOptional()
  creationProject!: Project;

  @HALDeserializeFrom('effective_date')
  @HALSerializeTo('effective_date')
  @HALDate()
  effectiveDate: Date | undefined;

  @HALDeserializeFrom('instruments_id')
  @HALSerializeTo('instruments_id')
  instrumentId: number = 0;

  @HALSerializeTo()
  @HALDeserializeFrom('attributes', Attribute)
  @HALOptional()
  attributes: Attribute[] = [];
}
