import { Pipe, PipeTransform } from '@angular/core';
import { Project } from '@app/project/models';
import { ProjectTypeService } from '@app/project/services/project-type.service';
import { VALIDATION_STATUS } from '../models';

export interface ProjectsTableItem {
  id: number;
  name: string;
  projectTypeName: string;
  scopeCodesStr: string; // codes of project scopes as string
  scopesStr: string; // all project scopes as a single string (name + historical_name)
  goalsStr: string; // all project goals as a single string
  dateMin: string;
  dateMax: string;
  firstClosingDate: string;
  validationStatus: VALIDATION_STATUS;
  comment?: string;
}

@Pipe({  standalone: true,
  name: 'projectsTableItems',
})
export class ProjectsTableItemsPipe implements PipeTransform {
  constructor(private projectTypeService: ProjectTypeService) {}

  transform(projects: Project[]): ProjectsTableItem[] {
    return projects.map(
      (project): ProjectsTableItem => ({
        id: project.id,
        name: project.name,
        projectTypeName: this.projectTypeService.getProjectTypeById(project.projectTypeId)?.name || '',
        // Convert project scopes and goals to strings so that
        // these fields may be used as filters in the projects list
        scopeCodesStr: project.relProjectToScopes
          .map((rel) => rel.scope)
          .map((scope) => scope.code)
          .join(', '),
        scopesStr: project.relProjectToScopes
          .map((rel) => rel.scope)
          .map((scope) => `${scope.name}__${scope.historicalName}__${scope.city}`)
          .join(', '),
        goalsStr: project.relProjectToGoals.map((rel) => rel.goal.name).join(', '),
        dateMin: project.dateMin,
        dateMax: project.dateMax,
        firstClosingDate: project.firstClosingDate,
        validationStatus: project.validationStatus,
        comment: project.comment,
      })
    );
  }
}
