import { Pipe, PipeTransform } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Pipe({  standalone: true,
  name: 'tableDataSource',
})
export class TableDataSourcePipe implements PipeTransform {
  transform<T>(items: T[]): MatTableDataSource<T> {
    return new MatTableDataSource<T>(items);
  }
}
