import { Pipe, PipeTransform } from '@angular/core';

@Pipe({  standalone: true,
  name: 'fillEmpty',
})
export class FillEmptyPipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    return !!value ? value : '-';
  }
}
