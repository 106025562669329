import { EntityWithId } from '@app/common/models';
import { HALDeserializeFrom, HALSerializeTo } from '@app/common/services/hal-serializer.service';

export class ProjectType extends EntityWithId {
  @HALDeserializeFrom()
  @HALSerializeTo()
  override status: number = 0;

  @HALDeserializeFrom()
  override id: number = 0;

  @HALDeserializeFrom()
  override created: number = 0;

  @HALDeserializeFrom()
  override updated: number = 0;

  @HALDeserializeFrom()
  name: string = '';

  @HALDeserializeFrom()
  order: number = 0;
}
