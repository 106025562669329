import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DIALOG_WIDTH_LARGE, DIALOG_WIDTH_MEDIUM, DIALOG_WIDTH_SMALL } from '@app/common/common.constants';
import {
  DialogFundManagerSelectorSharedComponent,
  DialogFundManagerSelectorSharedData,
} from '@app/common/components/dialog-fund-manager-selector/dialog-fund-manager-selector-shared.component';
import { DialogPersonProjectComponent } from '@app/common/components/dialog-person-project/dialog-person-project.component';
import { DialogPersonComponent, DialogPersonData } from '@app/common/components/dialog-person/dialog-person.component';
import {
  DialogPersonsSelectorComponent,
  DialogPersonsSelectorData,
} from '@app/common/components/dialog-persons-selector/dialog-persons-selector.component';
import {
  PersonFormMode,
  PersonFormResult,
} from '@app/common/components/person-form-shared/person-form-shared.component';
import { HALResource } from '@app/common/models';
import { Feature } from '@app/common/models/role.model';
import { PartengApiService, RestService, SerializerService } from '@app/common/services';
import { SettingsService } from '@app/data-entry/services/settings.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable, forkJoin } from 'rxjs';
import { defaultIfEmpty, map, switchMap } from 'rxjs/operators';
import { Project } from '../models';
import { Person, PersonDto, PersonType, PersonTypeId } from '../models/person.model';
import { PersonGroupService } from './person-group.service';

@Injectable({ providedIn: 'root' })
export class PersonSerializerService extends SerializerService<Person, PersonDto> {
  fromDto(json: HALResource<PersonDto>): Person {
    return new Person(json);
  }

  toDto(entity: Person): any {
    return {
      ...this.getDtoBaseProps(entity),

      // note that entity.name is a computed prop and is not included in the DTO
      first_name: entity.first_name,
      last_name: entity.last_name,
      company_name: entity.company_name,
      short_name: entity.short_name,
      person_type: entity.person_type,
      creation_projects_id: entity.creation_projects_id,

      legal_entity_types_id: entity.legal_entity_types_id!,
      legal_entity_identifier: entity.legal_entity_identifier!,
      legal_entity_country_code: entity.legal_entity_country_code!,
      legal_entity_pending_registration: entity.legal_entity_pending_registration,

      fund_types_id: entity.fund_types_id!,
      fund_manager_id: entity.fund_manager_id!,

      validation_status: entity.validation_status,
      comment: entity.comment!,
    };
  }
}

@Injectable({ providedIn: 'root' })
export class PersonService extends PartengApiService<Person, HALResource<PersonDto>> {
  constructor(
    rest: RestService,
    serializer: PersonSerializerService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private settingsService: SettingsService,
    private personGroupService: PersonGroupService
  ) {
    super(rest, serializer, '/persons', 'persons');
  }

  getPersonTypeId(personType: PersonType): PersonTypeId {
    return this.settingsService.get<PersonTypeId>(personType)!;
  }

  newPerson(project: Project | undefined, personTypeId?: PersonTypeId): Person {
    return new Person({
      creation_projects_id: project ? project.id : undefined,
      person_type: personTypeId,
      legal_entity_country_code: 'FR',
    });
  }

  isLegalPerson(person: Person): boolean {
    return person.person_type === this.settingsService.get<PersonTypeId>('PERSON_TYPE_LEGAL_PERSON');
  }

  isFundPerson(person: Person): boolean {
    return person.person_type === this.settingsService.get<PersonTypeId>('PERSON_TYPE_INVESTMENT_FUND');
  }

  isGroupPerson(person: Person): boolean {
    return person.person_type === this.settingsService.get<PersonTypeId>('PERSON_TYPE_GROUP');
  }

  showPersonSelectorDialog(opts: {
    project: Project;
    title: string;
    description: string;
    selectedPersons?: Person[];
    titleName?: string;
    disableAddPersonButton?: boolean;
    hideAddPersonButton?: boolean;
    isMonoSelection?: boolean;
    closeAfterAdd?: boolean;
    forceLargeWidth?: boolean;
    personTypeListAuthorized?: PersonTypeId[];
    disableGroups?: boolean;
    selectedItemsDescription?: string;
    selectedItemsTitle?: string;
  }): Observable<Person[] | undefined | false> {
    return this.dialog
      .open<DialogPersonsSelectorComponent, DialogPersonsSelectorData, Person[]>(DialogPersonsSelectorComponent, {
        width: opts.forceLargeWidth ? DIALOG_WIDTH_LARGE : DIALOG_WIDTH_MEDIUM,
        data: {
          project: opts.project,
          dialogTitle: this.translateService.instant(opts.title, { name: opts.titleName }),
          dialogDescription: this.translateService.instant(opts.description),
          additionalInfoTitle: this.translateService.instant('shared.dialogPersonSelector.itemAdditionalInfoTitle'),
          selectedPersons: opts.selectedPersons || [],
          disableAddPersonButton: !!opts.disableAddPersonButton,
          hideAddPersonButton: !!opts.hideAddPersonButton,
          isMonoSelection: opts.isMonoSelection ?? true,
          closeAfterAdd: opts.closeAfterAdd ?? true,
          selectedItemsDescription:
            opts.selectedItemsDescription === undefined
              ? ''
              : this.translateService.instant(opts.selectedItemsDescription),
          selectedItemsTitle:
            opts.selectedItemsTitle === undefined ? '' : this.translateService.instant(opts.selectedItemsTitle),
          personTypeListAuthorized:
            opts.disableGroups === true
              ? [
                  this.getPersonTypeId('PERSON_TYPE_LEGAL_PERSON'),
                  this.getPersonTypeId('PERSON_TYPE_NATURAL_PERSON'),
                  this.getPersonTypeId('PERSON_TYPE_INVESTMENT_FUND'),
                ]
              : opts.personTypeListAuthorized ?? [],
        },
      })
      .afterClosed();
  }

  showPersonDialog({
    project,
    personTypeId,
    mode,
    person,
    showDeleteButton = false,
    fundManager = false,
    disablePersonCreation = false,
    readonly = false,
    contextFeature,
  }: {
    project?: Project;
    personTypeId: PersonTypeId;
    mode: PersonFormMode;
    person?: Person;
    showDeleteButton?: boolean;
    fundManager?: boolean;
    disablePersonCreation?: boolean;
    readonly?: boolean;
    // Note: we might to explicitly set the contextFeature as undefined.
    // And we still want it to be optional. Thus, we deactivate the SonarLint code smell typescript:S4782.
    contextFeature?: Feature | undefined; // NOSONAR
  }): Observable<PersonFormResult | undefined> {
    return this.dialog
      .open<DialogPersonComponent, DialogPersonData, PersonFormResult>(DialogPersonComponent, {
        width: DIALOG_WIDTH_SMALL,
        data: {
          mode,
          project,
          showDeleteButton,
          person: person || this.newPerson(project, personTypeId),
          fundManager,
          disablePersonCreation,
          readonly,
          contextFeature,
        },
      })
      .afterClosed();
  }

  showFundManagerSelectorDialog(project?: Project, disablePersonCreation = false): Observable<Person[] | undefined> {
    return this.dialog
      .open<DialogFundManagerSelectorSharedComponent, DialogFundManagerSelectorSharedData>(
        DialogFundManagerSelectorSharedComponent,
        {
          width: DIALOG_WIDTH_SMALL,
          data: {
            project,
            disablePersonCreation,
          },
        }
      )
      .afterClosed();
  }

  showFundManagerCreationDialog(project?: Project): Observable<Person | undefined> {
    // Note: a fund manager is a legal person.
    return this.showPersonDialog({
      project,
      personTypeId: this.settingsService.get<PersonTypeId>('PERSON_TYPE_LEGAL_PERSON')!,
      mode: 'create',
      showDeleteButton: false,
      fundManager: true,
      contextFeature: Feature.persons,
    }).pipe(map((createdPersons) => createdPersons?.person));
  }

  showPersonCreationProjectDialog(person: Person): Observable<void> {
    return this.dialog
      .open(DialogPersonProjectComponent, {
        width: DIALOG_WIDTH_MEDIUM,
        data: { person },
      })
      .afterClosed();
  }

  getAll$(): Observable<Person[]> {
    return this.getCollection$().pipe(
      map((persons) => _.sortBy(persons, 'name')),
      // Set the fund manager name for each fund
      map((persons) => {
        const fundManagerIdList = persons
          .filter((person) => !!person.fund_manager_id)
          .map((person) => person.fund_manager_id as NonNullable<number>);
        const fundManagers = persons.filter((person) => fundManagerIdList.includes(person.id));
        return persons.map((person) =>
          !!person.fund_manager_id
            ? person.clone({ $fundManagerName: fundManagers.find((fm) => fm.id === person.fund_manager_id)?.name })
            : person
        );
      }),
      // Set persons in group for each group
      switchMap((persons) =>
        this.personGroupService.getAllGroupsWithTheirMembers$().pipe(
          map((allGroups) =>
            allGroups.map((group) => {
              const personGroup = persons.find((person) => person.id === group.id) as Person;
              return new Person({
                id: group.id,
                personsInGroup: group.personsInGroup,
                first_name: personGroup.first_name,
                last_name: personGroup.last_name,
                company_name: personGroup.company_name,
                name: personGroup.name,
                short_name: personGroup.short_name,
                person_type: personGroup.person_type,
                creation_projects_id: personGroup.creation_projects_id,
                creationProject: personGroup.creationProject,
                has_balance_sheet_impacted: personGroup.has_balance_sheet_impacted,
                has_capitalization_table_impacted: personGroup.has_capitalization_table_impacted,
                legal_entity_types_id: personGroup.legal_entity_types_id,
                legal_entity_identifier: personGroup.legal_entity_identifier,
                legal_entity_country_code: personGroup.legal_entity_country_code,
                legal_entity_pending_registration: personGroup.legal_entity_pending_registration,
                fund_types_id: personGroup.fund_types_id,
                fund_manager_id: personGroup.fund_manager_id,
                $fundManagerName: personGroup.$fundManagerName,
                comment: personGroup.comment,
                validation_status: personGroup.validation_status,
              });
            })
          ),
          map((allGroups) => ({
            persons,
            allGroups,
          }))
        )
      ),
      map(({ persons, allGroups }) => {
        const uniquePersonListWithFilledGroups = [...allGroups, ...persons].filter(
          (person, index, self) => index === self.findIndex((p) => p.id === person.id)
        );
        return {
          persons: uniquePersonListWithFilledGroups,
          allGroups,
        };
      }),
      // Set groups for each person
      map(({ persons, allGroups }) => {
        return persons.map((person) =>
          person.clone({
            groups: allGroups.filter((group) => group.personsInGroup?.find((p) => p.id === person.id)),
          })
        );
      })
    );
  }

  getByIds$(ids: number[]): Observable<Person[]> {
    return forkJoin(ids.map((id) => this.getById$(id))).pipe(defaultIfEmpty([]));
  }

  getByTypes$(personsTypes: string[]) {
    return this.personsMapper$(this.getCollection$({ queryParams: { ['person_type[]']: personsTypes } }));
  }

  getCreatedPersons$(projectId: number): Observable<Person[]> {
    return this.personsMapper$(
      this.getCollection$({
        queryParams: {
          creation_projects_id: projectId.toString(),
        },
      })
    );
  }

  getReferencedPersons$(projectId: number): Observable<Person[]> {
    return this.personsMapper$(
      this.getCollection$({
        queryParams: {
          projects_id: projectId.toString(),
        },
      })
    );
  }

  private personsMapper$(persons$: Observable<Person[]>): Observable<Person[]> {
    return persons$.pipe(
      map((persons) => _.sortBy(persons, 'name')),
      // Set the fund manager name for each fund
      switchMap((persons) =>
        this.getByIds$([
          ...new Set(persons.filter((person) => !!person.fund_manager_id).map((person) => person.fund_manager_id!)),
        ]).pipe(
          map((fundManagers) =>
            persons.map((person) =>
              !!person.fund_manager_id
                ? person.clone({ $fundManagerName: fundManagers.find((fm) => fm.id === person.fund_manager_id)?.name })
                : person
            )
          )
        )
      ),
      // Set persons in group for each group
      switchMap((persons) => {
        return this.personGroupService.getAllGroupsWithTheirMembers$().pipe(
          switchMap((allGroups) =>
            this.getCollection$().pipe(
              map((allPersons) => ({
                allGroups,
                allPersons,
              }))
            )
          ),
          map(({ allGroups, allPersons }) =>
            allGroups.map((group) => {
              // Sparadra to be corrected on the backend. It should return the value of has_balance_sheet_impacted in the GET api/rest/v1/persons request.
              let personGroup = persons.find((person) => person.id === group.id);
              if (personGroup === undefined) {
                personGroup = allPersons.find((person) => person.id === group.id) as Person;
              }

              return new Person({
                id: group.id,
                personsInGroup: group.personsInGroup,
                first_name: personGroup.first_name,
                last_name: personGroup.last_name,
                company_name: personGroup.company_name,
                name: personGroup.name,
                short_name: personGroup.short_name,
                person_type: personGroup.person_type,
                creation_projects_id: personGroup.creation_projects_id,
                creationProject: personGroup.creationProject,
                has_balance_sheet_impacted: personGroup.has_balance_sheet_impacted,
                has_capitalization_table_impacted: personGroup.has_capitalization_table_impacted,
                legal_entity_types_id: personGroup.legal_entity_types_id,
                legal_entity_identifier: personGroup.legal_entity_identifier,
                legal_entity_country_code: personGroup.legal_entity_country_code,
                legal_entity_pending_registration: personGroup.legal_entity_pending_registration,
                fund_types_id: personGroup.fund_types_id,
                fund_manager_id: personGroup.fund_manager_id,
                $fundManagerName: personGroup.$fundManagerName,
                comment: personGroup.comment,
                validation_status: personGroup.validation_status,
              });
            })
          ),
          map((allGroups) => ({
            persons,
            allGroups,
          }))
        );
      }),
      map(({ persons, allGroups }) => {
        const uniquePersonListWithFilledGroups = persons.map((person) => {
          const personIsAGroup = allGroups.find((group) => group.id === person.id);
          return personIsAGroup ?? person;
        });
        return {
          persons: uniquePersonListWithFilledGroups,
          allGroups,
        };
      }),
      // Set groups for each person
      map(({ persons, allGroups }) => {
        return persons.map((person) =>
          person.clone({
            groups: allGroups.filter((group) => group.personsInGroup?.find((p) => p.id === person.id)),
          })
        );
      })
    );
  }

  save$(person: Person): Observable<Person> {
    return person.id ? this.updatePerson$(person) : this.createPerson$(person);
  }

  createPerson$(person: Person): Observable<Person> {
    return this.postOne$(person);
  }

  updatePerson$(person: Person): Observable<Person> {
    return this.putOne$(person, person.id);
  }

  updatePersonProjectCreation$(person: Person, projectId: number): Observable<Person> {
    return this.patchOne$(person.id, { creation_projects_id: projectId });
  }

  deletePerson$(person: Person): Observable<void> {
    return this.deleteOne$({ endpoint: '/persons/' + person.id });
  }

  isPersonReviewed(person: Person): boolean {
    return person.validation_status === this.settingsService.get('VALIDATION_STATUS_REVIEWED');
  }

  isGroupPersonLocked(person: Person): boolean {
    return person.validation_status === this.settingsService.get('PERSON_VALIDATION_STATUS_NOT_REVIEWED_LOCKED');
  }

  isPersonNotReviewed(person: Person): boolean {
    return person.validation_status === this.settingsService.get('VALIDATION_STATUS_NOT_REVIEWED');
  }

  setPersonReviewed(person: Person) {
    return this.patchOne$(person.id, { validation_status: this.settingsService.get('VALIDATION_STATUS_REVIEWED') });
  }

  setPersonNotReviewed(person: Person) {
    return this.patchOne$(person.id, { validation_status: this.settingsService.get('VALIDATION_STATUS_NOT_REVIEWED') });
  }

  setGroupPersonLocked(person: Person) {
    return this.patchOne$(person.id, {
      validation_status: this.settingsService.get('PERSON_VALIDATION_STATUS_NOT_REVIEWED_LOCKED'),
    });
  }
}
