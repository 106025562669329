import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [MatIconModule, NgIf, TranslateModule],
  selector: 'parteng-help-container',
  template: `
    <!-- Help trigger -->
    <div
      *ngIf="show"
      class="flex flex-col absolute bg-transparent w-[50px] h-[50px] bg-gray-ptg-2 text-neutral-500 hover:text-blue-ptg-secondary hover:h-[60px] z-[102] top-[60px] left-[80%] rounded-b-[10px] shadow-lg cursor-pointer border border-gray-ptg-1 shadow-[0px_4px_5px_rgba(32, 32, 32, 0.15)] text-center align-text-center text-[24px] pt-2"
      (click)="isHelpOpen = !isHelpOpen"
      (mouseenter)="isHelpHover = true"
      (mouseleave)="isHelpHover = false"
      data-testId="help-trigger"
    >
      <mat-icon *ngIf="!isHelpOpen" [inline]="true" aria-hidden="true">help_outline</mat-icon>
      <mat-icon
        *ngIf="isHelpOpen"
        [inline]="true"
        [svgIcon]="isHelpHover ? 'help_close_active' : 'help_close'"
        class="!w-[24px] !h-[24px] mx-auto"
        data-testId="help-trigger-icon"
      ></mat-icon>
      <p class="text-xs font-bold" *ngIf="isHelpHover">
        {{ (isHelpOpen ? 'shared.close' : 'shared.help') | translate }}
      </p>
    </div>
    <!-- Help container -->
    <div
      class="fixed w-full z-[101] pt-24"
      [@toggle]="isHelpOpen ? 'open' : 'close'"
      [hidden]="!isHelpOpen"
      data-testId="help-container"
    >
      <div id="help-container" class="pl-[414px] pr-[346px]"></div>
    </div>
  `,
  styleUrls: ['./help-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('toggle', [
      state('open', style({ height: '100%', backgroundColor: 'rgba(230, 234, 239, 0.95)' })),
      state('close', style({ height: 0 })),
      transition('open => close', [animate('200ms ease-in')]),
      transition('close => open', [animate('200ms ease-out')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpContainerComponent {
  @Input() show = false;

  isHelpOpen = false;
  isHelpHover = false;
  title = 'parteng-bo-front';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {
    this.matIconRegistry.addSvgIcon(
      'help_close_active',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/icon_help-close-active.svg'),
      { viewBox: '0 0 24 24' }
    );
    this.matIconRegistry.addSvgIcon(
      'help_close',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/icon_help-close.svg')
    );
  }

  closeHelp() {
    this.isHelpOpen = false;
    this.cdr.markForCheck();
  }
}
