import { Injectable } from '@angular/core';
import { HalApiService } from '@app/common/services/hal-api.service';
import * as _ from 'lodash';
import { Observable, map } from 'rxjs';
import { InstrumentType } from '../models/instrument-type.model';

@Injectable({ providedIn: 'root' })
export class InstrumentTypeService {
  constructor(private halApiService: HalApiService) {}

  getAll$(): Observable<InstrumentType[]> {
    return this.halApiService
      .getCollection$(InstrumentType, '/instrument-types', {}, 'instrument_types')
      .pipe(map((instrumentTypes) => _.sortBy(instrumentTypes, 'order')));
  }
}
