import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT_LOCATION } from '@app/common/injection-tokens/document-location.injection-token';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

@Component({
  selector: 'parteng-login',
  template: ``,
})
export class LoginComponent implements OnInit {
  constructor(private http: HttpClient, @Inject(DOCUMENT_LOCATION) private location: Location) {}

  async ngOnInit() {
    try {
      await firstValueFrom(this.http.post(`${environment.api.baseURL}/authentication`, { code: '' }));
    } catch (err: any) {
      // TODO: manage error
      const azureURL = err['error']['redirect'];
      this.location.assign(azureURL);
    }
  }
}
