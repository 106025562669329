import { Injectable } from '@angular/core';
import { HALResource } from '@app/common/models';
import { SerializerService } from '@app/common/services';
import {
    Transfer,
    TransferDto,
    TransferInstrument,
    TransferInstrumentDto,
    TransferPerson,
    TransferPersonDto,
} from '../models';
import { TransferInstrumentSerializerService } from './transfer-instrument.service';
import { TransferPersonSerializerService } from './transfer-person.service';

@Injectable({ providedIn: 'root' })
export class TransferSerializerService extends SerializerService<Transfer, TransferDto> {
  constructor(
    private transferInstrumentSerializer: TransferInstrumentSerializerService,
    private transferPersonSerializerService: TransferPersonSerializerService
  ) {
    super();
  }

  fromDto(transferDto: HALResource<TransferDto>): Transfer {
    return new Transfer({
      ...transferDto,
      transferInstruments: this.extractTransferInstruments(transferDto),
      transferPersons: this.extractTransferPersons(transferDto),
    });
  }

  private extractTransferInstruments(transferDto: HALResource<TransferDto>): TransferInstrument[] {
    const transferInstrumentDtos: HALResource<TransferInstrumentDto>[] =
      transferDto._embedded &&
      transferDto._embedded['transfer_instruments'] &&
      Array.isArray(transferDto._embedded['transfer_instruments'])
        ? transferDto._embedded['transfer_instruments']
        : [];
    return transferInstrumentDtos.map((transferInstrumentDto) =>
      this.transferInstrumentSerializer.fromDto(transferInstrumentDto)
    );
  }

  private extractTransferPersons(transferDto: HALResource<TransferDto>): TransferPerson[] {
    const transferPersonDtos: HALResource<TransferPersonDto>[] =
      transferDto._embedded &&
      transferDto._embedded['transfer_persons'] &&
      Array.isArray(transferDto._embedded['transfer_persons'])
        ? transferDto._embedded['transfer_persons']
        : [];
    return transferPersonDtos.map((transferPersonDto) =>
      this.transferPersonSerializerService.fromDto(transferPersonDto)
    );
  }

  toDto(entity: Transfer): HALResource<any> {
    return {
      ...this.getDtoBaseProps(entity),
      folders_id: entity.folders_id,
      setup_transfers_id: entity.setup_transfers_id,
      data_source: entity.data_source,
      date: entity.date,
      validation_status: entity.validation_status,
      comment: entity.comment!,
      _embedded: {
        transfer_instruments: entity.transferInstruments.map((transfInstrument) =>
          this.transferInstrumentSerializer.toDto(transfInstrument)
        ),
        transfer_persons: entity.transferPersons.map((transfPerson) =>
          this.transferPersonSerializerService.toDto(transfPerson)
        ),
      },
    };
  }
}
