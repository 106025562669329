import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [MatIconModule, MatTooltipModule, TranslateModule],
  selector: 'parteng-clear-filters-button',
  template: `<div class="border border-blue-ptg-primary-300 rounded-[4px] h-12 p-1 flex items-center">
    <button
      mat-icon-button
      (click)="clearFilters.emit()"
      [attr.aria-label]="'shared.resetFiltersAriaLabel' | translate"
      [matTooltip]="'shared.clearFiltersAriaLabel' | translate"
      data-testId="parteng-clear-filters-button"
    >
      <mat-icon aria-hidden="false" class="text-blue-ptg-primary-500 picto">filter_alt_off</mat-icon>
    </button>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClearFiltersButtonComponent {
  @Output() clearFilters = new EventEmitter<void>();
}
