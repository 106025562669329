import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HealthcheckInfos } from '../models/healthcheck.model';

@Injectable({ providedIn: 'root' })
export class HealthcheckService {
  private healthCheckInfos: HealthcheckInfos | undefined;

  constructor(private http: HttpClient) {}

  loadHealthcheckInfos$({ errorTest }: { errorTest: boolean }): Observable<any> {
    const url = `${environment.api.baseURL}/healthcheck${errorTest === true ? '/error=true' : ''}`;
    const headers = new HttpHeaders({
      Accept: 'application/hal+json',
    });
    return this.http.get<HttpResponse<HealthcheckInfos>>(url, { headers, observe: 'response' }).pipe(
      map(
        (httpResponse) =>
          (this.healthCheckInfos = {
            ...httpResponse.body,
            apiVersion: httpResponse.headers.get('x-application-version') || 'NOT_FOUND',
            apiCommitHash: httpResponse.headers.get('x-application-commit-hash') || 'NOT_FOUND',
            envName: httpResponse.headers.get('x-application-env') || 'NOT_FOUND',
          } as any)
      ),
      catchError((httpResponse) => {
        this.healthCheckInfos = {
          database: {
            sql_mode: '',
            time_zone: '',
            character_set_results: '',
            character_set_connection: '',
            collation_connection: '',
            character_set_client: '',
          },
          filesystem: 0,
          redis: {
            version: '',
          },
          apiVersion: httpResponse.headers.get('x-application-version') || 'NOT_FOUND',
          apiCommitHash: httpResponse.headers.get('x-application-commit-hash') || 'NOT_FOUND',
          envName: httpResponse.headers.get('x-application-env') || 'NOT_FOUND',
        };
        return of();
      })
    );
  }

  getHealthcheckInfos(): HealthcheckInfos | undefined {
    return this.healthCheckInfos;
  }
}
