import { Injectable } from '@angular/core';
import { ConfigService } from '@app/common/services';
import { InstrumentType, PSEUDO_FOLDER } from '@app/data-entry/models';
import { PersonQuality } from '@app/data-entry/models/person-quality.model';
import { ProjectAndFolderLightService } from '@app/project/services/project-and-folder-light.service';
import { Observable, forkJoin, map, of } from 'rxjs';
import { OwnershipType, TransferSetupInputStep, TransferType } from '../models';
import { Transfer } from '../models/transfer.model';
import { TransferSetupInputStepService } from './transfer-setup-input-step.service';
import { TransferService } from './transfer.service';

@Injectable({ providedIn: 'root' })
export class TransferFormDataService {
  constructor(
    private config: ConfigService,
    private projectAndFolderLightService: ProjectAndFolderLightService,
    private transferService: TransferService,
    private setupTransferInputStepService: TransferSetupInputStepService
  ) {}

  getData$(opts: {
    projectId: number;
    folderId: number | PSEUDO_FOLDER.ALL_FOLDERS;
    transferId?: number;
    transferIdToCopy?: number;
  }): Observable<{
    transfer: Transfer;
    transferTypes: TransferType[];
    instrumentTypes: InstrumentType[];
    personQualities: PersonQuality[];
    ownershipTypes: OwnershipType[];
    setupTransferInputSteps: TransferSetupInputStep[];
  }> {
    const createDuplicate = !opts.transferId && !!opts.transferIdToCopy;
    const transferId = opts.transferId || opts.transferIdToCopy;
    return forkJoin({
      transfer: transferId
        ? this.transferService.getById$(transferId, { projectId: opts.projectId, createDuplicate })
        : this.projectAndFolderLightService
            .getProjectAndFolder$({
              ...opts,
              folderId: opts.folderId === PSEUDO_FOLDER.ALL_FOLDERS ? undefined : opts.folderId,
            })
            .pipe(
              map(
                ({ project, folder }) =>
                  new Transfer({ parentProject: project, parentFolder: folder!, folders_id: folder!.id })
              )
            ),
      transferTypes: of(this.config.getAllTransferTypes()),
      instrumentTypes: of(this.config.getAllInstrumentTypes()),
      personQualities: of(this.config.getAllPersonQualities()),
      ownershipTypes: of(this.config.getAllOwnershipTypes()),
      setupTransferInputSteps: this.setupTransferInputStepService.getAll$(),
    });
  }
}
