import { Injectable } from '@angular/core';
import { HALResource } from '@app/common/models';
import { PartengApiService, RestService, SerializerService } from '@app/common/services';
import { concatMap, from, map } from 'rxjs';
import { PersonGroupDto } from '../models/person-group.model';
import { Person, PersonDto } from '../models/person.model';

export type PersonGroupsStarSetsFullResponseBody = {
  _embedded: {
    rel_person_groups_to_persons: {
      group_persons_id: number;
      _embedded: {
        person: PersonDto;
      };
    }[];
  };
};

@Injectable({ providedIn: 'root' })
export class PersonGroupSerializerService extends SerializerService<Person, PersonGroupDto> {
  fromDto(json: HALResource<PersonGroupDto>): Person {
    const data = json._embedded.person as PersonDto;
    return new Person(data);
  }

  toDto(entity: Person): any {
    return {
      ...this.getDtoBaseProps(entity),
      status: 1,
    };
  }
}

@Injectable({ providedIn: 'root' })
export class PersonGroupService extends PartengApiService<Person, HALResource<PersonGroupDto>> {
  constructor(rest: RestService, serializer: PersonGroupSerializerService) {
    super(rest, serializer, '/person-groups', 'persons');
  }

  getAllGroupsWithTheirMembers$() {
    return this.request$<PersonGroupsStarSetsFullResponseBody>('get', `/person-groups/*/persons`, {
      queryParams: { sets: 'full' },
    }).pipe(
      map((data) => {
        let groups: Record<string, Person[]> = {};
        data._embedded.rel_person_groups_to_persons.forEach((item) => {
          let groupId = item.group_persons_id;
          let person = new Person(item._embedded.person);

          groupId in groups ? groups[groupId].push(person) : (groups[groupId] = [person]);
        });

        return Object.keys(groups).map(
          (groupId) => new Person({ id: parseInt(groupId, 10), personsInGroup: groups[groupId] })
        );
      })
    );
  }

  getPersonsByGroup$(personGroup: Person) {
    return this.getCollection$({
      halProperty: 'rel_person_groups_to_persons',
      prefix: `/${personGroup.id}/persons`,
      queryParams: { sets: 'full' },
    });
  }

  removePersonsFromPersonGroup$(personGroup: Person, personIdList: number[]) {
    return from((personIdList || []).map((personId) => this.removePersonFromPersonGroup$(personGroup, personId))).pipe(
      concatMap((request) => request)
    );
  }

  removePersonFromPersonGroup$(personGroup: Person, personId: number) {
    return this.deleteOne$({ prefix: `/${personGroup.id}/persons/${personId}` });
  }

  addPersonsInPersonGroup$(personGroup: Person, personIdList: number[]) {
    return from((personIdList || []).map((personId) => this.addPersonInPersonGroup$(personGroup, personId))).pipe(
      concatMap((request) => request)
    );
  }

  addPersonInPersonGroup$(personGroup: Person, personId: number) {
    return this.putOne$(personGroup, personId, { prefix: `/${personGroup.id}/persons` });
  }
}
