import { AsyncPipe, NgIf } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AbstractFormDialogComponent } from '@app/common/components';
import { CanEditFeatureDirective } from '@app/common/directives/can-edit-feature.directive';
import { Feature } from '@app/common/models/role.model';
import { TaskService } from '@app/common/services/task.service';
import { ServicesStore } from '@app/common/store/services.store';
import { Scope, World } from '@app/project/models';
import { ScopeService } from '@app/project/services/scope.service';
import { WorldService } from '@app/project/services/world.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, lastValueFrom } from 'rxjs';
import { DialogComponent } from '../dialog.component';
import { GenericDialogService } from '../generic-dialog/generic-dialog.service';
import { ScopeForm2Component } from '../scope-form2/scope-form2.component';

export type DialogScopeForm2Result = Scope;

export interface DialogScopeFormData {
  mode: 'create' | 'view' | 'edit';
  scope: Scope;
  showDeleteButton: boolean;
  fromReferentialScopes: boolean;
}

@Component({  standalone: true, imports:[DialogComponent, TranslateModule, ScopeForm2Component, AsyncPipe, MatDialogModule, NgIf, CanEditFeatureDirective, MatButtonModule],
  selector: 'parteng-dialog-scope-form-shared',
  template: `
    <section class="dialog-scope-form-shared">
      <parteng-dialog
        [title]="
          (isNew ? 'project.dialogScopeForm.titleNewScope' : 'project.dialogScopeForm.titleExistingScope') | translate
        "
        [description]="
          isNew && !dialogData.fromReferentialScopes ? ('project.dialogScopeForm.description' | translate) : ''
        "
        [isSubmitDisabled]="isFormInvalid"
        [customDialogActionsHTML]="customDialogActionsHTML"
        (safeClose)="safeCloseDialog()"
      >
        <parteng-scope-form2
          [scope]="dialogData.scope"
          [worlds]="(allWorlds$ | async)!"
          [mode]="mode"
          (modeChanged)="onModeChanged($event)"
          (formSubmitted)="onFormSubmitted($event)"
        ></parteng-scope-form2>
        <ng-template #customDialogActionsHTML>
          <mat-dialog-actions class="flex justify-end p-5">
            <button
              *ngIf="mode === 'view'"
              type="button"
              mat-flat-button
              (click)="closeDialog()"
              data-testId="scope-cancel-button"
            >
              {{ 'shared.buttonLabels.goBack' | translate }}
            </button>
            <button
              *ngIf="mode !== 'view'"
              type="button"
              mat-flat-button
              (click)="closeDialog()"
              data-testId="scope-cancel-button"
            >
              {{ 'shared.buttonLabels.cancel' | translate }}
            </button>
            <button
              *ngIf="dialogData.showDeleteButton && mode === 'view'"
              type="button"
              mat-stroked-button
              color="warn"
              (click)="onDeleteScope(dialogData.scope)"
              [can-edit-feature]="Feature.scopes"
              data-testId="scope-delete-button"
            >
              {{ 'project.dialogScopeForm.deleteBtn' | translate }}
            </button>
            <button
              *ngIf="mode !== 'view'"
              type="button"
              mat-raised-button
              color="primary"
              (click)="triggerFormSubmit()"
              [disabled]="isFormInvalid"
              data-testId="scope-update-button"
            >
              {{ (mode === 'edit' ? 'shared.buttonLabels.validate_edit' : 'shared.buttonLabels.validate') | translate }}
            </button>
          </mat-dialog-actions>
        </ng-template>
      </parteng-dialog>
    </section>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogScopeFormSharedComponent extends AbstractFormDialogComponent<Scope> implements OnInit {
  Feature = Feature;
  static readonly DIALOG_ID = 'DIALOG_SCOPE_FORM2';

  @ViewChild(ScopeForm2Component) formComponent!: ScopeForm2Component;

  formState$!: Observable<any>; // @TODO: Remove
  allWorlds$: Observable<World[]> = this.servicesStore.select<World[]>('allWorlds');
  mode!: 'create' | 'view' | 'edit';
  isNew!: boolean;

  constructor(
    dialogRef: MatDialogRef<DialogScopeFormSharedComponent, DialogScopeForm2Result>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogScopeFormData,
    private scopeService: ScopeService,
    private worldService: WorldService,
    private servicesStore: ServicesStore,
    private translateService: TranslateService,
    private taskService: TaskService,
    private genericDialogService: GenericDialogService
  ) {
    super(dialogRef);
    this.mode = dialogData.mode;
    this.isNew = dialogData.mode === 'create';
  }

  ngOnInit(): void {
    this.servicesStore.dispatch(this.worldService.getAll$(), 'allWorlds');
  }

  async onFormSubmitted(scope?: Scope | undefined) {
    if (!scope) {
      return this.closeDialog(); // this.cancel() ??
    }
    const savedScope = await this.taskService.doSave$(this.scopeService.saveScope$(scope), {
      [HttpStatusCode.Conflict]: this.translateService.instant('project.dialogScopeForm.codeAlreadyExists'),
    });
    this.submitAndCloseDialog(savedScope); // do not use "safe close"
  }

  async onDeleteScope(scope: Scope): Promise<void> {
    const confirm = await lastValueFrom(
      this.genericDialogService.warningDelete(
        this.translateService.instant('shared.dialogDeleteWarning.SCOPE', {
          scopeName: scope.name,
        })
      )
    );
    if (!confirm) {
      return;
    }
    await this.taskService.doDelete$(this.scopeService.deleteScope$(scope));
    this._forceDialogClose(); // do not use "safe close"
  }

  onModeChanged(mode: 'create' | 'view' | 'edit'): void {
    this.mode = mode;
  }
}
