export enum Feature {
  consultation = 'consultation',
  dataEntry = 'data-entry',
  instruments = 'instruments',
  persons = 'persons',
  projects = 'projects',
  scopes = 'scopes',
  validation = 'validation',
  verification = 'verification',
  apiRolesMatrix = 'api-roles-matrix',
  batchVerification = 'batch-verification',
  noConfirmationVerification = 'no-confirmation-verification',
}
export type Right = 'readWrite' | 'readonly' | 'show';
export type Role = string;
export type RoleMatrix = Record<Feature, Record<Right, Role[]>> | undefined;
