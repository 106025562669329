import { NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AbstractDialogComponent } from '@app/common/components';
import { EntityWithId, TableColumnDef } from '@app/common/models';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { DialogComponent } from '../dialog.component';
import { ItemSelectorTableComponent } from '../item-selector-table/item-selector-table.component';

// Components projecting their content into this component must implement this interface.
export interface DialogItemSelector<ENTITY extends EntityWithId> {
  columnDefs: TableColumnDef[];
  itemSelectorState$: Observable<DialogItemSelectorState<ENTITY>>;
  filterItemFn: (item: ENTITY, filter: string) => boolean;
}

// ATTENTION. NO OPTIONAL PROPS ALLOWED BELOW AS THIS INTERFACE REPRESENTS A PIECE OF STATE
export interface DialogItemSelectorState<ENTITY extends EntityWithId> {
  // dialog data
  allItems: ENTITY[];
  selectedItems: ENTITY[];
  newItem: ENTITY;
  // dialog customisations
  forEntityName: string;
  dialogTitleTranskateKey: string;
  hideAddItemButton: boolean;
}

@Component({  standalone: true, imports:[NgIf, DialogComponent, NgClass, MatFormFieldModule, TranslateModule, ItemSelectorTableComponent, MatIconModule, NgForOf, NgTemplateOutlet, MatInputModule],
  selector: 'parteng-dialog-item-selector',
  templateUrl: './dialog-item-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogItemSelectorComponent<ENTITY extends EntityWithId>
  extends AbstractDialogComponent<ENTITY[]>
  implements OnInit, OnChanges
{
  // Translated strings
  @Input() dialogTitle!: string;
  @Input() dialogDescription!: string;
  @Input() selectedItemsTitle!: string;
  @Input() selectedItemsDescription!: string;
  @Input() itemAdditionalInfoTitle!: string;

  // HTML Templates
  @Input() itemAdditionalInfoHTML!: TemplateRef<{ item: ENTITY }>;
  @Input() selectedItemPreviewHTML!: TemplateRef<{ item: ENTITY }>;

  // Data
  @Input() columnDefs!: TableColumnDef[];
  @Input() allItems: ENTITY[] = [];
  // NB. Items already selected the 1st time the dialog is opened.
  // Further changes are done LOCALLY on `selectedItems` and dispatched
  // back to the state only when the dialog is submitted.
  @Input() defaultSelectedItems: ENTITY[] = [];
  @Input() newItem!: ENTITY;
  @Input() filterItemFn!: (item: ENTITY, filter: string) => boolean;

  @Input() isMonoSelection = false; // If true, only one item can be selected at a time + the right column of "selected items" is not visible
  @Input() hideAddItemButton = false; // If true, the "Add Item" button will be hidden
  @Input() dialogColumnsSplitPoint: string = '4/6'; // The ratio of the dialog's width that will be used to split the columns.

  @ViewChild('selectedItemsDiv') selectedItemsDivRef!: ElementRef;
  @ViewChild('itemSelectorTable') itemSelectorTableComponent!: ItemSelectorTableComponent<ENTITY>;

  selectedItems: ENTITY[] = [];
  checkboxSelection: ENTITY[] = [];
  highlightedItem: ENTITY | undefined;
  filterText = '';

  // NB. This is the dialogRef of the projected component
  // we use it to keep the "close dialog" code in here
  constructor(dialogRef: MatDialogRef<DialogItemSelectorComponent<ENTITY>, ENTITY[]>) {
    super(dialogRef);
  }

  ngOnInit(): void {
    this.selectedItems = [...this.defaultSelectedItems];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['newItem'] && !changes['newItem'].firstChange) {
      this.resetFilter();
      this.selectItem(this.newItem);
    }
  }

  onRowClicked(items: ENTITY) {
    this.highlightedItem = items;

    // In mono-selection
    if (this.isMonoSelection === true) {
      this.selectItem(items);
    }
  }

  onCheckboxClicked(items: ENTITY[]) {
    this.checkboxSelection = items;
  }

  onSelectItem(): void {
    // In mono-selection
    if (this.highlightedItem !== undefined && this.isMonoSelection === true) {
      this.selectItem(this.highlightedItem);
      this.highlightedItem = undefined;
      return;
    }
    //In multi-selection
    this.checkboxSelection.forEach((item) => this.toggleSelectedItem(item));
    this.highlightedItem = undefined;
    this.scrollDownSelectedItemsList();
    this.checkboxSelection = [];
    this.itemSelectorTableComponent.clearCheckboxSelection();
    this.markAsDirty();
  }

  onUnselectAllCheckboxes() {
    this.itemSelectorTableComponent.clearCheckboxSelection();
    this.highlightedItem = undefined;
    this.checkboxSelection = [];
  }

  selectItem(item: ENTITY): void {
    this.markAsDirty();

    if (this.isMonoSelection === true) {
      this.selectedItems = [item];
      return;
    }
    // In multi-selection, selecting acts as a toggle
    this.toggleSelectedItem(item);
  }

  unselectItem(item: ENTITY): void {
    this.toggleSelectedItem(item);
    this.markAsDirty();
  }

  private toggleSelectedItem(item: ENTITY): void {
    const isSelected = this.selectedItems.some((i) => i.id === item.id);
    // If the item is already selected, remove it from selectedItems. Otherwise, add it.
    this.selectedItems = isSelected
      ? this.selectedItems.filter((i) => i.id !== item.id)
      : [...this.selectedItems, item];
  }

  submitSelectedItems(): void {
    this.submitAndCloseDialog(this.selectedItems);
  }

  // searching should reset the highlighted item
  onFilterTextChanged(event: Event): void {
    this.filterText = (event.target as HTMLInputElement).value;
    this.highlightedItem = undefined;
  }

  private resetFilter(): void {
    this.filterText = '';
    this.highlightedItem = undefined;
  }

  // scroll selected items list all the way down to make the last added item visible
  private scrollDownSelectedItemsList(): void {
    setTimeout(() => {
      const selectedItemsDiv: HTMLDivElement = this.selectedItemsDivRef?.nativeElement;
      if (selectedItemsDiv) {
        const scrollAmount = selectedItemsDiv.scrollHeight - selectedItemsDiv.clientHeight;
        selectedItemsDiv.scrollTop = scrollAmount;
      }
    });
  }
}
