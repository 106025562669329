import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DIALOG_WIDTH_MEDIUM } from '@app/common/common.constants';
import { firstValueFrom, Observable } from 'rxjs';
import { DialogConfirmComponent, DialogConfirmData } from '../dialog-confirm.component';
import { DialogErrorComponent } from '../dialog-error.component';
import { DialogLeaveWarningComponent } from '../dialog-leave-warning.component';
import { DialogWarningCustomTextComponent, DialogWarningCustomTextData } from '../dialog-warning-custom-text.component';

@Injectable({ providedIn: 'root' })
export class GenericDialogService {
  constructor(private dialog: MatDialog) {}

  confirm({
    title,
    description,
    labelConfirm,
    labelCancel,
  }: {
    title: string;
    description: string;
    labelConfirm: string;
    labelCancel: string;
  }): Observable<boolean | undefined> {
    return this.dialog
      .open<DialogConfirmComponent, DialogConfirmData, boolean>(DialogConfirmComponent, {
        data: {
          title,
          description,
          labelConfirm,
          labelCancel,
        },
      })
      .afterClosed();
  }

  leaveWarning$(dialogLeaveWarningTextTranslateKey?: string): Observable<boolean> {
    return this.dialog
      .open(DialogLeaveWarningComponent, {
        width: DIALOG_WIDTH_MEDIUM,
        data: { dialogLeaveWarningTextTranslateKey },
      })
      .afterClosed();
  }

  leaveWarning(dialogLeaveWarningTextTranslateKey?: string): Promise<boolean> {
    return firstValueFrom(this.leaveWarning$(dialogLeaveWarningTextTranslateKey));
  }

  warning(
    dialogTitleTranslateKey: string,
    dialogDescriptionTranslateKey: string,
    isCriticalChoice: boolean = true
  ): Observable<boolean | undefined> {
    return this.dialog
      .open<DialogWarningCustomTextComponent, DialogWarningCustomTextData, boolean>(DialogWarningCustomTextComponent, {
        data: {
          dialogTitleTranslateKey,
          dialogDescriptionTranslateKey,
          isCriticalChoice,
        },
      })
      .afterClosed();
  }

  criticalError(text: string) {
    return this.dialog
      .open(DialogErrorComponent, {
        panelClass: 'error-dialog-overlay',
        data: {
          text,
        },
      })
      .afterClosed();
  }

  warningDelete(dialogDescriptionTranslateKey: string): Observable<boolean | undefined> {
    return this.dialog
      .open<DialogWarningCustomTextComponent, DialogWarningCustomTextData, boolean>(DialogWarningCustomTextComponent, {
        data: {
          dialogTitleTranslateKey: 'shared.dialogDeleteWarning.title',
          dialogDescriptionTranslateKey,
          isCriticalChoice: true,
        },
      })
      .afterClosed();
  }
}
