/**
 * @file
 * Interface for component classes holding a form.
 *
 * This lets us handle form components more generically.
 */
import { DOCUMENT, NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AppInjector } from '@app/app-injector';
import { ErrorMessageBlockComponent } from '@app/common/components/error-block.component';
import { CanEditFeatureDirective } from '@app/common/directives/can-edit-feature.directive';
import { FillEmptyPipe } from '@app/common/pipes/fill-empty.pipe';
import { ScopeWorldsPipe } from '@app/common/pipes/scope-worlds.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({ template: '', standalone: true, imports:[NgIf, TranslateModule, FillEmptyPipe, ScopeWorldsPipe, MatIconModule, CanEditFeatureDirective, FormsModule, ReactiveFormsModule, MatFormFieldModule, NgClass, ErrorMessageBlockComponent, NgForOf, MatInputModule] })
export abstract class AbstractFormComponent<R = any> implements OnInit, OnDestroy {
  @Output() formSubmitted = new EventEmitter<R>();
  @Output() validity = new EventEmitter<boolean>();
  @Output() formChange = new EventEmitter<void>();

  form!: UntypedFormGroup;

  protected fb!: UntypedFormBuilder;
  private valueChangesSub!: Subscription;
  private document!: Document;

  constructor() {
    const injector = AppInjector.getInjector();
    this.fb = injector.get(UntypedFormBuilder);
    this.document = injector.get(DOCUMENT);
  }

  ngOnInit(): void {
    this.buildForm();
    this.valueChangesSub = this.form.valueChanges.subscribe(() => {
      this.validity.emit(this.form.valid);
      this.formChange.emit();
    });
  }

  ngOnDestroy(): void {
    this.valueChangesSub.unsubscribe();
  }

  abstract buildForm(): void;

  submit(): void {
    this.formSubmitted.emit(this.serializeForm());
  }

  // Convert the form data into a ready-to-consume format.
  abstract serializeForm(): R;

  // Scroll to show error displayed at the top of the form
  protected scrollToTop(): void {
    const formTop: HTMLElement = this.document.querySelector('#formTop')!;
    if (formTop) {
      formTop.scrollIntoView();
    }
  }
}
