import { EntityWithId, EntityWithIdDto } from '@app/common/models';
import { HALDeserializeFrom } from '@app/common/services/hal-serializer.service';
import { Goal } from './goal.model';
import { RelProjectTemplateToGoal } from './rel-project-template-to-goal';
import { RelProjectTemplateToStandardFolder } from './rel-project-template-to-standard-folder';
import { StandardFolder } from './standard-folder.model';

export class ProjectTemplate2 extends EntityWithId {
  @HALDeserializeFrom()
  override status: number = 0;

  @HALDeserializeFrom()
  override id: number = 0;

  @HALDeserializeFrom()
  name: string = '';

  @HALDeserializeFrom('project_name_template')
  projectNameTemplate: string = '';

  @HALDeserializeFrom()
  order: number = 0;

  @HALDeserializeFrom()
  comment: string = '';

  @HALDeserializeFrom('project_types_id')
  projectTypeId: number = 0;

  @HALDeserializeFrom('project_templates_to_standard_folders', RelProjectTemplateToStandardFolder)
  relProjectTemplateToStandardFolders: RelProjectTemplateToStandardFolder[] = [];

  @HALDeserializeFrom('project_templates_to_goals', RelProjectTemplateToGoal)
  relProjectTemplateToGoals: RelProjectTemplateToGoal[] = [];
}

export interface ProjectTemplateDto extends EntityWithIdDto {
  name: string;
  project_name_template: string; // pattern used to generate the project name
  order: number;
  comment: string;
}

export interface ProjectTemplateDtoAndRels extends ProjectTemplateDto {
  standardFolders: StandardFolder[];
  goals: Goal[];
}

export class ProjectTemplate extends EntityWithId {
  name: string; // required
  project_name_template: string; // required
  order: number; // required
  comment: string | undefined;

  standardFolders: StandardFolder[];
  goals: Goal[];

  constructor(opts: Partial<ProjectTemplateDtoAndRels> = {}) {
    super(opts);

    this.name = opts.name!;
    this.project_name_template = opts.project_name_template!;
    this.order = opts.order!;
    this.comment = opts.comment;

    this.standardFolders = opts.standardFolders || [];
    this.goals = opts.goals || [];
  }

  clone(opts: Partial<ProjectTemplateDtoAndRels> = {}): ProjectTemplate {
    return new ProjectTemplate({
      ...this.cloneEntityProps(opts),

      name: opts.name || this.name,
      project_name_template: opts.project_name_template || this.project_name_template,
      order: opts.order || this.order,
      comment: opts.comment || this.comment,

      standardFolders: opts.standardFolders || [...this.standardFolders],
      goals: opts.goals || [...this.goals],
    });
  }
}
