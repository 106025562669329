import { EntityWithId, EntityWithIdDto } from '@app/common/models';
import { HALDeserializeFrom } from '@app/common/services/hal-serializer.service';

export class World2 extends EntityWithId {
  @HALDeserializeFrom()
  override status: number = 0;

  @HALDeserializeFrom()
  override id: number = 0;

  @HALDeserializeFrom()
  order: number = 0;

  @HALDeserializeFrom()
  name: string = '';
}

export interface WorldDto extends EntityWithIdDto {
  name: string;
  order: number;
}

export class World extends EntityWithId {
  name: string; // required
  order: number; // required

  constructor(opts: Partial<WorldDto> = {}) {
    super(opts);
    this.name = opts.name!;
    this.order = opts.order!;
  }
}
