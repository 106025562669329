import { Clipboard } from '@angular/cdk/clipboard';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { EnvironmentInfo } from '@app/common/models';
import { EnvironmentName } from '@app/common/models/environment-info.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [NgIf, NgClass, MatIconModule, TranslateModule, MatTooltipModule, RouterLink],
  selector: 'parteng-header',
  template: `<header
    *ngIf="envInfo"
    class="parteng-header text-white flex flex-row items-center"
    [ngClass]="envInfo.env.headerColor"
    data-testid="header"
  >
    <div class="px-7">
      <a routerLink="/">
        <img width="109" src="./assets/images/parteng_logo.png" alt="Logo Parteng" data-testId="logo-parteng" />
      </a>
    </div>
    <div *ngIf="envInfo" class="border-l-2 border-white pl-6 text-white text-base">
      {{ envInfo.env.envName }}
    </div>
    <div class="relative">
      <mat-icon
        (click)="
          clipboard.copy(
            'Front : ' +
              envInfo.frontVersion +
              ' - ' +
              envInfo.frontCommitHash +
              ' / ' +
              'API : ' +
              envInfo.apiVersion +
              ' - ' +
              envInfo.apiCommitHash
          )
        "
        aria-hidden="false"
        [attr.aria-label]="'shared.header.infoIconAriaLabel' | translate"
        class="cursor-pointer ml-3 text-[#DADADA] leading-[1.1]"
        (mouseenter)="showTooltip()"
        (mouseleave)="hideTooltip()"
        >info_outline</mat-icon
      >
      <div
        class="absolute left-3 w-[300px] h-[80px] p-3 text-xs text-blue-ptg-primary bg-gray-ptg-1 rounded drop-shadow-md z-40"
        [class.invisible]="!isTooltipOpen"
      >
        <span class="font-semibold">{{ 'shared.header.versionAndCommitHashes' | translate }}</span
        ><br />
        Front : {{ envInfo.frontVersion }} - {{ envInfo.frontCommitHash }}<br />
        API : {{ envInfo.apiVersion }} - {{ envInfo.apiCommitHash }}<br />
      </div>
    </div>
    <div [matTooltip]="'shared.header.infoIconLogout' | translate" matTooltipPosition="left" class="ml-auto mr-3">
      <button (click)="logout()">
        <mat-icon
          aria-hidden="false"
          [attr.aria-label]="'shared.header.infoIconLogout' | translate"
          data-testId="parteng-logout-button"
          >exit_to_app</mat-icon
        >
      </button>
    </div>
  </header>`,
  styles: [
    `
      @use '/src/styles/variables' as v;
      .parteng-header {
        height: v.$parteng-site-header-height;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartengHeaderComponent {
  @Input() envInfo: EnvironmentInfo = {
    env: { envName: EnvironmentName.development, headerColor: 'orange-dev-ptg' },
    frontVersion: '0.1.0',
    frontCommitHash: 'dsfdy',
    apiVersion: '0.3.0',
    apiCommitHash: 'aaass',
  };

  isTooltipOpen = false;

  constructor(public clipboard: Clipboard, private authenticationService: AuthenticationService) {}

  showTooltip(): void {
    if (!this.isTooltipOpen) {
      this.isTooltipOpen = true;
    }
  }

  hideTooltip(): void {
    if (this.isTooltipOpen) {
      this.isTooltipOpen = false;
    }
  }

  async logout() {
    await this.authenticationService.logout();
  }
}
