import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class LogoutGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService) {}

  canActivate() {
    if (this.authenticationService.hasToken() === true) {
      this.authenticationService.login();
      return false;
    }
    return true;
  }
}
