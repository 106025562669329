import * as auth from './fr.auth.json';
import * as backoffice from './fr.backoffice.json';
import * as browser from './fr.browser.json';
import * as dataEntry from './fr.data-entry.json';
import * as instruments from './fr.instruments.json';
import * as persons from './fr.persons.json';
import * as project from './fr.project.json';
import * as scopes from './fr.scopes.json';
import * as shared from './fr.shared.json';
import * as transfers from './fr.transfers.json';
import * as validation from './fr.validation.json';
import * as verification from './fr.verification.json';

export const translations = {
  fr: {
    ...auth,
    ...backoffice,
    ...browser,
    ...dataEntry,
    ...instruments,
    ...persons,
    ...project,
    ...scopes,
    ...shared,
    ...transfers,
    ...validation,
    ...verification,
  },
};
