import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import * as coreActions from '@app/common/store/core.actions';
import * as coreSelectors from '@app/common/store/core.selectors';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { AuthenticationService } from './auth/services/authentication.service';
import { HelpContainerComponent } from './common/components/help-container.component';
import { PartengFooterComponent } from './common/components/parteng-footer.component';
import { PartengHeaderComponent } from './common/components/parteng-header.component';
import { ConfigService } from './common/services';
import { CompletedService } from './common/services/completed.service';
import { ErrorService } from './common/services/error.service';
import { PrefetchDataService } from './common/services/prefetch-data.service';
import { TaskService } from './common/services/task.service';
import { SettingsService } from './data-entry/services/settings.service';

@Component({
  standalone: true,
  imports: [PartengHeaderComponent, HelpContainerComponent, NgIf, RouterOutlet, AsyncPipe, PartengFooterComponent],
  selector: 'parteng-app',
  template: `
    <ng-container *ngIf="core$ | async as core">
      <ng-container *ngIf="core.dataReady">
        <parteng-header (click)="helpContainer.closeHelp()" [envInfo]="envInfo"></parteng-header>
        <parteng-help-container #helpContainer [show]="core.help"></parteng-help-container>
        <router-outlet></router-outlet>
        <footer parteng-footer></footer>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'parteng-bo-front';
  envInfo = this.config.getEnvironmentInfo();
  core$ = this.store.select(coreSelectors.selectCoreState);

  constructor(
    private taskService: TaskService,
    private errorService: ErrorService,
    private completedService: CompletedService,
    private prefetchDataService: PrefetchDataService,
    private settingsService: SettingsService,
    private config: ConfigService,
    private store: Store,
    private authenticationService: AuthenticationService
  ) {
    this.taskService.setErrorService(this.errorService);
    this.taskService.setCompletedService(this.completedService);
  }

  async ngOnInit() {
    this.authenticationService.setRefreshTokenCallback();
    const data = await firstValueFrom(this.prefetchDataService.fetch$());
    this.config.saveMultiInCache(data);
    await firstValueFrom(this.settingsService.load$());
    this.store.dispatch(coreActions.dataReady());
  }
}
