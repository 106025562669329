import { Pipe, PipeTransform } from '@angular/core';
import { Person } from '@app/project/models';

@Pipe({  standalone: true,
  name: 'personGroupChipSort',
})
export class PersonGroupChipSortPipe implements PipeTransform {
  transform(personsGroup: Person[]) {
    return personsGroup.sort((personA, personB) => personA.name.localeCompare(personB.name, 'fr'));
  }
}
