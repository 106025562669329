import { NgForOf, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConfigDataPipe } from '@app/common/pipes/config-data.pipe';
import { FillEmptyPipe } from '@app/common/pipes/fill-empty.pipe';
import { PersonsSortByNamePipe } from '@app/common/pipes/persons-sort-by-name.pipe';
import { SettingsService } from '@app/data-entry/services/settings.service';
import { Person } from '@app/project/models';
import { PersonService } from '@app/project/services/person.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({  standalone: true, imports:[NgIf, NgSwitch, NgSwitchCase, ConfigDataPipe, TranslateModule, FillEmptyPipe, NgForOf, PersonsSortByNamePipe, NgTemplateOutlet],
  selector: 'parteng-person-additional-info',
  template: `
    <div [ngSwitch]="person.person_type" class="text-sm text-neutral-700">
      <div *ngSwitchCase="PERSON_TYPE_NATURAL_PERSON" class="text-sm">
        <ng-container *ngIf="person.groups === undefined || person.groups.length === 0">-</ng-container>
      </div>

      <div *ngSwitchCase="PERSON_TYPE_LEGAL_PERSON">
        <ng-container *ngIf="showLegalPersonInformation === true">
          {{ person.legal_entity_country_code }}
          - {{ person.legal_entity_types_id! | configData: 'LegalEntityTypes':'short_name' }} -
          {{
            (person.legal_entity_pending_registration === LEGAL_ENTITY_REGISTRATION_IS_NOT_PENDING
              ? 'shared.dialogPersonSelector.legalEntityIdentifier'
              : 'shared.dialogPersonSelector.registrationPending'
            ) | translate: { legal_entity_identifier: person.legal_entity_identifier }
          }}
        </ng-container>
      </div>

      <div *ngSwitchCase="PERSON_TYPE_INVESTMENT_FUND">
        <ng-container *ngIf="showTypeOfFund === true"
          >{{ person.fund_types_id! | configData: 'FundTypes':'short_name' }} -
        </ng-container>
        {{
          'shared.dialogPersonSelector.fundManager'
            | translate: { id: person.fund_manager_id, name: person.$fundManagerName }
        }}
      </div>

      <div *ngSwitchCase="PERSON_TYPE_GROUP">
        {{ 'shared.dialogPersonSelector.personGroup' | translate }}
        <ng-container *ngTemplateOutlet="persons; context: { persons: person.personsInGroup }"> </ng-container>
      </div>
    </div>

    <div *ngIf="person.groups !== undefined && person.groups.length > 0">
      <div class="text-sm text-neutral-700">
        {{ 'shared.dialogPersonSelector.groups' | translate }}
        <ng-container *ngTemplateOutlet="persons; context: { persons: person.groups }"> </ng-container>
      </div>
    </div>

    <div class="text-xs text-neutral-400 mt-2">
      {{ 'shared.dialogPersonSelector.commentLabel' | translate }}
    </div>
    <div class="text-neutral-700">
      {{ person.comment | fillEmpty }}
    </div>

    <ng-template #empty>-</ng-template>

    <ng-template let-persons="persons" #persons>
      <ng-container *ngFor="let person of persons || [] | personsSortByName; last as isLast; trackBy: trackPersonById">
        {{ person.id }} - {{ person.name }} {{ isLast === true ? '' : '|' }}
      </ng-container>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonAdditionalInfoComponent {
  @Input() person!: Person;
  @Input() showLegalPersonInformation = true;
  @Input() showTypeOfFund = true;

  PERSON_TYPE_LEGAL_PERSON = this.personService.getPersonTypeId('PERSON_TYPE_LEGAL_PERSON')!;
  PERSON_TYPE_NATURAL_PERSON = this.personService.getPersonTypeId('PERSON_TYPE_NATURAL_PERSON')!;
  PERSON_TYPE_INVESTMENT_FUND = this.personService.getPersonTypeId('PERSON_TYPE_INVESTMENT_FUND')!;
  PERSON_TYPE_GROUP = this.personService.getPersonTypeId('PERSON_TYPE_GROUP')!;
  LEGAL_ENTITY_REGISTRATION_IS_NOT_PENDING = this.settingsService.get<number>(
    'LEGAL_ENTITY_REGISTRATION_IS_NOT_PENDING'
  );

  constructor(private settingsService: SettingsService, private personService: PersonService) {}

  trackPersonById(index: number, person: Person) {
    return person.id;
  }
}
