import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'parteng-oauth',
  template: ``,
})
export class OAuthComponent implements OnInit {
  constructor(
    private ngxSpinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    this.ngxSpinnerService.show();
    // TODO: manage error
    const response: any = await firstValueFrom(
      this.http.post(`${environment.api.baseURL}/authentication`, { code: this.route.snapshot.queryParams['code'] })
    );
    this.authenticationService.setToken(response.token);
    this.authenticationService.goToPostAuthenticationUrl();
  }
}
