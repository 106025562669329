import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '@app/common/angular-material.module';
import { ProjectValidationStatusComponent } from '@app/common/components/project-validation-status.component';
import { FillEmptyPipe } from '@app/common/pipes/fill-empty.pipe';
import { ProjectsTableItem } from '@app/common/pipes/projects-table-items.pipe';
import { ProjectsTableSortPipe } from '@app/common/pipes/projects-table-sort.pipe';
import { TableDataSourcePipe } from '@app/common/pipes/table-datasource.pipe';
import { Project } from '@app/project/models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    MatPaginatorModule,
    AngularMaterialModule,
    TranslateModule,
    TableDataSourcePipe,
    ProjectsTableSortPipe,
    FillEmptyPipe,
    ProjectValidationStatusComponent,
  ],
  standalone: true,
  selector: 'parteng-projects-list-table',
  template: `
    <table
      mat-table
      [dataSource]="projects | tableDataSource | projectsTableSort: sort:paginator"
      matSort
      matSortDisableClear
      matSortActive="dateMin"
      matSortDirection="desc"
      [trackBy]="trackProjectById"
      [fixedLayout]="true"
      class="w-full"
    >
      <!-- COLUMN Templates (TH/TD) -->
      <!-- ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-blue-ptg-secondary-500 w-[75px]">
          {{ 'project.pageProjectsList.projectsTable.columnId' | translate }}
        </th>
        <td mat-cell *matCellDef="let project">
          {{ project.id }}
        </td>
      </ng-container>
      <!-- Name -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-blue-ptg-secondary-500">
          {{ 'project.pageProjectsList.projectsTable.columnName' | translate }}
        </th>
        <td mat-cell *matCellDef="let project">
          {{ project.name }}
        </td>
      </ng-container>
      <!-- Scopes -->
      <ng-container matColumnDef="scopeCodesStr">
        <th mat-header-cell *matHeaderCellDef class="text-blue-ptg-secondary-500">
          {{ 'project.pageProjectsList.projectsTable.columnScopes' | translate }}
        </th>
        <td mat-cell *matCellDef="let project">
          {{ project.scopeCodesStr }}
        </td>
      </ng-container>
      <!-- Closing Date -->
      <ng-container matColumnDef="closing_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="firstClosingDate" class="text-blue-ptg-secondary-500">
          {{ 'project.pageProjectForm.projectIdentificationBlock.date' | translate }}
        </th>
        <td mat-cell *matCellDef="let project">
          {{ project.firstClosingDate | date: 'shortDate' | fillEmpty }}
        </td>
      </ng-container>
      <!-- Start Date -->
      <ng-container matColumnDef="dateMin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-blue-ptg-secondary-500">
          {{ 'project.pageProjectsList.projectsTable.columnDateMin' | translate }}
        </th>
        <td mat-cell *matCellDef="let project">
          {{ project.dateMin | date: 'shortDate' | fillEmpty }}
        </td>
      </ng-container>
      <!-- End Date -->
      <ng-container matColumnDef="dateMax">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-blue-ptg-secondary-500">
          {{ 'project.pageProjectsList.projectsTable.columnDateMax' | translate }}
        </th>
        <td mat-cell *matCellDef="let project">
          {{ project.dateMax | date: 'shortDate' | fillEmpty }}
        </td>
      </ng-container>
      <!-- Validation Status -->
      <ng-container matColumnDef="validationStatus">
        <th mat-header-cell *matHeaderCellDef class="text-blue-ptg-secondary-500 w-[50px]">
          {{ 'project.pageProjectsList.projectsTable.columnStatus' | translate }}
        </th>
        <td mat-cell *matCellDef="let project">
          <parteng-project-validation-status
            [projectValidationStatusId]="project.validationStatus"
          ></parteng-project-validation-status>
        </td>
      </ng-container>
      <!-- ROW Templates (TR) -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="h-9"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="clickRow(row)"
        class="cursor-pointer"
        [class.bg-neutral-100]="isHighlighted(row)"
      ></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[10]"
      showFirstLastButtons
      [attr.aria-label]="'instruments.instrumentsPage.paginator.ariaLabel' | translate"
    >
    </mat-paginator>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsListTableComponent {
  @Input() projects: ProjectsTableItem[] = [];
  @Input() highlightedProject: Project | undefined;

  @Output() rowClicked = new EventEmitter<ProjectsTableItem>();

  displayedColumns = ['id', 'name', 'scopeCodesStr', 'closing_date', 'dateMin', 'dateMax', 'validationStatus'];

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  trackProjectById(_index: number, item: ProjectsTableItem): number {
    return item.id;
  }

  clickRow(projectItem: ProjectsTableItem): void {
    this.rowClicked.emit(projectItem);
  }

  isHighlighted(projectItem: ProjectsTableItem): boolean {
    return this.highlightedProject?.id === projectItem.id;
  }
}
