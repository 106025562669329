/**
 * @file
 * Generic dialog for all warnings.
 * Dialog returns `true` to proceed with the close, `false` to cancel the close.
 */
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({  standalone: true, imports:[MatDialogModule, MatIconModule, TranslateModule, MatButtonModule, NgIf, NgTemplateOutlet],
  selector: 'parteng-dialog-warning',
  template: `
    <h2 mat-dialog-title align="end" class="px-5">
      <mat-icon
        aria-hidden="false"
        [attr.aria-label]="'shared.dialog.closeAriaLabel' | translate"
        class="text-neutral-500 cursor-pointer picto"
        [mat-dialog-close]="false"
        >close</mat-icon
      >
    </h2>

    <div class="mx-auto py-16">
      <h3 class="text-center text-2xl m-0 font-semibold text-red-ptg-alert">
        {{ title }}
      </h3>
      <div mat-dialog-content class="flex flex-col items-center">
        <div class="border-t border-neutral-500 w-[280px] pb-4"></div>
        <p class="text-base text-center" [innerHTML]="text"></p>
      </div>
      <mat-dialog-actions *ngIf="!customDialogActionsHTML" class="flex align-items justify-around">
        <button
          type="button"
          mat-raised-button
          [mat-dialog-close]="true"
          [color]="isCriticalChoice ? 'warn' : 'primary'"
          data-testId="dialog-warning-confirm-button"
        >
          {{ 'shared.buttonLabels.confirm' | translate }}
        </button>
        <button type="button" mat-raised-button [mat-dialog-close]="false" data-testId="dialog-warning-cancel-button">
          {{ 'shared.buttonLabels.cancel' | translate }}
        </button>
      </mat-dialog-actions>
      <ng-container *ngIf="customDialogActionsHTML">
        <ng-container *ngTemplateOutlet="customDialogActionsHTML"></ng-container>
      </ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogWarningComponent {
  @Input() title!: string;
  @Input() text!: string;
  @Input() isCriticalChoice = false;
  @Input() customDialogActionsHTML!: TemplateRef<any>;
}
