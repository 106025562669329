import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HALResource } from '@app/common/models';
import { PartengApiService, RestService, SerializerService } from '@app/common/services';
import { World, WorldDto } from '../models/world.model';

@Injectable({ providedIn: 'root' })
export class WorldSerializerService extends SerializerService<World, WorldDto> {
  fromDto(json: WorldDto | HALResource<WorldDto>): World {
    return new World(json);
  }

  toDto(entity: World): any {
    return {
      ...this.getDtoBaseProps(entity),
      name: entity.name,
      order: entity.order,
    };
  }
}

@Injectable({ providedIn: 'root' })
export class WorldService extends PartengApiService<World, HALResource<WorldDto>> {
  constructor(rest: RestService, serializer: WorldSerializerService) {
    super(rest, serializer, '/worlds', 'worlds');
  }

  getAll$(): Observable<World[]> {
    return this.getCollection$().pipe(map((worlds) => _.sortBy(worlds, 'order')));
  }
}
