import { EntityWithId, EntityWithIdDto } from '@app/common/models';
import { HALDeserializeFrom, HALSerializeTo } from '@app/common/services/hal-serializer.service';

export class Goal2 extends EntityWithId {
  @HALDeserializeFrom()
  @HALSerializeTo()
  override status: number = 0;

  @HALDeserializeFrom()
  override id: number = 0;

  @HALDeserializeFrom()
  @HALSerializeTo()
  name: string = '';

  @HALDeserializeFrom()
  @HALSerializeTo()
  order: number = 0;

  @HALDeserializeFrom()
  @HALSerializeTo()
  comment: string = '';
}

export interface GoalDto extends EntityWithIdDto {
  name: string;
  order: number;
  comment: string;
}

export class Goal extends EntityWithId {
  name: string; // required
  order: number; // required
  comment: string | undefined;

  constructor(opts: Partial<GoalDto> = {}) {
    super(opts);
    this.name = opts.name!;
    this.order = opts.order!;
    this.comment = opts.comment;
  }
}
