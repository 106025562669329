import { EntityWithId, EntityWithIdDto } from '@app/common/models';
import {
  HALDeserializeFrom,
  HALDeserializeValueToNewValue,
  HALSerializeTo,
} from '@app/common/services/hal-serializer.service';

export interface PersonQualityDto extends EntityWithIdDto {
  name: string;
  order: number;
  person_group_accepted: number;
}

export class PersonQuality extends EntityWithId {
  @HALDeserializeFrom()
  override id: number = 0;

  @HALDeserializeFrom()
  @HALSerializeTo()
  name: string = '';

  @HALDeserializeFrom()
  @HALSerializeTo()
  order: number = 0;

  @HALDeserializeFrom('person_group_accepted')
  @HALDeserializeValueToNewValue({ 0: false, 1: true })
  @HALSerializeTo('person_group_accepted')
  personGroupAccepted: boolean = false;
}
