import { Pipe, PipeTransform } from '@angular/core';
import { JsHelper } from '@app/common/helpers';
import { SettingsService } from '@app/data-entry/services/settings.service';
import {
    ProjectValidationStatus,
    ProjectValidationStatusId,
} from '@app/project/models/project-validation-status.model';
import { ProjectsTableItem } from './projects-table-items.pipe';

@Pipe({  standalone: true,
  name: 'projectsFilters',
})
export class ProjectsFiltersPipe implements PipeTransform {
  constructor(private settingsService: SettingsService) {}

  transform(
    allProjectItems: ProjectsTableItem[],
    textFilter: string | undefined | null,
    selectedStatusFilters: ProjectValidationStatus[]
  ): ProjectsTableItem[] {
    return allProjectItems
      .filter((projectItem) => {
        return (
          !textFilter ||
          textFilter === '' ||
          JsHelper.ObjPropsContainString(projectItem, textFilter, [
            'id',
            'name',
            'scopeCodesStr',
            'scopesStr',
            'goalsStr',
            'comment',
            // 'projectTypeName', // Remove this comment when projectType will be activated again.
          ])
        );
      })
      .filter(
        (project) =>
          selectedStatusFilters.includes('PROJECT_VALIDATION_STATUS_ALL') ||
          (selectedStatusFilters || [])
            .map((f) => this.settingsService.get<ProjectValidationStatusId>(f))
            .includes(project.validationStatus)
      );
  }
}
