import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT_LOCATION } from '@app/common/injection-tokens/document-location.injection-token';
import jwt_decode from 'jwt-decode';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';

const AUTHENTICATION_TOKEN_LOCAL_STORAGE_KEY = 'authenticationToken';
const POST_AUTHENTICATION_URL_LOCAL_STORAGE_KEY = 'postAuthenticationRedirectionUrl';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(
    @Inject(DOCUMENT_LOCATION) private location: Location,
    @Inject(APP_BASE_HREF) private baseHref: string,
    private router: Router,
    private http: HttpClient
  ) {
    // Because of environments, baseHref might be undefined, an empty string, a single slash, etc.
    this.baseHref = this.baseHref === undefined ? '' : this.baseHref;
  }

  setToken(token: string) {
    localStorage.setItem(AUTHENTICATION_TOKEN_LOCAL_STORAGE_KEY, token);
  }

  /**
   * So, how does it work?
   * We set a timer to refresh the token when it is about to expire (minus 1 minute)
   * This timer is set when the app starts and when the token is refreshed.
   * It means the user will never be disconnected.
   * If the token is invalid, it will be caught by rest.service.ts and forceLogin() will be called.
   * This will display a dialog and reload the app.
   */
  setRefreshTokenCallback() {
    const token = this.getToken();
    if (!token) {
      return;
    }
    const decodedToken = jwt_decode<{ exp: number }>(token);

    let delayBeforeTokenRefresh = Math.floor((decodedToken.exp * 1000 - Date.now()) * 0.9);

    if (delayBeforeTokenRefresh < 0) {
      delayBeforeTokenRefresh = 0;
    }

    setInterval(() => {
      lastValueFrom(
        this.http.put<{ token: string }>(
          `${environment.api.baseURL}/authentication`,
          {},
          {
            headers: new HttpHeaders({
              Accept: 'application/hal+json',
              Authorization: `Bearer ${this.getToken()}`,
              'x-authorization': `Bearer ${this.getToken()}`,
            }),
          }
        )
      ).then(({ token }) => {
        this.setToken(token);
      });
    }, delayBeforeTokenRefresh);
  }

  getToken(): string | null {
    return localStorage.getItem(AUTHENTICATION_TOKEN_LOCAL_STORAGE_KEY);
  }

  hasToken() {
    return (
      localStorage.getItem(AUTHENTICATION_TOKEN_LOCAL_STORAGE_KEY) !== null &&
      localStorage.getItem(AUTHENTICATION_TOKEN_LOCAL_STORAGE_KEY) !== ''
    );
  }

  deleteToken() {
    localStorage.setItem(AUTHENTICATION_TOKEN_LOCAL_STORAGE_KEY, '');
  }

  savePostAuthenticationUrl() {
    localStorage.setItem(POST_AUTHENTICATION_URL_LOCAL_STORAGE_KEY, this.location.href || '');
  }

  setPostAuthenticationUrl(url: string) {
    localStorage.setItem(POST_AUTHENTICATION_URL_LOCAL_STORAGE_KEY, url);
  }

  getPostAuthenticationUrl() {
    return localStorage.getItem(POST_AUTHENTICATION_URL_LOCAL_STORAGE_KEY);
  }

  clearPostAuthenticationUrl() {
    return localStorage.getItem(POST_AUTHENTICATION_URL_LOCAL_STORAGE_KEY);
  }

  /**
   * forceLogin() should not happen.
   * But, if it does, we want to notify the user the app will be reloaded,
   * instead of just reloading the app making the user wondering what happened.
   */
  forceLogin() {
    this.savePostAuthenticationUrl();
    this.location.assign(`${this.baseHref}${this.baseHref[this.baseHref.length - 1] !== '/' ? '/' : ''}login`);
  }

  goToPostAuthenticationUrl() {
    const postAuthenticationUrl = this.getPostAuthenticationUrl();
    this.clearPostAuthenticationUrl();
    this.location.assign(postAuthenticationUrl || this.baseHref || '/');
  }

  async logout() {
    await lastValueFrom(
      this.http.delete(`${environment.api.baseURL}/authentication`, {
        headers: new HttpHeaders({
          Accept: 'application/hal+json',
          Authorization: `Bearer ${this.getToken()}`,
          'x-authorization': `Bearer ${this.getToken()}`,
        }),
      })
    );
    this.deleteToken();
    this.router.navigate(['logout']);
  }

  login() {
    const host = this.location.host;
    const protocol = this.location.protocol;
    const url = `${protocol}//${host}${this.baseHref}/backoffice`;

    this.setPostAuthenticationUrl(url);
    this.router.navigate(['login']);
  }
}
