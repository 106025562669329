// We are trying to make Ngrx as generic as possible to avoid code duplication.
// Unfortunately, it means we are playing on the edge of the linter rules.
// So, to prevent any warning we disable the linter.
/* eslint-disable */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppStoreModel } from '../models/app-store.model';
import { serviceRequestAction } from './services.actions';

@Injectable({ providedIn: 'root' })
export class ServicesStore {
  constructor(private store: Store<AppStoreModel>) {}

  dispatch<T = unknown>(
    operation$: Observable<T>,
    stateSliceKey: string,
    successMessage?: string,
    failMessage?: string
  ): Observable<T> {
    this.store.dispatch(serviceRequestAction({ operation$, stateSliceKey, successMessage, failMessage }));
    return this.select<T>(stateSliceKey);
  }

  select<T>(stateSliceKey: string): Observable<T> {
    return (
      this.store
        // We fairly assume the initial state (AppStoreModel)
        // will provide services.data
        .select((state) => state.services.data[stateSliceKey])
        .pipe(
          // We filter here for two reasons :
          //  - the initial state will trigger the selector. And in this initial
          // state the slice does not exist. So we don't want to trigger anything.
          // - because the on(serviceRequestAction) reducers will create the
          // stateslice with loading = true and response = undefined.
          // This what we want for loading$ (see below), but we don't want to
          // trigger an undefined response.
          // Note: in case of 204 No Content, the response is null (so,
          // everything is fine).
          filter((stateSliceValue) => !!stateSliceValue && stateSliceValue.response !== undefined),
          map((stateSliceValue) => stateSliceValue.response as T)
        )
    );
  }

  loading$(stateSliceKey?: string): Observable<boolean> {
    if (stateSliceKey) {
      return this.store
        .select((state) => state.services.data[stateSliceKey])
        .pipe(
          filter((stateSliceValue) => !!stateSliceValue),
          map((stateSliceValue) => stateSliceValue.loading)
        );
    }
    return this.store
      .select((state) => state.services.data)
      .pipe(
        map((data) => {
          return !!Object.keys(data)
            .map((key) => data[key].loading)
            .find((loading) => loading);
        })
      );
  }

  // TODO: manage errors properly
  errors$(): Observable<unknown[]> {
    return this.store
      .select((state) => state.services.data)
      .pipe(
        map((data): unknown[] => {
          return (
            // TODO: use Object.values
            Object.keys(data)
              .filter((key) => !!data[key].error)
              .map((key) => data[key].error)
          );
        }),
        filter((errors) => errors.length > 0)
      );
  }
}
