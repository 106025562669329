import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DIALOG_WIDTH_LARGE } from '@app/common/common.constants';
import { DataEntryNavigationService } from '@app/data-entry/services/data-entry-navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { delay, firstValueFrom, from, Observable, switchMap } from 'rxjs';
import {
  DialogProjectSelectorComponent,
  DialogProjectSelectorComponentResult,
  DialogProjectSelectorParams,
} from '../dialogs/dialog-project-selector/dialog-project-selector.component';
import { Project } from '../models';

@Injectable({ providedIn: 'root' })
export class ProjectSelectorService {
  constructor(
    private translateService: TranslateService,
    private dialog: MatDialog,
    private router: Router,
    private dataEntryNavigationService: DataEntryNavigationService,
    private ngxSpinnerService: NgxSpinnerService
  ) {}

  openProjectSelectorForConsultation(): Observable<DialogProjectSelectorComponentResult | undefined> {
    return from(this.ngxSpinnerService.show()).pipe(
      delay(50),
      switchMap(() =>
        this.dialog
          .open<DialogProjectSelectorComponent, DialogProjectSelectorParams, DialogProjectSelectorComponentResult>(
            DialogProjectSelectorComponent,
            {
              width: DIALOG_WIDTH_LARGE,
              height: '90%',
              data: {
                title: this.translateService.instant('project.consultation.title'),
                description: this.translateService.instant('project.consultation.description'),
                directReturn: true,
                statusFiltersMode: 'combined',
                statusFiltersGroups: [
                  {
                    name: this.translateService.instant('project.validationStatusBlock.input'),
                    status: ['PROJECT_VALIDATION_STATUS_INPUT', 'PROJECT_VALIDATION_STATUS_VERIFICATION_REJECTED'],
                  },
                  {
                    name: this.translateService.instant('project.validationStatusBlock.verification'),
                    status: ['PROJECT_VALIDATION_STATUS_VERIFICATION', 'PROJECT_VALIDATION_STATUS_VALIDATION_REJECTED'],
                  },
                  {
                    name: this.translateService.instant('project.validationStatusBlock.validation'),
                    status: ['PROJECT_VALIDATION_STATUS_VALIDATION'],
                  },
                  {
                    name: this.translateService.instant('project.validationStatusBlock.validated'),
                    status: ['PROJECT_VALIDATION_STATUS_VALIDATED', 'PROJECT_VALIDATION_STATUS_CLOSED'],
                  },
                ],
                initialStatusFilters: [
                  'PROJECT_VALIDATION_STATUS_INPUT',
                  'PROJECT_VALIDATION_STATUS_VERIFICATION_REJECTED',
                  'PROJECT_VALIDATION_STATUS_VALIDATION_REJECTED',
                  'PROJECT_VALIDATION_STATUS_VERIFICATION',
                  'PROJECT_VALIDATION_STATUS_VALIDATION',
                ],
                submitButtonText: 'shared.see',
              },
            }
          )
          .afterClosed()
      )
    );
  }

  openProjectSelectorForDataEntry(): Observable<DialogProjectSelectorComponentResult | undefined> {
    return from(this.ngxSpinnerService.show()).pipe(
      delay(50),
      switchMap(() =>
        this.dialog
          .open<DialogProjectSelectorComponent, DialogProjectSelectorParams, DialogProjectSelectorComponentResult>(
            DialogProjectSelectorComponent,
            {
              width: DIALOG_WIDTH_LARGE,
              height: '90%',
              data: {
                directReturn: true,
                title: this.translateService.instant('project.dialogProjectSelector.title'),
                description: this.translateService.instant('project.dialogProjectSelector.description'),
                statusFiltersMode: 'exclusive',
                statusFilters: [
                  'PROJECT_VALIDATION_STATUS_ALL',
                  'PROJECT_VALIDATION_STATUS_INPUT',
                  'PROJECT_VALIDATION_STATUS_VERIFICATION_REJECTED',
                ],
                initialStatusFilters: ['PROJECT_VALIDATION_STATUS_ALL'],
              },
            }
          )
          .afterClosed()
      )
    );
  }

  openProjectSelectorForVerification(): Observable<DialogProjectSelectorComponentResult | undefined> {
    return this.dialog
      .open<DialogProjectSelectorComponent, DialogProjectSelectorParams, DialogProjectSelectorComponentResult>(
        DialogProjectSelectorComponent,
        {
          width: DIALOG_WIDTH_LARGE,
          height: '90%',
          data: {
            directReturn: true,
            title: this.translateService.instant('verification.dialogProjectSelector.title'),
            description: this.translateService.instant('verification.dialogProjectSelector.description'),
            statusFiltersMode: 'exclusive',
            statusFilters: [
              'PROJECT_VALIDATION_STATUS_ALL',
              'PROJECT_VALIDATION_STATUS_VERIFICATION',
              'PROJECT_VALIDATION_STATUS_VALIDATION_REJECTED',
            ],
            initialStatusFilters: ['PROJECT_VALIDATION_STATUS_ALL'],
          },
        }
      )
      .afterClosed();
  }

  openProjectSelectorForValidation(): Observable<DialogProjectSelectorComponentResult | undefined> {
    return this.dialog
      .open<DialogProjectSelectorComponent, DialogProjectSelectorParams, DialogProjectSelectorComponentResult>(
        DialogProjectSelectorComponent,
        {
          width: DIALOG_WIDTH_LARGE,
          height: '90%',
          data: {
            directReturn: true,
            title: this.translateService.instant('validation.dialogProjectSelector.title'),
            description: this.translateService.instant('validation.dialogProjectSelector.description'),
            statusFiltersMode: 'exclusive',
            statusFilters: ['PROJECT_VALIDATION_STATUS_VALIDATION'],
            initialStatusFilters: ['PROJECT_VALIDATION_STATUS_VALIDATION'],
          },
        }
      )
      .afterClosed();
  }

  openProjectSelectorForTransferMove(
    projects: Project[],
    transferLabel: string
  ): Observable<DialogProjectSelectorComponentResult | undefined> {
    return this.dialog
      .open<DialogProjectSelectorComponent, DialogProjectSelectorParams, DialogProjectSelectorComponentResult>(
        DialogProjectSelectorComponent,
        {
          width: DIALOG_WIDTH_LARGE,
          height: '90%',
          data: {
            directReturn: true,
            hideStatusFilter: true,
            projects,
            title: this.translateService.instant('transfers.moveTransfer.projectSelector.title', {
              transferLabel,
            }),
            description: this.translateService.instant('transfers.moveTransfer.projectSelector.description'),
            initialStatusFilters: ['PROJECT_VALIDATION_STATUS_ALL'],
          },
        }
      )
      .afterClosed();
  }

  openProjectSelectorForPersonMove(personName: string): Observable<DialogProjectSelectorComponentResult | undefined> {
    return this.dialog
      .open<DialogProjectSelectorComponent, DialogProjectSelectorParams, DialogProjectSelectorComponentResult>(
        DialogProjectSelectorComponent,
        {
          width: DIALOG_WIDTH_LARGE,
          height: '90%',
          data: {
            directReturn: true,
            hideStatusFilter: true,
            title: this.translateService.instant('persons.movePerson.projectSelector.title', {
              personName,
            }),
            description: this.translateService.instant('persons.movePerson.projectSelector.description'),
            initialStatusFilters: [
              'PROJECT_VALIDATION_STATUS_CREATED',
              'PROJECT_VALIDATION_STATUS_INPUT',
              'PROJECT_VALIDATION_STATUS_VERIFICATION_REJECTED',
              'PROJECT_VALIDATION_STATUS_VERIFICATION',
              'PROJECT_VALIDATION_STATUS_VALIDATION_REJECTED',
            ],
          },
        }
      )
      .afterClosed();
  }

  navigateToProjectForDataEntry(projectId: number) {
    this.dataEntryNavigationService.goToDataEntryDashboard(projectId);
  }

  navigateToProjectForVerification(projectId: number) {
    this.router.navigate(['/verification/projects', projectId]);
  }

  navigateToProjectForConsultation(projectId: number) {
    this.dataEntryNavigationService.goToDataEntryDashboard(projectId, { consultation: true });
  }

  navigateToProjectForValidation(projectId: number) {
    this.router.navigate(['/validation/projects', projectId]);
  }

  async selectProject(forConsultation: boolean) {
    const project = await firstValueFrom(
      forConsultation ? this.openProjectSelectorForConsultation() : this.openProjectSelectorForDataEntry()
    );
    if (project) {
      forConsultation
        ? this.navigateToProjectForConsultation(project.id)
        : this.navigateToProjectForDataEntry(project.id);
    }
  }
}
