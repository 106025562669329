import { enableProdMode, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AuthModule } from '@app/auth/auth.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AuthModule, {
    providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }],
  })
  .catch((err) => console.error(err));
