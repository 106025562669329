import { Pipe, PipeTransform } from '@angular/core';
import { Scope } from '@app/project/models';

@Pipe({  standalone: true,
  name: 'scopeWorlds',
})
export class ScopeWorldsPipe implements PipeTransform {
  transform(scope: Scope): unknown | null {
    return scope?.worlds?.map((w) => w.name)?.join(', ') || '';
  }
}
