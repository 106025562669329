import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { HttpHeaders, QueryParams } from '@app/common/models/rest.models';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Constructor, HALCollection, HALResource, HALSerializerService } from './hal-serializer.service';
import { RestService } from './rest.service';

@Injectable({ providedIn: 'root' })
export class HalApiService {
  constructor(
    private serializerService: HALSerializerService,
    private restService: RestService,
    private authenticationService: AuthenticationService
  ) {}

  getCollection$<T extends object>(
    ctor: Constructor<T>,
    endpoint: string,
    queryParams: QueryParams,
    embeddedKey: string
  ): Observable<T[]> {
    return this.restService
      .get<HALCollection>({
        method: 'get',
        url: `${environment.api.baseURL}${endpoint}`,
        queryParams,
        headers: this.headers(),
      })
      .pipe(
        map((response: HALCollection) => {
          return response &&
            response._embedded &&
            response._embedded[embeddedKey] &&
            Array.isArray(response._embedded[embeddedKey])
            ? response._embedded[embeddedKey]
            : [];
        }),
        map((collection: HALResource[]) =>
          collection.map((entity) => this.serializerService.deserialize<T>(entity, ctor))
        )
      );
  }

  getOne$<T extends object>(ctor: Constructor<T>, endpoint: string, queryParams: QueryParams): Observable<T> {
    return this.restService
      .get<HALResource>({
        method: 'get',
        url: `${environment.api.baseURL}${endpoint}`,
        queryParams,
        headers: this.headers(),
      })
      .pipe(map((entity: HALResource) => this.serializerService.deserialize<T>(entity, ctor)));
  }

  postOne$<T extends object>(endpoint: string, queryParams: QueryParams, body?: T): Observable<any> {
    return this.restService.post({
      method: 'post',
      headers: this.headers(),
      url: `${environment.api.baseURL}${endpoint}`,
      body: body !== undefined ? this.serializerService.serialize(body) : body,
      queryParams,
    });
  }

  putOne$<T extends object>(endpoint: string, queryParams: QueryParams, body?: T): Observable<any> {
    return this.restService.put({
      method: 'put',
      headers: this.headers(),
      url: `${environment.api.baseURL}${endpoint}`,
      body: body !== undefined ? this.serializerService.serialize(body) : body,
      queryParams,
    });
  }

  patch$<T>(endpoint: string, queryParams: QueryParams, body?: unknown): Observable<any> {
    return this.restService.patch({
      method: 'patch',
      headers: this.headers(),
      url: `${environment.api.baseURL}${endpoint}`,
      body: body,
      queryParams,
    });
  }

  deleteOne$<T>(endpoint: string, queryParams: QueryParams = {}): Observable<any> {
    return this.restService.delete({
      method: 'delete',
      headers: this.headers(),
      url: `${environment.api.baseURL}${endpoint}`,
      queryParams,
    });
  }

  headers(): HttpHeaders {
    return {
      Accept: 'application/hal+json',
      Authorization: `Bearer ${this.authenticationService.getToken()}`,
      'x-authorization': `Bearer ${this.authenticationService.getToken()}`,
    };
  }
}
