import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '@app/common/angular-material.module';
import { ProjectsFiltersPipe } from '@app/common/pipes/projects-filters.pipe';
import { ProjectsTableItem, ProjectsTableItemsPipe } from '@app/common/pipes/projects-table-items.pipe';
import { Project } from '@app/project/models';
import { ProjectValidationStatus } from '@app/project/models/project-validation-status.model';
import { TranslateModule } from '@ngx-translate/core';
import {
  ProjectValidationStatusFiltersGroup,
  ProjectsListCombinedFiltersComponent,
} from '../projects-list-combined-filters/projects-list-combined-filters.component';
import { ProjectsListExclusiveFiltersComponent } from '../projects-list-exclusive-filters/projects-list-exclusive-filters.component';
import { ProjectsListHighlightedProjectComponent } from '../projects-list-highlighted-project/projects-list-highlighted-project.component';
import { ProjectsListTableComponent } from '../projects-list-table/projects-list-table.component';

@Component({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    AngularMaterialModule,
    ProjectsListCombinedFiltersComponent,
    ProjectsListExclusiveFiltersComponent,
    ProjectsListTableComponent,
    ProjectsListHighlightedProjectComponent,
    ProjectsTableItemsPipe,
    ProjectsFiltersPipe,
  ],
  standalone: true,
  selector: 'parteng-projects-list',
  template: `
    <div class="h-full" data-testid="projects-list" *ngIf="allProjects | projectsTableItems as allProjectItems">
      <!-- Filters -->
      <div>
        <!-- Text Filter -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ 'shared.searchFieldLabel' | translate }}</mat-label>
          <input #text matInput cdkFocusInitial data-testId="projects-search-text" />
          <mat-icon matSuffix class="text-blue-ptg-primary-800">search</mat-icon>
        </mat-form-field>
        <div [ngClass]="{ hidden: statusFilters.length === 1 }" data-testId="status-filters">
          <!-- Status Filter (combined) -->
          <div *ngIf="statusFiltersMode === 'combined'">
            <parteng-projects-list-combined-filters
              *ngIf="!hideStatusFilter"
              [groups]="statusFiltersGroups"
              [selectedFilters]="selectedStatusFilters"
              (filters)="selectedStatusFilters = $event"
              (resetFilters)="selectedStatusFilters = initialValidationStatusFilters"
              (clearFilters)="selectedStatusFilters = []; text.value = ''"
            ></parteng-projects-list-combined-filters>
          </div>
          <!-- Status Filter (exclusive) -->
          <div *ngIf="statusFiltersMode === 'exclusive'">
            <parteng-projects-list-exclusive-filters
              *ngIf="!hideStatusFilter"
              [statusFilters]="statusFilters"
              [initialFilter]="initialValidationStatusFilters[0]"
              (statusFilterChanged)="selectedStatusFilters = $event"
            ></parteng-projects-list-exclusive-filters>
          </div>
        </div>
      </div>
      <!-- List of Projects -->
      <div class="overflow-y-auto min-h-[200px] mt-3 mb-1">
        <parteng-projects-list-table
          [projects]="allProjectItems | projectsFilters: text.value:selectedStatusFilters"
          [highlightedProject]="highlightedProject"
          (rowClicked)="onProjectClicked($event)"
          data-testId="projects-list-table"
        ></parteng-projects-list-table>
      </div>
      <!-- Highlighted Project -->
      <parteng-projects-list-highlighted-project
        [project]="highlightedProject"
      ></parteng-projects-list-highlighted-project>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsListComponent implements OnInit {
  @Input() initialValidationStatusFilters: ProjectValidationStatus[] = [];
  @Input() allProjects: Project[] = [];
  @Input() highlightedProject: Project | undefined;
  @Input() statusFilters: ProjectValidationStatus[] = this.initialValidationStatusFilters;
  @Input() hideStatusFilter: boolean | undefined = false;
  @Input() statusFiltersMode: 'exclusive' | 'combined' = 'exclusive';
  @Input() statusFiltersGroups: ProjectValidationStatusFiltersGroup[] = [];

  @Output() projectClicked = new EventEmitter<Project | undefined>();

  selectedStatusFilters: ProjectValidationStatus[] = [];

  ngOnInit() {
    this.selectedStatusFilters = this.initialValidationStatusFilters;
  }

  onProjectClicked(projectItem: ProjectsTableItem): void {
    this.projectClicked.emit(this.allProjects.find((p) => p.id === projectItem.id));
  }
}
