import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { PermissionService } from '@app/common/services/permission.service';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PermissionsGuard implements CanLoad {
  constructor(private permissionService: PermissionService) {}

  canLoad(_route: Route, _urlSegment: UrlSegment[]) {
    return this.permissionService.loadPermissions$().pipe(map(() => true));
  }
}
