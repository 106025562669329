import { createReducer, on } from '@ngrx/store';
import * as coreActions from './core.actions';

export interface State {
  dataReady: boolean;
  help: boolean;
}

export const initialState: State = {
  dataReady: false,
  help: false,
};

export const reducer = createReducer(
  initialState,
  on(
    coreActions.dataReady,
    (state): State => ({
      ...state,
      dataReady: true,
    })
  ),
  on(
    coreActions.enableHelp,
    (state): State => ({
      ...state,
      help: true,
    })
  ),
  on(
    coreActions.disableHelp,
    (state): State => ({
      ...state,
      help: false,
    })
  )
);
