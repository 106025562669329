import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Feature } from '@app/common/models/role.model';
import { RoleService } from '@app/common/services/role.service';

@Directive({  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[can-edit-feature]',
})
export class CanEditFeatureDirective implements OnInit {
  @Input('can-edit-feature') feature: Feature | undefined;

  constructor(private elementRef: ElementRef, private renderer2: Renderer2, private roleService: RoleService) {}

  ngOnInit() {
    if (this.roleService.canEditFeature({ feature: this.feature }) === false) {
      this.lockElement();
    }
  }

  private lockElement() {
    this.renderer2.setStyle(this.elementRef.nativeElement, 'opacity', 0.35);
    this.renderer2.setStyle(this.elementRef.nativeElement, 'pointer-events', 'none');
    this.renderer2.setStyle(this.elementRef.nativeElement, 'user-select', 'none');
  }
}
