import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogErrorComponent } from '@app/common/components/dialog-error.component';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, tap } from 'rxjs';
import { IErrorService } from './task.service';

export type ErrorContext = Partial<Record<HttpStatusCode, string>>;
export type BackendErrors = { [key: string]: string | object };
export enum BackendDetails {
  'ENTITY_VALIDATION_ERROR' = 'Entity validation error',
  'PRE_CONDITION_ERROR' = 'Pre condition error',
  'EMBED_DATA_VALIDATION_ERROR' = 'Embed data validation error',
  'ENTITY_INTEGRITY_CONSTRAINT_VIOLATION' = 'Entity integrity constraint violation',
  'COLLECTION_VALIDATION_ERROR' = 'Collection validation error',
}

export interface BackendError extends HttpErrorResponse {
  error: {
    detail: string;
    errors?: BackendErrors;
    status?: number;
    title: string;
    type: string;
  };
}

export const flatEmbedErrors = (values: any[]): unknown[] =>
  values.flatMap((key) => {
    const values = Object.values(key);
    if (values.every((text) => typeof text === 'string') === true) {
      return values;
    }
    return flatEmbedErrors(values);
  });

export const formatEmbedErrorMessages = (messages: unknown[], title: string) =>
  [...new Set(messages)].reduce((message, error) => `${message}<br/> ${error}`, `${title} : `);

@Injectable({ providedIn: 'root' })
export class ErrorService implements IErrorService {
  private dialogRef: MatDialogRef<DialogErrorComponent> | undefined;

  constructor(private translateService: TranslateService, private dialog: MatDialog) {}

  async onError(backendError: BackendError, errorContext?: ErrorContext) {
    let errorMessage;

    const error = backendError.error;
    const status = error.status;
    const detail = error.detail;

    switch (status) {
      case HttpStatusCode.BadRequest:
        errorMessage = errorContext === undefined ? detail : errorContext[HttpStatusCode.BadRequest];
        break;
      case HttpStatusCode.Forbidden:
        switch (detail) {
          case BackendDetails.ENTITY_VALIDATION_ERROR:
            errorMessage =
              error.errors === undefined
                ? this.translateService.instant('shared.entityValidationError')
                : formatEmbedErrorMessages(
                    flatEmbedErrors(Object.values(error.errors)),
                    this.translateService.instant('shared.entityValidationError')
                  );
            break;
          case BackendDetails.PRE_CONDITION_ERROR:
            errorMessage =
              error.errors === undefined
                ? this.translateService.instant('shared.preConditionError')
                : error.errors['precheck'];
            break;
          case BackendDetails.EMBED_DATA_VALIDATION_ERROR:
            errorMessage =
              error.errors === undefined
                ? this.translateService.instant('shared.embedDataValidationError')
                : formatEmbedErrorMessages(
                    flatEmbedErrors(Object.values(error.errors)),
                    this.translateService.instant('shared.entityValidationError')
                  );
            break;
          case BackendDetails.ENTITY_INTEGRITY_CONSTRAINT_VIOLATION:
            errorMessage =
              error.errors !== undefined && error.errors['message'] !== undefined
                ? error.errors['message']
                : this.translateService.instant('shared.entityConstraintViolation');
            break;
          case BackendDetails.COLLECTION_VALIDATION_ERROR:
            errorMessage = this.translateService.instant('shared.collectionValidationError');
            break;
          default:
            errorMessage = detail;
            break;
        }
        break;
      case HttpStatusCode.NotFound:
        errorMessage = errorContext === undefined ? detail : errorContext[HttpStatusCode.NotFound];
        break;
      case HttpStatusCode.Conflict:
        errorMessage = errorContext === undefined ? detail : errorContext[HttpStatusCode.Conflict];
        break;
      case HttpStatusCode.InternalServerError:
        errorMessage = detail;
        break;
      default:
        errorMessage = detail;
        break;
    }

    this.dialogRef = this.dialog.open(DialogErrorComponent, {
      panelClass: 'error-dialog-overlay',
      data: {
        text: `${errorMessage}`,
      },
    });
    await firstValueFrom(this.dialogRef.afterClosed().pipe(tap((_) => (this.dialogRef = undefined))));
  }

  getStatus(error: BackendError) {
    return error.status;
  }

  isConflict(error: BackendError) {
    return error.status === HttpStatusCode.Conflict;
  }

  closeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
