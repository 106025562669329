import { Injectable } from '@angular/core';
import { ConfigService } from '@app/common/services';
import { HalApiService } from '@app/common/services/hal-api.service';
import { Observable } from 'rxjs';
import { ProjectType } from '../models/project-type.model';

@Injectable({ providedIn: 'root' })
export class ProjectTypeService {
  constructor(private halApiService: HalApiService, private configService: ConfigService) {}

  getAll$(): Observable<ProjectType[]> {
    return this.halApiService.getCollection$(ProjectType, '/project-types', {}, 'project_types');
  }

  getProjectTypeById(projectId: number): ProjectType | undefined {
    return this.configService.getAllProjectTypes().find((projectType) => projectType.id === projectId);
  }
}
