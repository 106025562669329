import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Person } from '@app/project/models';

@Injectable({ providedIn: 'root' })
@Pipe({  standalone: true,
  name: 'personsSortByName',
})
export class PersonsSortByNamePipe implements PipeTransform {
  transform(persons: Person[]): Person[] {
    return [...(persons || [])].sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
  }
}
