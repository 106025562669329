import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '@app/common/angular-material.module';
import { ClearFiltersButtonComponent } from '@app/common/components/clear-filters-button.component';
import { ProjectValidationStatusComponent } from '@app/common/components/project-validation-status.component';
import { ResetFiltersButtonComponent } from '@app/common/components/reset-filters-button.component';
import { ProjectValidationStatusIdPipe } from '@app/common/pipes/project-validation-status-id.pipe';
import { ProjectValidationStatusLabelPipe } from '@app/common/pipes/project-validation-status-label.pipe';
import { ProjectValidationStatus } from '@app/project/models/project-validation-status.model';
import { TranslateModule } from '@ngx-translate/core';

export interface ProjectValidationStatusFiltersGroup {
  name: string;
  status: ProjectValidationStatus[];
}

@Component({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    AngularMaterialModule,
    ProjectValidationStatusComponent,
    ProjectValidationStatusIdPipe,
    ProjectValidationStatusLabelPipe,
    ResetFiltersButtonComponent,
    ClearFiltersButtonComponent,
  ],
  standalone: true,
  selector: 'parteng-projects-list-combined-filters',
  template: `
    <div class="flex justify-around items-center text-xs">
      <div *ngFor="let group of groups" class="relative border border-blue-ptg-primary-300 rounded h-12">
        <label class="absolute -top-2 left-4 bg-white px-1">
          {{ group.name }}
        </label>

        <span class="flex h-full justify-center items-center">
          <span class="flex h-full items-center" *ngFor="let validationStatus of group.status">
            <input
              class="h-[18px] w-[18px] cursor-pointer m-2"
              [checked]="isChecked(validationStatus)"
              (change)="onChange(validationStatus, $event)"
              type="checkbox"
            />
            <parteng-project-validation-status
              class="mr-1"
              [projectValidationStatusId]="validationStatus | projectValidationStatusId"
            ></parteng-project-validation-status>
            <span class="mr-1">{{ validationStatus | projectValidationStatusLabel }}</span>
          </span>
        </span>
      </div>
      <parteng-reset-filters-button (resetFilters)="onResetFilters()"></parteng-reset-filters-button>
      <parteng-clear-filters-button (clearFilters)="onClearFilters()"></parteng-clear-filters-button>
    </div>
  `,
})
export class ProjectsListCombinedFiltersComponent {
  @Input() groups: ProjectValidationStatusFiltersGroup[] = [];
  @Input() selectedFilters: ProjectValidationStatus[] = [];

  @Output() filters = new EventEmitter<ProjectValidationStatus[]>();
  @Output() resetFilters = new EventEmitter<void>();
  @Output() clearFilters = new EventEmitter<void>();

  isChecked(validationStatus: ProjectValidationStatus) {
    return this.selectedFilters.includes(validationStatus);
  }

  onChange(validationStatus: ProjectValidationStatus, event: Event) {
    this.selectedFilters =
      (event.target as HTMLInputElement).checked === true
        ? [...this.selectedFilters, validationStatus]
        : this.selectedFilters.filter((f) => f !== validationStatus);
    this.filters.emit(this.selectedFilters);
  }

  onResetFilters() {
    this.resetFilters.emit();
  }

  onClearFilters() {
    this.clearFilters.emit();
  }
}
