import { CommonModule } from '@angular/common';
import { AfterViewChecked, ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '@app/common/angular-material.module';
import { DIALOG_WIDTH_SMALL } from '@app/common/common.constants';
import { AbstractDialogComponent } from '@app/common/components';
import { DialogComponent } from '@app/common/components/dialog.component';
import { ProjectValidationStatusService } from '@app/common/services/project-validation-status.service';
import {
  ProjectWorkflowDialogComponent,
  ProjectWorkflowDialogData,
} from '@app/project/components/project-workflow/project-workflow-dialog.component';
import { ProjectValidationStatusFiltersGroup } from '@app/project/components/projects-list-combined-filters/projects-list-combined-filters.component';
import { ProjectsListComponent } from '@app/project/components/projects-list/projects-list.component';
import { Project } from '@app/project/models';
import { ProjectValidationStatus } from '@app/project/models/project-validation-status.model';
import * as projectFormActions from '@app/project/store/project-form.actions';
import * as projectFormSelectors from '@app/project/store/project-form.selectors';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

export interface DialogProjectSelectorParams {
  title: string;
  description: string;
  projects?: Project[];
  directReturn?: boolean;
  hideStatusFilter?: boolean;
  statusFiltersMode?: 'combined' | 'exclusive';
  statusFilters?: ProjectValidationStatus[];
  statusFiltersGroups?: ProjectValidationStatusFiltersGroup[];
  initialStatusFilters?: ProjectValidationStatus[];
  submitButtonText?: string;
}

export type DialogProjectSelectorComponentResult = null | Project;

@Component({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    AngularMaterialModule,
    TranslateModule,
    ProjectsListComponent,
    DialogComponent,
  ],
  standalone: true,
  selector: 'parteng-dialog-project-selector',
  template: `
    <div *ngIf="projectsListState$ | async as state">
      <parteng-dialog
        [title]="dialogParams.title || 'project.dialogProjectSelector.title' | translate"
        [description]="dialogParams.description || 'project.dialogProjectSelector.description' | translate"
        [submitBtnTranslateKey]="dialogParams.submitButtonText || 'project.dialogProjectSelector.submitButton'"
        [headerHeight]="dialogHeaderHeight + 'px'"
        [isSubmitDisabled]="!state.highlightedProject"
        (safeClose)="closeDialog()"
        (cancel)="closeDialog()"
        (submit)="gotoSelectedProject(state.highlightedProject)"
      >
        <div class="text-right">
          <div (click)="showWorkflow()" class="inline-flex font-semibold cursor-pointer">
            <span><mat-icon>visibility</mat-icon></span>
            <span class="ml-2">{{ 'project.consultation.seeWorkflow' | translate }}</span>
          </div>
        </div>
        <div class="mt-4" *ngIf="!state.isLoading; else loading">
          <parteng-projects-list
            *ngIf="state.allProjects.length > 0; else noProject"
            [allProjects]="state.allProjects"
            [highlightedProject]="state.highlightedProject"
            [statusFiltersMode]="dialogParams.statusFiltersMode || 'exclusive'"
            [statusFilters]="dialogParams.statusFilters || []"
            [statusFiltersGroups]="dialogParams.statusFiltersGroups || []"
            [initialValidationStatusFilters]="dialogParams.initialStatusFilters || []"
            [hideStatusFilter]="dialogParams.hideStatusFilter"
            (projectClicked)="onProjectClicked($event)"
          ></parteng-projects-list>
          <ng-template #noProject>
            <div class="mt-5 text-lg font-light">
              {{ 'project.dialogProjectSelector.noProject' | translate }}
            </div>
          </ng-template>
        </div>
        <ng-template #loading>
          <div class="pt-5 text-lg font-light text-center h-[600px]">
            {{ 'project.dialogProjectSelector.loading' | translate }}
          </div>
        </ng-template>
      </parteng-dialog>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogProjectSelectorComponent
  extends AbstractDialogComponent<DialogProjectSelectorComponentResult>
  implements OnInit, AfterViewChecked
{
  dialogHeaderHeight = 170;
  defaultStatusFilter = -1;
  projectsListState$ = this.store.select(projectFormSelectors.selectProjectsListState);

  constructor(
    dialogRef: MatDialogRef<DialogProjectSelectorComponent, DialogProjectSelectorComponentResult>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: DialogProjectSelectorParams,
    private translateService: TranslateService,
    private projectValidationStatusService: ProjectValidationStatusService,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    this.store.dispatch(
      !this.dialogParams?.projects
        ? projectFormActions.loadProjectsList({
            validationStatusIds: (this.dialogParams.statusFilters || [])
              .filter((statusFilter) => statusFilter !== 'PROJECT_VALIDATION_STATUS_ALL')
              .map((statusFilter) => this.projectValidationStatusService.getProjectValidationStatusId(statusFilter)!),
          })
        : projectFormActions.loadProjectsListSuccess({ allProjects: this.dialogParams.projects })
    );
  }

  ngAfterViewChecked() {
    this.ngxSpinnerService.hide();
  }

  // @TODO: See refactoring in PageProjectsListComponent
  onProjectClicked(project?: Project): void {
    if (project) {
      this.markAsDirty();
      this.store.dispatch(projectFormActions.loadProjectHighlightedInProjectsList({ projectId: project?.id }));
    } else {
      this.store.dispatch(projectFormActions.resetProjectHighlightedInProjectsList());
    }
  }

  gotoSelectedProject(project?: Project): void {
    if (this.dialogParams?.directReturn) {
      this.dialogRef.close(project);
    }
  }

  showWorkflow() {
    return this.dialog
      .open<ProjectWorkflowDialogComponent, ProjectWorkflowDialogData>(ProjectWorkflowDialogComponent, {
        width: DIALOG_WIDTH_SMALL,
        data: {
          title: this.translateService.instant('project.completeWorkflow.title'),
          textUp: this.translateService.instant('project.completeWorkflow.textUp'),
          image: './assets/images/workflow-project.png',
          cancelButtonText: this.translateService.instant('shared.close'),
          showImage: true,
        },
      })
      .afterClosed();
  }
}
