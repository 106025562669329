import { EntityWithId, EntityWithIdDto } from '@app/common/models';
import { HALDeserializeFrom } from '@app/common/services/hal-serializer.service';

export class StandardFolder2 extends EntityWithId {
  @HALDeserializeFrom()
  override status: number = 0;

  @HALDeserializeFrom()
  override id: number = 0;

  @HALDeserializeFrom()
  name: string = '';

  @HALDeserializeFrom()
  order: number = 0;

  @HALDeserializeFrom()
  comment: string = '';
}

export interface StandardFolderDto extends EntityWithIdDto {
  name: string;
  order: number;
  comment: string;
}

export class StandardFolder extends EntityWithId {
  name: string; // required
  order: number; // required
  comment: string | undefined;

  constructor(opts: Partial<StandardFolderDto> = {}) {
    super(opts);
    this.name = opts.name!;
    this.order = opts.order!;
    this.comment = opts.comment;
  }

  clone(opts: Partial<StandardFolderDto> = {}): StandardFolder {
    const clone = new StandardFolder({
      ...this.cloneEntityProps(opts),
      name: opts.name || this.name,
      order: opts.order !== undefined ? opts.order : this.order,
      comment: opts.comment || this.comment,
    });
    return clone;
  }
}
