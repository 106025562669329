import { Pipe, PipeTransform, inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ProjectsTableItem } from './projects-table-items.pipe';

@Pipe({ standalone: true, name: 'projectsTableSort' })
export class ProjectsTableSortPipe implements PipeTransform {
  translateService = inject(TranslateService);

  transform(
    dataSource: MatTableDataSource<ProjectsTableItem>,
    sort: MatSort | undefined,
    paginator: MatPaginator | undefined
  ): MatTableDataSource<ProjectsTableItem> {
    if (sort === undefined || paginator === undefined) {
      return dataSource;
    }

    const defaultSort = dataSource.sortData;

    // We want to display null dateMin first.
    // To do so, we replace null dateMin with a date that is always greater than any other date.
    const nullDate = '9999-12-31';
    const compareDates = (a: ProjectsTableItem, b: ProjectsTableItem) =>
      (a.dateMin || nullDate).localeCompare(b.dateMin || nullDate);

    dataSource.sortData = (data: ProjectsTableItem[], sort: MatSort) => {
      if (sort.active === 'dateMin') {
        return sort.direction === 'asc'
          ? data.sort((a, b) => compareDates(a, b))
          : data.sort((b, a) => compareDates(a, b));
      }
      return defaultSort(data, sort);
    };

    dataSource.sortingDataAccessor = (item: ProjectsTableItem, sortHeaderId: string): string | number => {
      if (sortHeaderId === 'name') {
        return item[sortHeaderId]?.toLocaleLowerCase() || '';
      }
      return (item as any)[sortHeaderId];
    };

    dataSource.sort = sort;
    paginator.hidePageSize = true;
    paginator._intl.itemsPerPageLabel = this.translateService.instant('shared.table.paginator.itemsPerPageLabel');
    paginator._intl.nextPageLabel = this.translateService.instant('shared.table.paginator.nextPageLabel');
    paginator._intl.previousPageLabel = this.translateService.instant('shared.table.paginator.previousPageLabel');
    paginator._intl.firstPageLabel = this.translateService.instant('shared.table.paginator.firstPageLabel');
    paginator._intl.lastPageLabel = this.translateService.instant('shared.table.paginator.lastPageLabel');
    paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 sur ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} sur ${length}`;
    };
    dataSource.paginator = paginator;

    return dataSource;
  }
}
