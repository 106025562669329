import { DynamicPipes } from "@app/common/pipes/apply-pipe.pipe";

/**
 * @file
 * Interface describing a table column to use in an Angular Material Table.
 *
 * @see https://material.angular.io/components/table
 */
export interface TableColumnDef {
  key: string;
  labelTranslateKey: string;
  pipeName?: DynamicPipes;
  pipeArgs?: any[];
  width?: string;
}
