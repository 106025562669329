import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AbstractFormDialogComponent } from '@app/common/components';
import { CanEditFeatureDirective } from '@app/common/directives/can-edit-feature.directive';
import { Feature } from '@app/common/models/role.model';
import { PersonTypeToTranslatedLabelPipe } from '@app/common/pipes/person-type-to-translated-label.pipe';
import { TaskService } from '@app/common/services/task.service';
import { ServicesStore } from '@app/common/store/services.store';
import { SettingsService } from '@app/data-entry/services/settings.service';
import { FundType, LegalEntityType, Person, PersonTypeId, Project } from '@app/project/models';
import { FundTypeService } from '@app/project/services/fund-type.service';
import { LegalEntityTypeService } from '@app/project/services/legal-entity-type.service';
import { PersonGroupService } from '@app/project/services/person-group.service';
import { PersonService } from '@app/project/services/person.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, firstValueFrom, lastValueFrom } from 'rxjs';
import { DialogComponent } from '../dialog.component';
import { GenericDialogService } from '../generic-dialog/generic-dialog.service';
import {
  PersonFormMode,
  PersonFormResult,
  PersonFormSharedComponent,
} from '../person-form-shared/person-form-shared.component';

export interface DialogPersonData {
  mode: PersonFormMode;
  project: Project | undefined;
  person: Person;
  showDeleteButton: boolean;
  fundManager?: boolean;
  disablePersonCreation?: boolean;
  readonly?: boolean;
  contextFeature: Feature | undefined;
}

@Component({  standalone: true, imports:[DialogComponent, TranslateModule, PersonTypeToTranslatedLabelPipe, NgIf, MatIconModule, CanEditFeatureDirective, PersonFormSharedComponent, AsyncPipe],
  selector: 'parteng-dialog-person',
  template: `
    <parteng-dialog
      data-testId="dialog-person"
      [showDialogButtons]="false"
      [title]="
        dialogParams.mode === 'create'
          ? ('project.dialogPersonForm.' + personType() + '.title' | translate)
          : (dialogParams.person.person_type | personTypeToTranslatedLabel)
      "
      [description]="
        dialogParams.mode === 'create' ? ('project.dialogPersonForm.' + personType() + '.description' | translate) : ''
      "
      [isSubmitDisabled]="isFormInvalid"
      (cancel)="cancel()"
      (safeClose)="cancel()"
    >
      <div *ngIf="!dialogParams.readonly && (mode === 'view' || mode === 'verification')" class="text-right">
        <mat-icon
          class="ml-2 text-neutral-500 cursor-pointer picto"
          (click)="setMode('edit')"
          [can-edit-feature]="contextFeature"
          data-testId="person-edit-button"
          >edit</mat-icon
        >
      </div>

      <parteng-person-form-shared
        [mode]="mode"
        [initialMode]="initialMode"
        [person]="dialogParams.person"
        [project]="dialogParams.project"
        [allLegalEntityTypes]="(allLegalEntityTypes$ | async)!"
        [allFundTypes]="(allFundTypes$ | async)!"
        [allPersons]="(allPersons$ | async)!"
        [showDeleteButton]="dialogParams.showDeleteButton"
        [showChangeProjectButton]="!dialogParams.readonly"
        [disablePersonCreation]="!!dialogParams.disablePersonCreation"
        [contextFeature]="contextFeature"
        (deletePerson)="onDeletePerson()"
        (changeProjectCreation)="onChangePersonProjectCreation()"
        (cancel)="cancel()"
        (formSubmitted)="onFormSubmitted($event)"
      ></parteng-person-form-shared>
    </parteng-dialog>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogPersonComponent extends AbstractFormDialogComponent<PersonFormResult> implements OnInit {
  // TODO: remove
  formState$!: Observable<any>;
  allLegalEntityTypes$ = this.servicesStore.select<LegalEntityType[]>('legalEntityTypes');
  allFundTypes$ = this.servicesStore.select<FundType[]>('fundTypes');
  allPersons$: Observable<Person[]> = this.servicesStore.select<Person[]>('allPersons');
  fundManager: Person | undefined;
  mode!: PersonFormMode;
  initialMode: PersonFormMode = 'create';
  contextFeature: Feature | undefined;

  @ViewChild(PersonFormSharedComponent) formComponent!: PersonFormSharedComponent;

  constructor(
    dialogRef: MatDialogRef<DialogPersonComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: DialogPersonData,
    private servicesStore: ServicesStore,
    private legalEntityTypeService: LegalEntityTypeService,
    private fundTypeService: FundTypeService,
    private personService: PersonService,
    private personGroupService: PersonGroupService,
    private translateService: TranslateService,
    private genericDialogService: GenericDialogService,
    private taskService: TaskService,
    private settingsService: SettingsService
  ) {
    super(dialogRef);
    this.setMode(this.dialogParams.mode);
    this.contextFeature = this.dialogParams.contextFeature;
    this.initialMode = this.dialogParams.mode;
  }

  ngOnInit() {
    this.servicesStore.dispatch(this.legalEntityTypeService.getAll$(), 'legalEntityTypes');
    this.servicesStore.dispatch(this.fundTypeService.getAll$(), 'fundTypes');
    this.servicesStore.dispatch(this.personService.getAll$(), 'allPersons');
  }

  setMode(mode: PersonFormMode) {
    this.mode = mode;
    this.dialogLeaveWarningTextTranslateKey =
      mode === 'create' ? 'shared.dialogSafeExitCreate.text' : 'shared.dialogSafeExitUpdate.text';
  }

  personType() {
    return this.dialogParams.fundManager ? 'fundManager' : this.dialogParams.person.person_type;
  }

  async onDeletePerson() {
    const confirm = await lastValueFrom(
      this.genericDialogService.warningDelete(
        this.translateService.instant(
          `shared.dialogDeleteWarning.${
            this.dialogParams.person.person_type === this.settingsService.get<PersonTypeId>('PERSON_TYPE_GROUP')
              ? 'GROUP'
              : 'PERSON'
          }`,
          { name: this.dialogParams.person.name }
        )
      )
    );
    if (!confirm) {
      return;
    }
    // TODO: check if person is not used anywhere
    await this.taskService.doDelete$(this.personService.deletePerson$(this.dialogParams.person));
    this.dialogRef.close();
  }

  async onChangePersonProjectCreation(): Promise<void> {
    await firstValueFrom(this.personService.showPersonCreationProjectDialog(this.dialogParams.person));
    this.dialogRef.close();
  }

  async onFormSubmitted(persons?: PersonFormResult | undefined) {
    if (this.mode === 'verification') {
      this.dialogRef.close(persons);
      return;
    }
    if (!persons) {
      return this.cancel();
    }

    // TODO: check name uniqueness before saving.
    // See with backend for an endpoint to check name uniqueness.
    // (name is generated by the backend).
    const savedPerson: Person = await this.taskService.doSave$(this.personService.save$(persons.person));
    if (!savedPerson) {
      // An error has occured (person already exists or anything else)
      return;
    }

    if (this.personService.isFundPerson(savedPerson) && persons.fundManager !== undefined) {
      savedPerson.$fundManagerName = persons.fundManager!.name;
    }

    if (this.mode === 'create' && this.personService.isGroupPerson(persons.person) === true) {
      const personGroup = new Person({ ...persons.person, id: savedPerson.id });
      await this.taskService.doSave$(
        this.personGroupService.addPersonsInPersonGroup$(
          personGroup,
          personGroup.personsInGroup!.map((person) => person.id)
        )
      );
    }

    savedPerson.personsInGroup = persons.person.personsInGroup;
    savedPerson.creationProject = persons.person.creationProject;
    savedPerson.creation_projects_id = persons.person.creation_projects_id;

    this.dialogRef.close(
      savedPerson
        ? { person: savedPerson, fundManager: persons.fundManager, isCreatedFundManager: persons.isCreatedFundManager }
        : undefined
    );
  }

  cancel() {
    this.dialogRef.close();
  }
}
