import { createAction, props } from '@ngrx/store';

// Action that does nothing, required in some scenarios
export const noOp = createAction('[Core] No Op');

export const dataReady = createAction('[Core] Data ready');

export const enableHelp = createAction('[Core] Enable help');

export const disableHelp = createAction('[Core] Disable help');

export const openSnackbar = createAction('[Core] Open snackbar', props<{ message: string; isErrorMessage: boolean }>());
