import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({  standalone: true, imports:[MatIconModule, TranslateModule, MatDialogModule, MatButtonModule],
  selector: 'parteng-dialog-error',
  template: `
    <div class=" bg-red-700 text-white">
      <h2 mat-dialog-title align="end" class="px-5">
        <mat-icon
          aria-hidden="false"
          [attr.aria-label]="'shared.dialog.closeAriaLabel' | translate"
          class="!text-white cursor-pointer picto"
          [mat-dialog-close]="false"
          >close</mat-icon>
      </h2>

      <div class="w-[500px] mx-auto py-16">
        <h2 class="text-white text-center text-2xl font-semibold">
          {{ 'shared.errorDialog.title' | translate }}
        </h2>
        <div mat-dialog-content class="flex flex-col items-center">
          <div class="border-t border-white w-[280px] pb-4"></div>
          <p class="text-base text-white text-center" [innerHTML]="dialogParams.text"></p>
        </div>
        <mat-dialog-actions align="center" class="flex flex-col gap-3">
          <button mat-stroked-button [mat-dialog-close]="false" class="font-semibold !border-white !text-white">
            {{ 'shared.errorDialog.buttonLabel' | translate }}
          </button>
        </mat-dialog-actions>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogErrorComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public dialogParams: { text: string }) {}
}
