import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { JsHelper } from '@app/common/helpers';
import { TableColumnDef } from '@app/common/models';
import { Feature } from '@app/common/models/role.model';
import { PersonTypeToTranslatedLabelPipe } from '@app/common/pipes/person-type-to-translated-label.pipe';
import { Person, PersonType, PersonTypeId, Project } from '@app/project/models';
import { PersonService } from '@app/project/services/person.service';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, lastValueFrom, map, of } from 'rxjs';
import { DialogItemSelectorComponent } from '../dialog-item-selector/dialog-item-selector.component';
import { PersonAdditionalInfoComponent } from '../person-additional-info/person-additional-info.component';

export interface DialogPersonsSelectorData {
  additionalInfoTitle: string;
  closeAfterAdd: boolean;
  dialogDescription: string;
  dialogTitle: string;
  disableAddPersonButton: boolean;
  hideAddPersonButton: boolean;
  isMonoSelection: boolean;
  personTypeListAuthorized: PersonTypeId[];
  project: Project;
  selectedItemsDescription: string;
  selectedItemsTitle: string;
  selectedPersons: Person[];
}

@Component({
  standalone: true,
  imports: [
    DialogItemSelectorComponent,
    NgIf,
    AsyncPipe,
    PersonAdditionalInfoComponent,
    PersonTypeToTranslatedLabelPipe,
    TranslateModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
  ],
  selector: 'parteng-dialog-persons-selector',
  template: `
    <section class="dialog-person-selector">
      <parteng-dialog-item-selector
        #itemSelector
        *ngIf="persons$ | async as persons"
        [dialogTitle]="dialogParams.dialogTitle"
        [selectedItemsTitle]="dialogParams.selectedItemsTitle"
        [selectedItemsDescription]="dialogParams.selectedItemsDescription"
        [dialogDescription]="dialogParams.dialogDescription"
        [itemAdditionalInfoTitle]="dialogParams.additionalInfoTitle"
        [itemAdditionalInfoHTML]="itemAdditionalInfoHTML"
        [selectedItemPreviewHTML]="selectedItemPreviewHTML"
        [columnDefs]="columnDefs"
        [allItems]="persons"
        [defaultSelectedItems]="dialogParams.selectedPersons"
        [filterItemFn]="filterItemFn"
        [isMonoSelection]="dialogParams.isMonoSelection"
      >
        <!-- Additional Info -->
        <ng-template #itemAdditionalInfoHTML let-person="item">
          <parteng-person-additional-info [person]="person"></parteng-person-additional-info>
        </ng-template>

        <!-- Selected Item Preview -->
        <ng-template #selectedItemPreviewHTML let-person="item">
          <div class="flex text-sm w-full">
            <div class="pr-3">{{ person.name }}</div>
            <div>{{ person.person_type | personTypeToTranslatedLabel }}</div>
          </div>
        </ng-template>

        <!-- Add Item Button -->
        <button
          *ngIf="!dialogParams.hideAddPersonButton"
          [disabled]="dialogParams.disableAddPersonButton"
          mat-stroked-button
          color="primary"
          [matMenuTriggerFor]="menu"
        >
          {{ 'shared.dialogPersonSelector.createItem' | translate }}
          <mat-icon aria-hidden="true">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="openAddPersonDialog('PERSON_TYPE_LEGAL_PERSON')">
            {{ 'shared.dialogPersonSelector.createItemLegalPerson' | translate }}
          </button>
          <button mat-menu-item (click)="openAddPersonDialog('PERSON_TYPE_NATURAL_PERSON')">
            {{ 'shared.dialogPersonSelector.createItemNaturalPerson' | translate }}
          </button>
          <button mat-menu-item (click)="openAddPersonDialog('PERSON_TYPE_INVESTMENT_FUND')">
            {{ 'shared.dialogPersonSelector.createItemInvestmentFund' | translate }}
          </button>
          <button *ngIf="canCreatePersonGroup" mat-menu-item (click)="openAddPersonDialog('PERSON_TYPE_GROUP')">
            {{ 'shared.dialogPersonSelector.createItemPersonGroup' | translate }}
          </button>
        </mat-menu>
      </parteng-dialog-item-selector>
    </section>
  `,
})
export class DialogPersonsSelectorComponent implements OnInit {
  persons$: Observable<Person[]> = of([]);
  canCreatePersonGroup = false;

  columnDefs: TableColumnDef[] = [
    { key: 'id', labelTranslateKey: 'shared.dialogPersonSelector.columnId' },
    { key: 'name', labelTranslateKey: 'shared.dialogPersonSelector.columnName' },
    { key: 'short_name', labelTranslateKey: 'shared.dialogPersonSelector.columnShortName', pipeName: 'fillEmpty' },
    {
      key: 'person_type',
      labelTranslateKey: 'shared.dialogPersonSelector.columnType',
      pipeName: 'personTypeToTranslatedLabel',
    },
  ];

  @ViewChild('itemSelector') itemSelector: DialogItemSelectorComponent<Person> | undefined;

  constructor(
    private dialogRef: MatDialogRef<DialogPersonsSelectorComponent, Person[]>,
    private personService: PersonService,
    @Inject(MAT_DIALOG_DATA) public dialogParams: DialogPersonsSelectorData
  ) {}

  async ngOnInit() {
    this.refreshList();
    this.canCreatePersonGroup =
      this.dialogParams.personTypeListAuthorized.length === 0 ||
      this.dialogParams.personTypeListAuthorized.includes(this.personService.getPersonTypeId('PERSON_TYPE_GROUP')) ===
        true;
  }

  filterItemFn(item: Person, filter: string) {
    return JsHelper.ObjPropsContainString(item, filter, [
      'id',
      'name',
      'legal_entity_identifier',
      'comment',
      'short_name',
    ]);
  }

  async openAddPersonDialog(personType: PersonType) {
    const createdPersons = await lastValueFrom(
      this.personService.showPersonDialog({
        project: this.dialogParams.project,
        personTypeId: this.personService.getPersonTypeId(personType),
        mode: 'create',
        contextFeature: Feature.persons,
      })
    );
    if (createdPersons && this.dialogParams.closeAfterAdd) {
      return this.dialogRef.close([createdPersons.person]);
    }

    // We refresh even if user canceled the creation because he/she may have added a person
    // in a different dialog (fund manager...)
    await this.refreshList();
    if (createdPersons) {
      // We return the initial created person (not fund manager if created)
      this.itemSelector?.selectItem(createdPersons.person);
    }
  }

  async refreshList() {
    const selectedPersonsIds = [
      ...(this.dialogParams.selectedPersons ? this.dialogParams.selectedPersons.map((p) => p.id) : []),
      ...(this.itemSelector?.selectedItems.map((s) => s.id) || []),
    ];
    this.persons$ = of(
      (await lastValueFrom(
        this.dialogParams.personTypeListAuthorized.length === 0
          ? this.personService.getAll$()
          : this.personService.getByTypes$(this.dialogParams.personTypeListAuthorized.map(String))
      )) || []
    ).pipe(
      map((persons) =>
        persons
          // isMonoSelection = true means we move persons between the main list and the selected list.
          // isMonoSelection = false means we do not have a selected list, so we show all persons in the main list.
          .filter((person) => !(this.dialogParams.isMonoSelection && selectedPersonsIds.includes(person.id)))
          .sort((b, a) => a.person_type - b.person_type || (b.name || '').localeCompare(a.name || ''))
      )
    );
  }
}
