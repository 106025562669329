import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { Observable, map } from 'rxjs';

import { HALResource } from '@app/common/models';
import { PartengApiService, RestService, SerializerService } from '@app/common/services';
import { StandardFolder, StandardFolderDto } from '../models/standard-folder.model';

@Injectable({ providedIn: 'root' })
export class StandardFolderSerializerService extends SerializerService<StandardFolder, StandardFolderDto> {
  fromDto(json: StandardFolderDto | HALResource<StandardFolderDto>): StandardFolder {
    return new StandardFolder(json);
  }

  toDto(entity: StandardFolder): any {
    return {
      ...this.getDtoBaseProps(entity),
      name: entity.name,
      order: entity.order,
      comment: entity.comment!,
    };
  }
}

@Injectable({ providedIn: 'root' })
export class StandardFolderService extends PartengApiService<StandardFolder, HALResource<StandardFolderDto>> {
  constructor(rest: RestService, serializer: StandardFolderSerializerService) {
    super(rest, serializer, '/standard-folders', 'standard_folders');
  }

  getAll$(): Observable<StandardFolder[]> {
    return this.getCollection$().pipe(map((standardFolders) => _.sortBy(standardFolders, 'order')));
  }
}
