import { environmentCommon } from './environment-common'

export const environment = {
  ...environmentCommon,
  name: 'development',
  production: true,
  sourceMap: true,
  api: {
    baseURL: 'https://api.parteng.dev.arpilabe.tech/api/rest/v1',
  },
}
