/**
 * @file
 * Thin wrapper around Angular's HttpClient.
 */
import { HttpClient, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { RestRequest } from '@app/common/models/rest.models';
import { EMPTY, Observable, catchError, throwError } from 'rxjs';

export type ResponseBody = unknown;

@Injectable({ providedIn: 'root' })
export class RestService {
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {}

  get<T = ResponseBody>(request: RestRequest): Observable<T> {
    const httpOptions = {
      headers: this.headers(request),
      params: request.queryParams,
    };
    return this.catchError(this.http.get<T>(request.url, httpOptions));
  }

  post<T = ResponseBody>(request: RestRequest): Observable<T> {
    const httpOptions = {
      headers: this.headers(request),
      params: request.queryParams,
    };
    return this.catchError(this.http.post<T>(request.url, request.body, httpOptions));
  }

  put<T = ResponseBody>(request: RestRequest): Observable<T> {
    const httpOptions = {
      headers: this.headers(request),
      params: request.queryParams,
    };
    return this.catchError(this.http.put<T>(request.url, request.body, httpOptions));
  }

  patch<T = ResponseBody>(request: RestRequest): Observable<T> {
    const httpOptions = {
      headers: this.headers(request),
      params: request.queryParams,
    };
    return this.catchError(this.http.patch<T>(request.url, request.body, httpOptions));
  }

  delete<T = ResponseBody>(request: RestRequest): Observable<T> {
    const httpOptions = {
      headers: this.headers(request),
      params: request.queryParams,
    };
    return this.catchError(this.http.delete<T>(request.url, httpOptions));
  }

  request<G = ResponseBody>(req: RestRequest): Observable<G> {
    const httpOptions = {
      headers: this.headers(req),
      params: req.queryParams,
      body: req.body,
    };
    return this.catchError(this.http.request<G>(req.method!, req.url, httpOptions));
  }

  private headers(req: RestRequest): any {
    return new HttpHeaders({
      ...req.headers,
      Accept: 'application/hal+json',
      Authorization: `Bearer ${this.authenticationService.getToken()}`,
      'x-authorization': `Bearer ${this.authenticationService.getToken()}`,
    });
  }

  // In a perfect world, this method would be in parteng-api.service.ts.
  // It's not possbile because it needs AuthenticationService to be injected.
  // Because of inheritance (bad practice), we would have to repeat a lot of code:
  // injecting AuthenticationService in each child.
  // Unfortunately, manual injection does not work either (probably because of lazy loading)
  // and it would be strange trick.
  // TODO: rename RestService to PartengApiRestService.
  private catchError(request$: Observable<any>): Observable<any> {
    return request$.pipe(
      catchError((err) => {
        if (err.status === HttpStatusCode.Unauthorized) {
          this.authenticationService.forceLogin();
          return EMPTY;
        }
        return throwError(() => err);
      })
    );
  }
}
