import { HALDeserializeFrom } from '@app/common/services/hal-serializer.service';
import { Goal2 } from './goal.model';

export class RelProjectTemplateToGoal {
  @HALDeserializeFrom()
  project_templates_id: number = 0;

  @HALDeserializeFrom()
  goals_id: number = 0;

  @HALDeserializeFrom()
  goal!: Goal2;

  @HALDeserializeFrom()
  status: number = 0;
}
