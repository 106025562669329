import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
@Component({  standalone: true, imports:[NgClass, NgIf],
  selector: 'parteng-dynamic-input',
  template: `
    <ng-container *ngIf="mode === 'view'">
      <span class="mt-3 text-xs text-blue-ptg-primary font-semibold">{{ label }}</span>
      <!-- prettier-ignore -->
      <div class="mb-5" [ngClass]="{'whitespace-pre-line' : enableBreakLine, 'break-all': enableBreakLine }">{{ displayValue }}</div>
    </ng-container>
    <ng-container *ngIf="mode === 'edit'">
      <ng-content></ng-content>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicInputComponent {
  @Input() label: string = '';
  @Input() displayValue: unknown = '';
  @Input() mode: 'view' | 'edit' = 'view';
  @Input() enableBreakLine = false;
}
