import { DOCUMENT } from '@angular/common';
import { InjectionToken, inject } from '@angular/core';

// Why not use PlatformLocation or Location from Angular.
// Because, this token provides a low-level access to the browser's location object,
// allowing us to force redirect the browser to a specific URL.
// This is used in login.component.ts and authentication.service.ts.
// TODO: see if we can force-redirect by an Angular way.
// Note : we could use DOCUMENT token, but for testing purpose, we create a specific token.
// Also, naming it DOCUMENT_LOCATION is to prevent confusion with the GeoLocation API.
export const DOCUMENT_LOCATION: InjectionToken<Location> = new InjectionToken('Document Location API', {
  factory: (): Location => inject(DOCUMENT).location,
  providedIn: 'root',
});
