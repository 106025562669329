import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '@app/common/services';

@Pipe({  standalone: true,
  name: 'configData',
})
export class ConfigDataPipe implements PipeTransform {
  constructor(private config: ConfigService) {}

  transform<T>(dataId: number | undefined, dataName: 'LegalEntityTypes' | 'FundTypes', field: keyof T): string | null {
    if (!dataId) {
      return null;
    }
    const accessorName = `getAll${dataName}`;
    // @ts-ignore
    if (!this.config[accessorName]) {
      throw new Error(`ConfigDataPipe: accessor ${accessorName} not found`);
    }
    // @ts-ignore
    const data = (this.config[accessorName]() || []).find((d: { id: number }) => d.id === dataId);
    if (!data) {
      return null;
    }
    if (data[field] === undefined) {
      throw new Error(`ConfigDataPipe: field ${String(field)} not found in ${dataName}`);
    }
    return data[field];
  }
}
