import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'parteng-no-connection',
  template: `
    <div class="text-center mt-10">
      <h1>
        {{ 'auth.noConnection' | translate }}
      </h1>
    </div>
  `,
})
export class NoConnectionComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    if (navigator.onLine) {
      this.router.navigateByUrl('/');
    }
  }
}
