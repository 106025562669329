import { CommonModule, DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '@app/common/angular-material.module';
import { AbstractDialogComponent } from '@app/common/components';
import { DialogComponent } from '@app/common/components/dialog.component';
import { FolderValidationStatusIconPipe } from '@app/common/pipes/folder-validation-status-icon.pipe';
import { TaskService } from '@app/common/services/task.service';
import { Folder, Project } from '@app/project/models';
import { FolderService2 } from '@app/project/services/folder2.service';
import { ProjectSelectorService } from '@app/project/services/project-selector.service';
import { ProjectService } from '@app/project/services/project.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { Transfer } from '../../models';
import { TransferService } from '../../services';

export interface DialogMoveTransferData {
  transfer: Transfer;
}

@Component({
  imports: [CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  TranslateModule,
  AngularMaterialModule, DialogComponent, FolderValidationStatusIconPipe],
  standalone: true,
  selector: 'parteng-dialog-move-transfer',
  template: `
    <parteng-dialog [showDialogButtons]="false" [title]="title()" (cancel)="cancel()" (safeClose)="cancel()">
      <!-- STEP 1 - move to project or folder? -->
      <ng-container *ngIf="!selectedProject && !confirming">
        <div class="mb-4 text-blue-ptg-primary font-semibold">
          {{ 'transfers.moveTransfer.description' | translate }}
        </div>
        <div class="border border-neutral-300 rounded-md">
          <div class="flex border-b p-4">
            <div class="w-3/4">
              <span>{{ 'transfers.moveTransfer.project' | translate }}</span
              ><span class="font-semibold">{{ dialogParams.transfer.parentProject.longName }}</span>
            </div>
            <div class="w-1/4">
              <button mat-raised-button color="primary" class="w-full" (click)="showProjectSelectorDialog()">
                {{ 'transfers.moveTransfer.buttons.moveToAnotherProject' | translate }}
              </button>
            </div>
          </div>
          <div class="flex p-4">
            <div class="w-3/4">
              <span>{{ 'transfers.moveTransfer.folder' | translate }}</span
              ><span class="font-semibold">{{ dialogParams.transfer.parentFolder.longName }}</span>
            </div>
            <div *ngIf="availableDestinationFolders(currentProject!).length > 1" class="w-1/4">
              <button mat-raised-button color="primary" class="w-full" (click)="selectedProject = currentProject">
                {{ 'transfers.moveTransfer.buttons.moveToAnotherFolder' | translate }}
              </button>
            </div>
          </div>
        </div>
        <div class="mt-4 text-center">
          <button mat-button (click)="cancel()" class="mt-4">
            {{ 'transfers.moveTransfer.buttons.cancel' | translate }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="!confirming">
        <!-- STEP 2, first case - move to another PROJECT -->
        <ng-container *ngIf="selectedProject && !confirming">
          <ng-container *ngIf="selectedProject.id !== dialogParams.transfer.parentProject.id">
            <div class="border border-neutral-300 rounded-md p-4">
              <div class="text-blue-ptg-primary">
                {{ 'transfers.moveTransfer.fromProject' | translate }}
              </div>
              <div class="font-semibold">
                {{ dialogParams.transfer.parentProject.longName }}
              </div>
              <div class="text-blue-ptg-primary mt-4">
                {{ 'transfers.moveTransfer.toProject' | translate }}
              </div>
              <div class="font-semibold ">
                {{ selectedProject.longName }}
              </div>
            </div>
            <div class="text-center font-semibold text-blue-ptg-primary mt-4 mb-4">
              {{ 'transfers.moveTransfer.chooseFolder' | translate }}
            </div>
          </ng-container>
          <!-- STEP 2, second case - move to another FOLDER -->
          <ng-container *ngIf="selectedProject.id === dialogParams.transfer.parentProject.id">
            <div class="border border-neutral-300 rounded-md p-4">
              <div class="mb-4 text-blue-ptg-primary font-semibold">
                {{ 'transfers.moveTransfer.description' | translate }}
              </div>
              <div>
                <span>{{ 'transfers.moveTransfer.project' | translate }}</span
                ><span class="font-semibold">{{ dialogParams.transfer.parentProject.longName }}</span>
              </div>
              <div>
                <span>{{ 'transfers.moveTransfer.folder' | translate }}</span
                ><span class="font-semibold">{{ dialogParams.transfer.parentFolder.longName }}</span>
              </div>
            </div>
            <div class="text-center font-semibold text-blue-ptg-primary mt-4 mb-4">
              {{ 'transfers.moveTransfer.chooseAnotherFolder' | translate }}
            </div>
          </ng-container>
          <!-- STEP 2, all cases - folders list -->
          <ng-container *ngIf="selectedProject.folders.length > 0">
            <div *ngFor="let folder of availableDestinationFolders(selectedProject)">
              <div
                *ngIf="folder.id !== dialogParams.transfer.parentFolder.id"
                class="folder py-2 px-3 flex justify-between items-center w-full mb-3 cursor-pointer"
                (click)="selectedFolder = folder"
                [ngClass]="{
                  'bg-blue-ptg-primary text-white': folder === selectedFolder,
                  'bg-blue-ptg-secondary-060 text-blue-ptg-primary': folder !== selectedFolder
                }"
              >
                <div class="material-icons text-gray-500 text-left">{{ folder | folderValidationStatusIcon }}</div>
                <div class="ml-2 font-semibold text-left w-full text-xs">{{ folder.longName }}</div>
              </div>
            </div>
            <div class="text-center mt-5">
              <button
                mat-raised-button
                color="primary"
                [disabled]="!this.selectedFolder"
                (click)="selectedProject.id !== currentProject!.id ? (confirming = true) : moveTransfer()"
              >
                {{ 'transfers.moveTransfer.buttons.move' | translate }}
              </button>
              <br />
              <button mat-button (click)="cancel()" class="mt-4">
                {{ 'transfers.moveTransfer.buttons.cancel' | translate }}
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedProject.folders.length === 0"> Aucun dossier </ng-container>
        </ng-container>
      </ng-container>
      <!-- STEP 3 - confirm -->
      <ng-container *ngIf="confirming">
        <div class="text-center text-base">
          <div class="text-blue-ptg-primary font-semibold text-xl pb-4">
            {{ 'transfers.moveTransfer.confirmation.attention' | translate }}
          </div>
          <div class="border-b border-neutral-300 w-1/3 mx-auto mb-4"></div>
          <div class="">
            {{ 'transfers.moveTransfer.confirmation.youGonnaRemove' | translate }}
          </div>
          <div class="">
            <span>{{ 'transfers.moveTransfer.project' | translate }}</span>
            <span class="font-semibold">{{ dialogParams.transfer.parentProject.longName }}</span>
          </div>
          <div>
            <span>{{ 'transfers.moveTransfer.folder' | translate }}</span
            ><span class="font-semibold">{{ dialogParams.transfer.parentFolder.longName }}</span>
          </div>
          <div class="mt-4 pb-4 ">
            {{ 'transfers.moveTransfer.confirmation.toMoveTo' | translate }}
            <div class="">
              <span>{{ 'transfers.moveTransfer.project' | translate }}</span>
              <span class="font-semibold">{{ selectedProject?.longName }}</span>
            </div>
            <div>
              <span>{{ 'transfers.moveTransfer.folder' | translate }}</span
              ><span class="font-semibold">{{ selectedFolder?.longName }}</span>
            </div>
          </div>
          <div class="border-b border-neutral-300 w-1/3 mx-auto mb-4"></div>
          <div class="text-center mt-5">
            <button mat-button (click)="confirming = false" class="mt-4 mr-4">
              {{ 'transfers.moveTransfer.buttons.cancel' | translate }}
            </button>
            <button mat-stroked-button color="primary" (click)="moveTransfer()">
              {{ 'transfers.moveTransfer.buttons.confirm' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </parteng-dialog>
  `,
})
export class DialogMoveTransferComponent extends AbstractDialogComponent<boolean> implements OnInit {
  currentProject: Project | undefined;
  selectedProject: Project | undefined | null;
  selectedFolder: Folder | undefined;
  confirming: boolean = false;

  constructor(
    dialogRef: MatDialogRef<DialogMoveTransferComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: DialogMoveTransferData,
    private projectService: ProjectService,
    private transferService: TransferService,
    private taskService: TaskService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private projectSelectorService: ProjectSelectorService,
    private folderService: FolderService2
  ) {
    super(dialogRef);
  }

  async ngOnInit() {
    // Needed because transfer.parentProject does not contain folders
    this.currentProject = await firstValueFrom(
      this.projectService.getById$(this.dialogParams.transfer.parentProject.id)
    );
  }

  title() {
    if (this.confirming) {
      return '';
    }
    if (!this.selectedProject) {
      return this.translateService.instant('transfers.moveTransfer.title', {
        folderName: this.dialogParams.transfer?.parentFolder?.longName,
        transferLabel: this.transferLabel(),
      });
    }
    if (this.selectedProject) {
      return this.translateService.instant('transfers.moveTransfer.selectFolderTitle', {
        folderName: this.dialogParams.transfer?.parentFolder?.longName,
        transferLabel: this.transferLabel(),
      });
    }
    return '';
  }

  async showProjectSelectorDialog() {
    const notReviewProjectsWithAtLeastOneFolder = (
      (await firstValueFrom(this.projectService.getNotReviewProjectsWithAtLeastOneFolder$())) || []
    ).filter((project) => project.id !== this.currentProject!.id);
    this.selectedProject = await firstValueFrom(
      this.projectSelectorService.openProjectSelectorForTransferMove(
        notReviewProjectsWithAtLeastOneFolder,
        this.transferLabel()
      )
    );
  }

  transferLabel(): string {
    return `${this.datePipe.transform(this.dialogParams.transfer.date, 'shortDate')} ${
      this.dialogParams.transfer.transferType?.name
    }`;
  }

  availableDestinationFolders(project: Project): Folder[] {
    return (project?.folders || [])
      .filter((folder) => this.folderService.isFolderNotReviewed(folder))
      .sort((a, b) => a.order - b.order);
  }

  async moveTransfer() {
    await this.taskService.doSave$(
      this.transferService.moveTransfer$(this.dialogParams.transfer, this.selectedFolder!)
    );
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
