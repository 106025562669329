import { NgForOf, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { AngularMaterialModule } from '@app/common/angular-material.module';
import { AbstractFormComponent } from '@app/common/components';
import { CanEditFeatureDirective } from '@app/common/directives/can-edit-feature.directive';
import { Feature } from '@app/common/models/role.model';
import { PersonGroupChipSortPipe } from '@app/common/pipes/person-group-tablet-sort.pipe';
import { IsPersonReviewedPipe } from '@app/common/pipes/person-reviewed.pipe';
import { PersonsGroupToStringPipe } from '@app/common/pipes/persons-group-to-string.pipe';
import { TaskService } from '@app/common/services/task.service';
import { SettingsService } from '@app/data-entry/services/settings.service';
import { FundType, LegalEntityType, Person, Project } from '@app/project/models';
import { PersonGroupService } from '@app/project/services/person-group.service';
import { PersonService } from '@app/project/services/person.service';
import { TranslateModule } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { DynamicInputComponent } from '../dynamic-input.component';

export type PersonFormMode = 'create' | 'view' | 'edit' | 'verification';
type FieldMode = 'view' | 'edit';

export interface PersonFormResult {
  person: Person;
  fundManager?: Person;
  isCreatedFundManager?: boolean;
}

@Component({  standalone: true, imports:[NgIf, NgSwitch, NgSwitchCase, TranslateModule, ReactiveFormsModule, DynamicInputComponent, PersonsGroupToStringPipe, NgForOf, AngularMaterialModule, PersonGroupChipSortPipe, IsPersonReviewedPipe, CanEditFeatureDirective, NgTemplateOutlet ],
  selector: 'parteng-person-form-shared',
  templateUrl: './person-form-shared.component.html',
})
export class PersonFormSharedComponent extends AbstractFormComponent<PersonFormResult> implements OnChanges, OnInit {
  @Input() mode: PersonFormMode = 'create';
  @Input() initialMode: PersonFormMode = 'create';
  @Input() showDeleteButton: boolean = false;
  @Input() showChangeProjectButton: boolean = true;
  @Input() person!: Person;
  @Input() allLegalEntityTypes!: LegalEntityType[];
  @Input() allFundTypes!: FundType[];
  @Input() allPersons: Person[] = [];
  @Input() project: Project | undefined;
  @Input() disablePersonCreation: boolean = false;
  @Input() contextFeature: Feature | undefined;

  @Output() fundManagerChanged = new EventEmitter<void>();
  @Output() addFundManager = new EventEmitter<void>();
  @Output() deletePerson = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Output() changeProjectCreation = new EventEmitter<void>();

  fundManager: Person | undefined; // details for `person.fund_manager_id` -- only if person.type == INVESTMENT_FUND
  PERSON_TYPE_LEGAL_PERSON = this.personService.getPersonTypeId('PERSON_TYPE_LEGAL_PERSON')!;
  PERSON_TYPE_NATURAL_PERSON = this.personService.getPersonTypeId('PERSON_TYPE_NATURAL_PERSON')!;
  PERSON_TYPE_INVESTMENT_FUND = this.personService.getPersonTypeId('PERSON_TYPE_INVESTMENT_FUND')!;
  PERSON_TYPE_GROUP = this.personService.getPersonTypeId('PERSON_TYPE_GROUP')!;

  selectedFundManager: Person | undefined;
  isCreatedFundManager: boolean = false;
  selectedPersonsGroup: Person[] | undefined;
  isPersonReviewed: boolean = false;
  isGroupPersonLocked: boolean = false;
  fieldMode: FieldMode = 'view';

  constructor(
    private personService: PersonService,
    private personGroupService: PersonGroupService,
    private cdr: ChangeDetectorRef,
    private settingsService: SettingsService,
    private taskService: TaskService
  ) {
    super();
  }

  override async ngOnInit() {
    super.ngOnInit();
    // Update the list of persons for a group when the personId is defined
    if (this.personService.isGroupPerson(this.person)) {
      this.selectedPersonsGroup =
        this.person.id !== undefined
          ? await this.taskService.do$(this.personGroupService.getPersonsByGroup$(this.person))
          : [];
      this.cdr.markForCheck();
      this.form.patchValue({ persons: this.selectedPersonsGroup });
    }
  }

  ngOnChanges() {
    this.fieldMode = {
      create: 'edit' as FieldMode,
      edit: 'edit' as FieldMode,
      view: 'view' as FieldMode,
      verification: 'view' as FieldMode,
    }[this.mode];
    this.isPersonReviewed = this.personService.isPersonReviewed(this.person);
    this.isGroupPersonLocked = this.personService.isGroupPersonLocked(this.person);
  }

  buildForm() {
    switch (this.person.person_type) {
      case this.PERSON_TYPE_NATURAL_PERSON:
        this.buildFormNaturalPerson();
        break;
      case this.PERSON_TYPE_LEGAL_PERSON:
        this.buildFormLegalPerson();
        break;
      case this.PERSON_TYPE_INVESTMENT_FUND:
        this.buildFormInvestmentFund();
        break;
      case this.PERSON_TYPE_GROUP:
        this.buildFormPersonGroup();
        break;
      default:
        throw new Error(`Unknown person type ${this.person.person_type}`);
    }
    this.form.controls['id'].disable();
    this.form.controls['projectCreation'].disable();
  }

  serializeForm() {
    switch (this.person.person_type) {
      case this.PERSON_TYPE_NATURAL_PERSON:
        return this.serializeFormNaturalPerson();
      case this.PERSON_TYPE_LEGAL_PERSON:
        return this.serializeFormLegalPerson();
      case this.PERSON_TYPE_INVESTMENT_FUND:
        return this.serializeFormInvestmentFund();
      case this.PERSON_TYPE_GROUP:
        return this.serializeFormPersonGroup();
    }
    throw new Error('Unknown person type');
  }

  onDeleteButtonClick() {
    this.deletePerson.emit();
  }

  onChangeProjectButtonClick() {
    this.changeProjectCreation.emit();
  }

  //
  // ----- Natural Person -----
  //

  private buildFormNaturalPerson(): void {
    this.form = this.fb.group({
      id: [this.person.id],
      projectCreation: [this.person.creationProject?.longName],
      last_name: [this.person.last_name, Validators.required],
      first_name: [this.person.first_name, Validators.required],
      short_name: [this.person.short_name],
      comment: [this.person.comment],
    });
  }

  private serializeFormNaturalPerson(): PersonFormResult {
    const formData = this.form.value;
    const person = this.person.clone();
    person.last_name = formData.last_name;
    person.first_name = formData.first_name;
    person.short_name = formData.short_name;
    person.comment = formData.comment;
    person.creationProject = this.project;

    return { person };
  }

  //
  // ----- Legal Person -----
  //

  private buildFormLegalPerson(): void {
    const isRegistrationPending =
      this.person.legal_entity_pending_registration ===
      this.settingsService.get('LEGAL_ENTITY_REGISTRATION_IS_PENDING');
    this.form = this.fb.group({
      id: [this.person.id],
      projectCreation: [this.person.creationProject?.longName],
      company_name: [this.person.company_name, Validators.required],
      short_name: [this.person.short_name],
      legal_entity_pending_registration: new UntypedFormControl(isRegistrationPending),
      legal_entity_country_code: new UntypedFormControl(
        { value: this.person.legal_entity_country_code, disabled: this.isPersonReviewed },
        Validators.required
      ),
      legal_entity_identifier: new UntypedFormControl(this.person.legal_entity_identifier, Validators.required),
      legal_entity_types_id: new UntypedFormControl(
        {
          value: this.person.legal_entity_types_id,
          disabled: this.isPersonReviewed,
        },
        Validators.required
      ),
      comment: [this.person.comment],
    });
    this.onRegistrationPendingChanged(isRegistrationPending);
  }

  onRegistrationPendingChanged(isRegistrationPending: boolean): void {
    if (isRegistrationPending) {
      this.form.get('legal_entity_identifier')?.setValue('');
      this.form.get('legal_entity_identifier')?.setValidators([]);
      this.form.get('legal_entity_identifier')?.disable();
    } else {
      this.form.get('legal_entity_identifier')?.setValidators([Validators.required]);
      this.form.get('legal_entity_identifier')?.enable();
    }
    this.form.get('legal_entity_identifier')?.updateValueAndValidity();
  }

  private serializeFormLegalPerson(): PersonFormResult {
    const formData = this.form.value;
    const person = this.person.clone();
    person.company_name = formData.company_name;
    person.short_name = formData.short_name;
    person.legal_entity_pending_registration = formData.legal_entity_pending_registration =
      this.settingsService.get<number>(
        formData.legal_entity_pending_registration
          ? 'LEGAL_ENTITY_REGISTRATION_IS_PENDING'
          : 'LEGAL_ENTITY_REGISTRATION_IS_NOT_PENDING'
      );
    person.legal_entity_country_code = formData.legal_entity_country_code;
    person.legal_entity_identifier = formData.legal_entity_identifier;
    person.legal_entity_types_id = Number(formData.legal_entity_types_id);
    person.comment = formData.comment;
    person.creationProject = this.project;

    return { person };
  }

  getEntityTypeName(entityTypeId: number): string | undefined {
    return (this.allLegalEntityTypes || []).find((entityType) => entityType.id === entityTypeId)?.name;
  }

  //
  // ----- Investment Fund -----
  //

  private buildFormInvestmentFund(): void {
    this.form = this.fb.group({
      id: [this.person.id],
      projectCreation: [this.person.creationProject?.longName],
      company_name: [this.person.company_name, Validators.required],
      short_name: [this.person.short_name],
      fund_types_id: new UntypedFormControl(
        { value: this.person.fund_types_id, disabled: this.isPersonReviewed },
        Validators.required
      ),
      fund_manager_id: new UntypedFormControl(
        { value: this.person.fund_manager_id, disabled: this.isPersonReviewed },
        Validators.required
      ),
      comment: [this.person.comment],
    });
  }

  async selectFundManager() {
    const selectedFundManagers = await lastValueFrom(
      this.personService.showFundManagerSelectorDialog(this.project, this.disablePersonCreation)
    );
    if (!!selectedFundManagers) {
      this.isCreatedFundManager =
        this.allPersons.find((person) => person.id === selectedFundManagers[0].id) === undefined;
      this.fundManager = selectedFundManagers[0];
      this.allPersons = this.isCreatedFundManager ? [...this.allPersons, this.fundManager] : this.allPersons;
      this.form.patchValue({ fund_manager_id: this.fundManager.id });
      this.form.markAsDirty();
      this.cdr.markForCheck();
    }
  }

  getFundTypeName(fundTypeId: number): string | undefined {
    return (this.allFundTypes || []).find((fundType) => fundType.id === fundTypeId)?.name;
  }

  getFundManager(fundManagerId: number | undefined) {
    return (this.allPersons || []).find((person) => person.id === fundManagerId);
  }

  private serializeFormInvestmentFund(): PersonFormResult {
    const formData = this.form.value;
    const person = this.person.clone();
    person.company_name = formData.company_name;
    person.short_name = formData.short_name;
    person.fund_types_id = Number(formData.fund_types_id);
    person.fund_manager_id = formData.fund_manager_id;
    person.comment = formData.comment;
    person.creationProject = this.project;

    return { person, fundManager: this.fundManager, isCreatedFundManager: this.isCreatedFundManager };
  }

  //
  // ----- Person Group  -----
  //

  private buildFormPersonGroup() {
    this.form = this.fb.group({
      id: [this.person.id],
      projectCreation: [this.person.creationProject?.longName],
      groupName: [this.person.company_name, Validators.required],
      groupShortName: [this.person.short_name, Validators.required],
      persons: [this.selectedPersonsGroup, Validators.required],
      comment: [this.person.comment],
    });
  }

  async selectPersonsForGroup() {
    if (this.isPersonReviewed === true || this.isGroupPersonLocked === true) {
      return;
    }

    const newPersons = await lastValueFrom(
      this.personService.showPersonSelectorDialog({
        project: this.project!,
        title: 'project.dialogPersonGroupSelector.title',
        selectedPersons: this.selectedPersonsGroup,
        description: 'project.dialogPersonGroupSelector.description',
        isMonoSelection: false,
        forceLargeWidth: true,
        closeAfterAdd: false,
        hideAddPersonButton: this.project === undefined,
        disableAddPersonButton: this.project === undefined,
        selectedItemsDescription: 'project.dialogPersonGroupSelector.itemsDescription',
        selectedItemsTitle: 'project.dialogPersonGroupSelector.itemsTitle',
        personTypeListAuthorized: [
          this.PERSON_TYPE_LEGAL_PERSON,
          this.PERSON_TYPE_NATURAL_PERSON,
          this.PERSON_TYPE_INVESTMENT_FUND,
        ],
      })
    );
    // newPersons can be undefined when no person has been selected
    // newPersons can be false when user leaves the dialog without saving
    if (newPersons === undefined || newPersons === false) {
      return;
    }
    if (this.mode === 'create') {
      this.selectedPersonsGroup = newPersons;
      this.updatePersons(newPersons);
      return;
    }
    const persons = this.selectedPersonsGroup || [];
    // List of persons to be added
    const personsToAdd = newPersons
      .filter((newPerson) => persons.findIndex((person) => person.id === newPerson.id) === -1)
      .map((person) => person.id);
    // List of persons to be removed
    const personsToRemove = persons
      .filter((person) => newPersons.findIndex((newPerson) => person.id === newPerson.id) === -1)
      .map((person) => person.id);
    // When one of the lists is not empty, we send the appropriate request
    if (personsToAdd.length !== 0) {
      await this.taskService.doSave$(this.personGroupService.addPersonsInPersonGroup$(this.person, personsToAdd));
    }
    if (personsToRemove.length !== 0) {
      await this.taskService.doSave$(
        this.personGroupService.removePersonsFromPersonGroup$(this.person, personsToRemove)
      );
    }
    // Update the form
    this.selectedPersonsGroup = newPersons;
    this.updatePersons(newPersons);
  }

  updatePersons(persons: Person[]) {
    this.form.markAsDirty();
    this.cdr.markForCheck();
    this.form.patchValue({ persons });
  }

  async removePersonFromPersonGroup(personId: number) {
    if (this.isPersonReviewed === true || this.isGroupPersonLocked === true) {
      return;
    }

    const response =
      this.mode === 'create'
        ? null
        : await this.taskService.doSave$(this.personGroupService.removePersonFromPersonGroup$(this.person, personId));
    if (response === null) {
      const persons = this.selectedPersonsGroup?.filter((person) => person.id !== personId);
      this.selectedPersonsGroup = persons;
      this.form.markAsDirty();
      this.form.patchValue({ persons });
      this.cdr.markForCheck();
    }
  }

  private serializeFormPersonGroup(): PersonFormResult {
    const formData = this.form.value;
    const person = this.person.clone();
    person.company_name = formData.groupName;
    person.short_name = formData.groupShortName;
    person.comment = formData.comment;
    person.legal_entity_identifier = undefined;
    person.legal_entity_country_code = undefined;
    person.personsInGroup = this.selectedPersonsGroup;
    person.creationProject = this.project;
    return { person };
  }
}
