/**
 * @file
 * Service to return a lightweight version of the project name and project folder.
 */
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { Folder, Project } from '../models';
import { FolderService2 } from './folder2.service';
import { ProjectService } from './project.service';

// TODO: à virer, c'est une catastrophe ce truc
@Injectable({ providedIn: 'root' })
export class ProjectAndFolderLightService {
  constructor(private projectService: ProjectService, private folderService: FolderService2) {}

  getProjectAndFolder$(opts: { projectId: number; folderId?: number }): Observable<{
    project: Project;
    folder?: Folder;
  }> {
    const getProject$: Observable<Project> = this.projectService.getById$(opts.projectId).pipe();
    const getFolder$: Observable<Folder | undefined> = opts.folderId
      ? this.folderService.getById$(opts.folderId)
      : of(undefined);

    return forkJoin({ project: getProject$, folder: getFolder$ });
  }
}
