/**
 * @file
 * A ProjectGoal is a frontend-specific entity representing the relation
 * between a project and a specific goal and one or several person(s).
 *
 * Do not confuse it with a `Goal` entity, which simply mirrors
 * the "goals" table from the backend.
 */
import * as _ from 'lodash';

import { ENTITY_STATUS, Entity, EntityDto } from '@app/common/models';
import { Goal, Goal2, GoalDto } from './goal.model';
import { Person } from './person.model';
import { RelProjectToGoal } from './rel-project-to-goal';

// Table "rel_projects_to_goals"
export interface RelProjectToGoalDto extends EntityDto {
  projects_id: number; // PK
  goals_id: number; // PK
  comment: string; // comment seen in "Goal Enricher" dialog
  _embedded: { goal: GoalDto };
}

// Table "rel_projects_to_goals_to_persons"
export interface RelProjectToGoalToPersonDto extends EntityDto {
  rel_project_to_goals_projects_id: number; // PK
  rel_project_to_goals_goals_id: number; // PK
  persons_id: number; // PK
}

interface RelProjectToGoalToPersonDtoWithRels extends RelProjectToGoalToPersonDto {
  goal: Goal;
  persons: Person[];
  comment: string;
}

export class ProjectGoal extends Entity {
  goal: Goal;
  persons: Person[];
  comment: string;

  constructor(opts: Partial<RelProjectToGoalToPersonDtoWithRels> = {}) {
    super(opts);
    this.goal = opts.goal!;
    this.persons = opts.persons || [];
    this.comment = opts.comment || '';
  }

  clone(opts: Partial<RelProjectToGoalToPersonDtoWithRels> = {}): ProjectGoal {
    const clone = new ProjectGoal({
      goal: opts.goal || this.goal,
      persons: opts.persons || [...this.persons],
      comment: opts.comment || this.comment,
    });
    return clone;
  }
}

//
// ----- Helper Functions
//

export function goalsToProjectGoals(goals: Goal[], projectGoals: ProjectGoal[] = []): ProjectGoal[] {
  return goals.map((goal) => {
    const existingProjectGoal = projectGoals.find((pGoal) => pGoal.goal.id === goal.id);
    return existingProjectGoal || projectGoalCreate(goal);
  });
}

function projectGoalCreate(goal: Goal, persons?: Person[], comment?: string): ProjectGoal {
  return new ProjectGoal({ goal, persons, comment });
}

export function goalsToRelProjectToGoals(goals: Goal2[], projectId: number): RelProjectToGoal[] {
  return goals.map((goal) => {
    const relProjectToGoal = new RelProjectToGoal();
    relProjectToGoal.projects_id = projectId;
    relProjectToGoal.goals_id = goal.id;
    relProjectToGoal.goal = goal;
    relProjectToGoal.status = ENTITY_STATUS.ACTIVE;
    return relProjectToGoal;
  });
}

export function projectGoalsToGoals(projectGoals: ProjectGoal[]): Goal[] {
  return projectGoals.map((projectGoal) => projectGoalToGoal(projectGoal));
}

function projectGoalToGoal(projectGoal: ProjectGoal): Goal {
  return projectGoal.goal;
}

export function projectGoalsPrepareForProject(projectGoals: ProjectGoal[]): ProjectGoal[] {
  projectGoals = _.sortBy(projectGoals, 'goal.order') as ProjectGoal[];
  return projectGoals.map((pGoal) => pGoal.clone({ persons: _.sortBy(pGoal.persons, 'name') }));
}
