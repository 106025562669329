import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { PartengHelper } from '@app/common/helpers';
import { TranslateModule } from '@ngx-translate/core';

@Component({  standalone: true, imports:[MatIconModule, TranslateModule, MatDialogModule, NgIf, NgClass, NgTemplateOutlet, MatButtonModule],
  selector: 'parteng-dialog',
  template: `
    <!-- Close Button -->
    <h2 mat-dialog-title align="end" class="px-5">
      <mat-icon
        aria-hidden="false"
        [attr.aria-label]="'shared.dialog.closeAriaLabel' | translate"
        class="text-neutral-500 cursor-pointer picto"
        (click)="safeClose.emit()"
        data-testId="dialog-close-button"
        >close</mat-icon
      >
    </h2>

    <!-- Dialog Scrollable Area -->
    <mat-dialog-content class="mat-typography-400">
      <div class="px-4">
        <!-- Dialog Title & Description -->
        <h2 class="pl-3 mb-3 border-l-[3px] border-blue-ptg-primary-800" *ngIf="title">
          {{ title }}
        </h2>
        <p class="mt-3 text-xs text-blue-ptg-primary font-semibold" *ngIf="subtitle">
          {{ subtitle }}
        </p>
        <div class="flex justify-between mt-4 mb-2" *ngIf="description">
          <div class="text-sm mb-2" *ngIf="description" [innerHTML]="description"></div>
          <div class="ml-7 min-w-max">
            <ng-content select=".add-item-button"></ng-content>
          </div>
        </div>
        <!-- Dialog Content -->
        <ng-content></ng-content>
      </div>
    </mat-dialog-content>

    <!-- Dialog Buttons (default buttons may be overridden with custom HTML) -->
    <mat-dialog-actions *ngIf="showDialogButtons && !customDialogActionsHTML" align="end" class="p-5">
      <div class="w-full" [ngClass]="showSelectButton ? 'grid gap-4 grid-cols-' + buttonsTotalCols : ''">
        <div *ngIf="showSelectButton" class="col-span-{{ buttonsCol1 }} text-right">
          <button
            mat-button
            type="button"
            (click)="unselectAllCheckboxes.emit()"
            [disabled]="isSelectBtnDisabled"
            data-testId="unselectAllCheckboxes-button"
          >
            {{ unselectAllBtnTranslateKey | translate }}
          </button>
          <button
            mat-stroked-button
            color="primary"
            type="button"
            (click)="selectItem.emit()"
            [disabled]="isSelectBtnDisabled"
            data-testId="select-button"
          >
            {{ selectBtnTranslateKey | translate }}
          </button>
        </div>
        <div class="text-right flex justify-end gap-10 col-span-{{ buttonsCol2 }}">
          <button type="button" mat-flat-button (click)="cancel.emit()" data-testId="dialog-cancel-button">
            {{ cancelBtnTranslateKey | translate }}
          </button>
          <button
            type="button"
            mat-raised-button
            color="primary"
            (click)="submit.emit()"
            [disabled]="isSubmitDisabled"
            data-testId="dialog-validate-button"
          >
            {{ submitBtnTranslateKey | translate }}
          </button>
        </div>
      </div>
    </mat-dialog-actions>
    <ng-container *ngIf="customDialogActionsHTML">
      <ng-container *ngTemplateOutlet="customDialogActionsHTML"></ng-container>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnInit {
  @Input() title!: string;
  @Input() description!: string;
  @Input() subtitle: string | undefined;
  @Input() headerHeight = 'auto'; // fixed header height in pixels, so dialog content height can be 100% - headerHeight
  @Input() showDialogButtons = true;
  @Input() isSubmitDisabled = false;
  @Input() showSelectButton = false;
  @Input() isSelectBtnDisabled = true;
  @Input() cancelBtnTranslateKey = 'shared.buttonLabels.cancel';
  @Input() submitBtnTranslateKey = 'shared.buttonLabels.validate';
  @Input() selectBtnTranslateKey = 'shared.buttonLabels.select';
  @Input() unselectAllBtnTranslateKey = 'shared.buttonLabels.unselectAll';

  @Input() customDialogActionsHTML!: TemplateRef<any>;

  @Input() dialogButtonColumnsSplitPoint: string = '4/6';

  @Output() safeClose = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit = new EventEmitter<void>();
  @Output() selectItem = new EventEmitter<void>();
  @Output() unselectAllCheckboxes = new EventEmitter<void>();

  buttonsCol1: number = 1;
  buttonsCol2: number = 1;
  buttonsTotalCols: number = 2;

  ngOnInit(): void {
    if (this.dialogButtonColumnsSplitPoint) {
      const buttonColsInfo = PartengHelper.processColumnsSplitPoint(this.dialogButtonColumnsSplitPoint);
      if (buttonColsInfo) {
        this.buttonsCol1 = buttonColsInfo.col1;
        this.buttonsCol2 = buttonColsInfo.col2;
        this.buttonsTotalCols = buttonColsInfo.totalCols;
      }
    }
  }
}
