/**
 * @file
 * Dialog used to a show a warning, with customizable text passed via MAT_DIALOG_DATA.
 *
 * Dialog returns `true` to proceed with the close, `false` to cancel the close.
 */
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DialogWarningComponent } from './dialog-warning.component';

export interface DialogWarningCustomTextData {
  dialogTitleTranslateKey: string;
  dialogDescriptionTranslateKey: string;
  isCriticalChoice: boolean;
}

@Component({  standalone: true, imports:[DialogWarningComponent, TranslateModule],
  selector: 'parteng-dialog-warning-custom-text',
  template: `
    <parteng-dialog-warning
      [title]="data.dialogTitleTranslateKey | translate"
      [text]="data.dialogDescriptionTranslateKey | translate"
      [isCriticalChoice]="data.isCriticalChoice"
    ></parteng-dialog-warning>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogWarningCustomTextComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogWarningCustomTextData) {}
}
