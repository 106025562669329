import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { HalApiService } from '@app/common/services/hal-api.service';
import { HALDeserializeFrom } from '@app/common/services/hal-serializer.service';
import { catchError, map, Observable, of, throwError } from 'rxjs';

class RawPermission {
  @HALDeserializeFrom()
  id: number = 0;

  @HALDeserializeFrom()
  route: string = '';
}

class Me {
  @HALDeserializeFrom('display_name')
  displayName: string = '';

  @HALDeserializeFrom('first_name')
  firstName: string = '';

  @HALDeserializeFrom('last_name')
  lastName: string = '';

  @HALDeserializeFrom('roles', String)
  roles: string[] = [];

  @HALDeserializeFrom('resources', RawPermission)
  rawPermissions: RawPermission[] = [];
}

@Injectable({ providedIn: 'root' })
export class PermissionService {
  private roles: string[] = [];

  constructor(private halApiService: HalApiService, private authenticationService: AuthenticationService) {}

  loadPermissions$(): Observable<string[]> {
    return this.halApiService.getOne$<Me>(Me, `/me`, {}).pipe(
      map((me) => (this.roles = me.roles)),
      // To keep?
      // We assume the back would give us a 401 when disconnected.
      // Unfortunately, on this specific route, the back sends back a 403, which says :
      // "you don't the permission to know your own permissions" (?!).
      // So, for now, we force the login, preventing the user to have to delete manually the local storage.
      catchError((err) => {
        if (err.status === HttpStatusCode.Forbidden) {
          this.authenticationService.forceLogin();
          return of([]);
        }
        return throwError(() => err);
      })
    );
  }

  getRoles() {
    return this.roles;
  }
}
