import { Pipe, PipeTransform } from '@angular/core';
import { Folder } from '@app/project/models';
import { FolderService2 } from '@app/project/services/folder2.service';

@Pipe({  standalone: true,
  name: 'folderValidationStatusIcon',
})
export class FolderValidationStatusIconPipe implements PipeTransform {
  constructor(private folderService: FolderService2) {}
  transform(folder: Folder): string {
    switch (true) {
      case this.folderService.isFolderEmpty(folder):
        return 'folder_open';
      case this.folderService.isFolderNotReviewed(folder):
        return 'folder';
      case this.folderService.isFolderReviewed(folder):
        return 'lock_outline';
      case this.folderService.isFolderValidated(folder):
        return 'https';
      default:
        throw new Error('Unknown folder state/status');
    }
  }
}
