import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({  standalone: true, imports:[TranslateModule, MatTooltipModule, MatIconModule],
  selector: 'parteng-reset-filters-button',
  template: `<div class="border border-blue-ptg-primary-300 rounded-[4px] h-12 p-1 flex items-center">
    <button
      mat-icon-button
      (click)="resetFilters.emit()"
      [attr.aria-label]="'shared.resetFiltersAriaLabel' | translate"
      [matTooltip]="'shared.resetFiltersAriaLabel' | translate"
    >
      <mat-icon aria-hidden="false" class="text-blue-ptg-primary-500 picto">restart_alt</mat-icon>
    </button>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetFiltersButtonComponent {
  @Output() resetFilters = new EventEmitter<void>();
}
