/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HALResource } from '@app/common/models';
import { PartengApiService, RestService, SerializerService } from '@app/common/services';
import { GoalDto, StandardFolderDto } from '../models';
import { ProjectTemplate, ProjectTemplateDto } from '../models/project-template.model';
import { GoalSerializerService } from './goal.service';
import { StandardFolderSerializerService } from './standard-folder.service';

@Injectable({ providedIn: 'root' })
class ProjectTemplateSerializerService extends SerializerService<ProjectTemplate, ProjectTemplateDto> {
  constructor(
    private goalSerializer: GoalSerializerService,
    private standardFolderSerializerService: StandardFolderSerializerService
  ) {
    super();
  }

  /**
   * Deserialize a project template from GET /project-templates?sets=full
   *
   *   {
   *     id: 2,
   *     name: "Acquisition De Titres",
   *     ...
   *     _embedded: {
   *       project_templates_to_goals: [
   *         { project_templates_id: 2: goals_id: 24, goal: GOAL_24 },
   *         { project_templates_id: 2: goals_id: 25, goal: GOAL_25 },
   *       ],
   *       project_templates_to_standard_folders: [
   *         { project_templates_id: 2: standard_folders_id: 15, standard_folder: SF_15 },
   *       ],
   *     }
   *    }
   */
  fromDto(
    projectTemplateDto: HALResource<
      ProjectTemplateDto & {
        _embedded: {
          project_templates_to_goals: { _embedded: { goal: GoalDto } }[];
          project_templates_to_standard_folders: { _embedded: { standard_folder: StandardFolderDto } }[];
        };
      }
    >
  ): ProjectTemplate {
    // process related goals
    const goals =
      projectTemplateDto._embedded &&
      projectTemplateDto._embedded.project_templates_to_goals &&
      Array.isArray(projectTemplateDto._embedded.project_templates_to_goals)
        ? projectTemplateDto._embedded.project_templates_to_goals.map((rel_project_template_to_goal) =>
            this.goalSerializer.fromDto(rel_project_template_to_goal._embedded.goal)
          )
        : [];

    // process related standard folders
    const standardFolders =
      projectTemplateDto._embedded &&
      projectTemplateDto._embedded.project_templates_to_standard_folders &&
      Array.isArray(projectTemplateDto._embedded.project_templates_to_standard_folders)
        ? projectTemplateDto._embedded.project_templates_to_standard_folders.map(
            (rel_project_template_to_standard_folder) =>
              this.standardFolderSerializerService.fromDto(
                rel_project_template_to_standard_folder._embedded.standard_folder
              )
          )
        : [];

    return new ProjectTemplate({ ...projectTemplateDto, goals, standardFolders });
  }

  toDto(entity: ProjectTemplate): any {
    return {
      ...this.getDtoBaseProps(entity),
      name: entity.name,
      project_name_template: entity.project_name_template,
      order: entity.order,
      comment: entity.comment!,
    };
  }
}

@Injectable({ providedIn: 'root' })
export class ProjectTemplateService extends PartengApiService<ProjectTemplate, HALResource<ProjectTemplateDto>> {
  constructor(rest: RestService, serializer: ProjectTemplateSerializerService) {
    super(rest, serializer, '/project-templates', 'project_templates');
  }

  getAll$(): Observable<ProjectTemplate[]> {
    return this.getCollection$({ queryParams: { sets: 'full' } }).pipe(
      map((projectTemplates) => _.sortBy(projectTemplates, 'order'))
    );
  }
}
