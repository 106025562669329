import { EntityWithId } from '@app/common/models';
import { HALDeserializeFrom, HALOptional, HALSerializeTo } from '@app/common/services/hal-serializer.service';
import { InstrumentType } from './instrument-type.model';
import { InstrumentVersion } from './instrument-version.model';

export class Instrument extends EntityWithId {
  @HALDeserializeFrom()
  @HALSerializeTo()
  @HALOptional()
  override status: number = 0;

  @HALDeserializeFrom()
  override id: number = 0;

  @HALDeserializeFrom()
  name: string = '';

  @HALDeserializeFrom('instrument_types_id')
  @HALSerializeTo('instrument_types_id')
  instrumentTypeId: number = 0;

  @HALDeserializeFrom('instrument_versions', InstrumentVersion)
  @HALSerializeTo('instrument_versions')
  instrumentVersions?: InstrumentVersion[] = [];

  @HALDeserializeFrom('instrument_type')
  @HALOptional()
  instrumentType?: InstrumentType;

  @HALDeserializeFrom()
  @HALSerializeTo()
  comment: string = '';
}
