export enum PSEUDO_FOLDER {
  CREATED_PERSONS = 'CREATED_PERSONS',
  REFERENCED_PERSONS = 'REFERENCED_PERSONS',
  UPDATED_INSTRUMENTS = 'UPDATED_INSTRUMENTS',
  REFERENCED_INSTRUMENTS = 'REFERENCED_INSTRUMENTS',
  ORPHANED_INSTRUMENTS = 'ORPHANED_INSTRUMENTS',
  PROJECT_STRUCTURE = 'PROJECT_STRUCTURE',
  CAPITALIZATION_TABLES = 'CAPITALIZATION_TABLES',
  ALL_FOLDERS = 'ALL_FOLDERS', // TODO: à renommer en ALL_TRANSFERS_FOLDER
}
