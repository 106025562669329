import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PSEUDO_FOLDER } from '../models';
import { AccessModeVersion } from '../models/instrument-version.model';

@Injectable({ providedIn: 'root' })
export class DataEntryNavigationService {
  private window: Window & typeof globalThis;
  private host = '';
  private protocol = '';

  constructor(
    @Inject(APP_BASE_HREF) private baseHref: string,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView as NonNullable<Window & typeof globalThis>;
    // Because of environments, baseHref might be undefined, an empty string, a single slash, etc.
    this.baseHref = this.baseHref === undefined ? '' : this.baseHref;
    this.host = this.document.location.host;
    this.protocol = this.document.location.protocol;
  }

  goToDataEntryDashboard(projectId: number, { consultation }: { consultation: boolean } = { consultation: false }) {
    this.router.navigate(this.getDataEntryDashboardPath(projectId, { consultation }));
  }

  getDataEntryDashboardPath(projectId: number, { consultation }: { consultation: boolean } = { consultation: false }) {
    return ['/', consultation === true ? 'consultation' : 'data-entry', 'projects', projectId];
  }

  goToInstrument(
    instrumentId: number,
    projectId = 0,
    { consultation, accessModeVersion }: { consultation?: boolean; accessModeVersion?: AccessModeVersion } = {
      consultation: false,
    }
  ) {
    this.router.navigate(this.getInstrumentPath(instrumentId, projectId, { consultation }), {
      queryParams: {
        accessModeVersion,
      },
    });
  }

  goToInstrumentInOtherTab(
    instrumentId: number,
    projectId = 0,
    { consultation, accessModeVersion }: { consultation?: boolean; accessModeVersion?: AccessModeVersion } = {
      consultation: false,
    }
  ) {
    const url = this.router
      .createUrlTree(this.getInstrumentPath(instrumentId, projectId, { consultation }), {
        queryParams: {
          accessModeVersion,
        },
      })
      .toString();
    this.window.open(`${this.protocol}//${this.host}${this.baseHref}${url}`, '_blank');
  }

  getInstrumentPath(
    instrumentId: number,
    projectId = 0,
    { consultation }: { consultation?: boolean } = {
      consultation: false,
    }
  ) {
    return [
      '/',
      consultation === true ? 'consultation' : 'data-entry',
      'projects',
      projectId,
      'instruments',
      instrumentId,
    ];
  }

  goToUpdatedInstrumentsList(projectId: number, { consultation }: { consultation: boolean } = { consultation: false }) {
    return this.router.navigate([
      '/',
      consultation === true ? 'consultation' : 'data-entry',
      'projects',
      projectId,
      'folders',
      PSEUDO_FOLDER.UPDATED_INSTRUMENTS,
      'instruments',
      'list',
    ]);
  }

  goToInstrumentsReferentialList() {
    this.router.navigate(['instruments', 'list']);
  }

  goToInstrumentCreation(projectId: number, { copyFrom }: { copyFrom?: number }) {
    return this.router.navigate(['/', 'data-entry', 'projects', projectId, 'instruments', 'create'], {
      queryParams: { copyFrom },
    });
  }

  goToInstrumentVersion(
    instrumentVersionId: number,
    instrumentId: number,
    projectId: number = 0,
    { consultation, accessModeVersion }: { consultation?: boolean; accessModeVersion?: AccessModeVersion } = {
      consultation: false,
    }
  ) {
    this.router.navigate(
      [
        '/',
        consultation === true ? 'consultation' : 'data-entry',
        'projects',
        projectId,
        'instruments',
        instrumentId,
        'versions',
        instrumentVersionId,
        'update',
      ],
      {
        queryParams: {
          accessModeVersion,
        },
      }
    );
  }

  goToInstrumentVersionInOtherTab(
    instrumentVersionId: number,
    instrumentId: number,
    projectId: number = 0,
    { consultation, accessModeVersion }: { consultation?: boolean; accessModeVersion?: AccessModeVersion } = {
      consultation: false,
    }
  ) {
    const url = this.router
      .createUrlTree(
        [
          '/',
          consultation === true ? 'consultation' : 'data-entry',
          'projects',
          projectId,
          'instruments',
          instrumentId,
          'versions',
          instrumentVersionId,
          'update',
        ],
        {
          queryParams: {
            accessModeVersion,
          },
        }
      )
      .toString();
    this.window.open(`${this.protocol}//${this.host}${this.baseHref}${url}`, '_blank');
  }
}
