/**
 * @file
 * Dialog shown to the user when trying to close a dialog with pending changes.
 *
 * Dialog returns `true` to proceed with the close, `false` to cancel the close.
 */
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DialogWarningComponent } from './dialog-warning.component';

type truc = { dialogLeaveWarningTextTranslateKey: string | undefined };

@Component({  standalone: true, imports:[DialogWarningComponent, TranslateModule],
  selector: 'parteng-dialog-close-warning',
  template: `
    <parteng-dialog-warning
      data-testId="parteng-dialog-close-warning"
      [title]="'shared.dialogSafeExitCreate.title' | translate"
      [text]="dialogLeaveWarningTextTranslateKey || 'shared.dialogSafeExitCreate.text' | translate"
    ></parteng-dialog-warning>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogCloseWarningComponent {
  dialogLeaveWarningTextTranslateKey: string | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) data: { dialogLeaveWarningTextTranslateKey: string | undefined }) {
    this.dialogLeaveWarningTextTranslateKey = data?.dialogLeaveWarningTextTranslateKey;
  }
}
