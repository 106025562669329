import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JsHelper } from '@app/common/helpers';
import { TableColumnDef } from '@app/common/models';
import { FillEmptyPipe } from '@app/common/pipes/fill-empty.pipe';
import { ScopeWorldsPipe } from '@app/common/pipes/scope-worlds.pipe';
import { Scope } from '@app/project/models';
import { ScopeService } from '@app/project/services/scope.service';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, lastValueFrom, map, of } from 'rxjs';
import { DialogItemSelectorComponent } from '../dialog-item-selector/dialog-item-selector.component';

export interface DialogScopeSelectorData {
  dialogTitle: string;
  dialogDescription: string;
  selectedItemsTitle: string;
  selectedItemsDescription: string;
  itemAdditionalInfoTitle: string;
  selectedScopes: Scope[];
  hideAddItemButton: boolean;
  isMonoSelection: boolean;
}

@Component({
  standalone: true,
  imports: [
    DialogItemSelectorComponent,
    AsyncPipe,
    FillEmptyPipe,
    TranslateModule,
    ScopeWorldsPipe,
    NgIf,
    MatButtonModule,
  ],
  selector: 'parteng-dialog-scope-selector-shared',
  template: `
    <section class="dialog-scope-selector-shared">
      <parteng-dialog-item-selector
        *ngIf="scopes$ | async as scopes"
        [dialogTitle]="dialogData.dialogTitle"
        [dialogDescription]="dialogData.dialogDescription"
        [selectedItemsTitle]="dialogData.selectedItemsTitle"
        [selectedItemsDescription]="dialogData.selectedItemsDescription"
        [itemAdditionalInfoTitle]="dialogData.itemAdditionalInfoTitle"
        [itemAdditionalInfoHTML]="itemAdditionalInfoHTML"
        [selectedItemPreviewHTML]="selectedItemPreviewHTML"
        [columnDefs]="columnDefs"
        [allItems]="scopes"
        [defaultSelectedItems]="dialogData.selectedScopes"
        [filterItemFn]="filterItemFn"
        itemsSortProperty="code"
        [hideAddItemButton]="dialogData.hideAddItemButton"
      >
        <!-- Additional Info -->
        <ng-template #itemAdditionalInfoHTML let-scope="item">
          <p class="text-sm text-neutral-700">
            {{ scope.comment | fillEmpty }}<br />
            <span class="font-bold">{{ 'scopes.pageScopesList.detailsAreaWorlds' | translate }} :</span>
            {{ scope | scopeWorlds }}
          </p>
        </ng-template>
        <!-- Selected Item Preview -->
        <ng-template #selectedItemPreviewHTML let-scope="item">
          <div class="flex text-sm gap-2">
            <div>{{ scope.code }}</div>
            <div>{{ scope.name }}</div>
          </div>
        </ng-template>
        <!-- Add Item Button -->
        <button mat-stroked-button color="primary" type="button" (click)="openAddScopeDialog()">
          {{ 'project.dialogScopeSelector.createItem' | translate }}
        </button>
      </parteng-dialog-item-selector>
    </section>
  `,
})
export class DialogScopeSelectorSharedComponent implements OnInit {
  @ViewChild(DialogItemSelectorComponent) dialogItemSelectorComponent!: DialogItemSelectorComponent<Scope>;

  scopes$: Observable<Scope[]> = of([]);

  columnDefs: TableColumnDef[] = [
    { key: 'code', labelTranslateKey: 'project.dialogScopeSelector.columnCode' },
    { key: 'name', labelTranslateKey: 'project.dialogScopeSelector.columnName' },
    { key: 'historicalName', labelTranslateKey: 'project.dialogScopeSelector.columnHistoricalNames' },
    { key: 'city', labelTranslateKey: 'project.dialogScopeSelector.columnCity' },
  ];

  constructor(
    private scopeService: ScopeService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogScopeSelectorData
  ) {}

  async ngOnInit(): Promise<void> {
    this.refreshList();
  }

  filterItemFn(item: Scope, filter: string) {
    return JsHelper.ObjPropsContainString(item, filter, ['code', 'name', 'historicalName', 'city', 'comment']);
  }

  async openAddScopeDialog() {
    const createdScope = await lastValueFrom(this.scopeService.showScopeDialog({ mode: 'create' }));
    if (createdScope) {
      this.dialogItemSelectorComponent.selectItem(createdScope);
      this.refreshList();
    }
  }

  async refreshList() {
    // Note. Scopes are already sorted in getAll$()
    const selectedScopeIds = [
      ...(this.dialogData.selectedScopes ? this.dialogData.selectedScopes.map((s) => s.id) : []),
      ...(this.dialogItemSelectorComponent?.selectedItems || []).map((s) => s.id),
    ];
    this.scopes$ = of(
      (await lastValueFrom(
        this.scopeService
          .getAll$()
          .pipe(
            map((scopes) => scopes.filter((s) => !(this.dialogData.isMonoSelection && selectedScopeIds.includes(s.id))))
          )
      )) || []
    );
  }
}
