import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'parteng-access-denied',
  template: `
    <div class="text-center mt-10">
      <h1>
        {{ 'auth.accessDenied' | translate }}
      </h1>
      <div class="mt-8">
        <button mat-raised-button color="primary" (click)="goToHomePage()">{{ 'auth.clickHere' | translate }}</button>
      </div>
    </div>
  `,
})
export class AccessDeniedComponent {
  constructor(private router: Router) {}

  goToHomePage() {
    this.router.navigateByUrl('/');
  }
}
