import { NgClass, NgForOf, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { AngularMaterialModule } from '@app/common/angular-material.module';
import { AbstractFormComponent } from '@app/common/components';
import { CanEditFeatureDirective } from '@app/common/directives/can-edit-feature.directive';
import { JsHelper } from '@app/common/helpers';
import { Feature } from '@app/common/models/role.model';
import { FillEmptyPipe } from '@app/common/pipes/fill-empty.pipe';
import { ScopeWorldsPipe } from '@app/common/pipes/scope-worlds.pipe';
import { Scope, World } from '@app/project/models';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorMessageBlockComponent } from '../error-block.component';

@Component({  standalone: true, imports:[NgIf, TranslateModule, FillEmptyPipe, ScopeWorldsPipe, AngularMaterialModule, CanEditFeatureDirective, ReactiveFormsModule, NgClass, ErrorMessageBlockComponent, NgForOf],
  selector: 'parteng-scope-form2',
  templateUrl: './scope-form2.component.html',
  styles: [
    `
      .field {
        @apply my-4;
      }
      .label {
        @apply text-blue-ptg-primary text-xs mr-3;
      }
      .value {
        @apply text-black-ptg font-bold text-sm;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScopeForm2Component extends AbstractFormComponent<Scope> implements OnChanges {
  @Input() scope!: Scope;
  @Input() worlds!: World[];
  @Input() mode!: 'create' | 'view' | 'edit';

  @Output() modeChanged = new EventEmitter<'create' | 'view' | 'edit'>();

  Feature = Feature;
  isWorldsFieldReady = false;

  get isWorldsInvalid(): boolean {
    const control = this.form.get('worlds');
    return control ? control.dirty && control.invalid : false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['worlds'] && !changes['worlds'].firstChange && this.worlds) {
      this.setupWorldsField();
    }
  }

  buildForm(): void {
    // NB. The "worlds" field is added separately, after the async "worlds" input is received.
    this.form = this.fb.group({
      code: [this.scope.code, Validators.required],
      name: [this.scope.name, Validators.required],
      historical_name: [this.scope.historicalName],
      city: [this.scope.city],
      comment: [this.scope.comment],
    });
  }

  private setupWorldsField(): void {
    const worldsFormGroupConfig: { [k: string]: any } = {};
    for (const world of this.worlds) {
      const isWorldSelected = this.scope.worlds.some((w) => w.id === world.id);
      worldsFormGroupConfig[`${world.id}`] = [isWorldSelected];
    }
    this.form.addControl(
      'worlds',
      this.fb.group(worldsFormGroupConfig, { validators: atLeastOneWorldSelectedValidator() })
    );
    this.isWorldsFieldReady = true;
  }

  serializeForm(): Scope {
    const formData = this.form!.value;

    // Process worlds
    const worldIds = JsHelper.objGetKeysTrue(formData.worlds).map(Number);
    const worlds = worldIds.map((id) => this.worlds.find((w) => w.id === id)!);

    const scope = new Scope();
    scope.id = this.scope.id;
    scope.status = this.scope.status;
    scope.code = `${formData.code}`.trim();
    scope.name = (formData.name || '').trim();
    scope.historicalName = (formData.historical_name || '').trim();
    scope.city = (formData.city || '').trim();
    scope.comment = (formData.comment || '').trim();
    scope.worlds = worlds;

    return scope;
  }

  switchToEditMode(): void {
    this.modeChanged.emit('edit');
  }
}

//
//
//

function atLeastOneWorldSelectedValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const error = !!control.value && JsHelper.objGetKeysTrue(control.value).length === 0;
    return error ? { noWorldSelected: true } : null;
  };
}
