import { EntityWithId, EntityWithIdDto } from '@app/common/models';
import { HALDeserializeFrom, HALSerializeTo } from '@app/common/services/hal-serializer.service';
import { RelScopeToWorld } from './rel-scope-to-world';
import { World, World2 } from './world.model';

export class Scope extends EntityWithId {
  @HALDeserializeFrom()
  @HALSerializeTo()
  override status: number = 0;

  @HALDeserializeFrom()
  override id: number = 0;

  @HALDeserializeFrom()
  @HALSerializeTo()
  code: string = '';

  @HALDeserializeFrom()
  @HALSerializeTo()
  name: string = '';

  @HALDeserializeFrom('historical_name')
  @HALSerializeTo('historical_name')
  historicalName: string = '';

  @HALDeserializeFrom()
  @HALSerializeTo()
  city: string = '';

  @HALDeserializeFrom()
  @HALSerializeTo()
  comment: string = '';

  @HALDeserializeFrom('rel_scopes_to_worlds', RelScopeToWorld)
  relScopeToWorlds: RelScopeToWorld[] = [];

  worlds: World2[] = [];

  // Use this to modify a scope from the store (cause store data is immutable)
  clone(
    opts: Partial<{
      id: number;
      status: number;
      code: string;
      name: string;
      historicalName: string;
      city: string;
      comment: string;
    }> = {}
  ): Scope {
    const clone = new Scope();

    clone.id = opts.id !== undefined ? opts.id : this.id;
    clone.status = opts.status !== undefined ? opts.status : this.status;

    clone.code = opts.code !== undefined ? opts.code : this.code;
    clone.name = opts.name !== undefined ? opts.name : this.name;
    clone.historicalName = opts.historicalName !== undefined ? opts.historicalName : this.historicalName;
    clone.city = opts.city !== undefined ? opts.city : this.city;
    clone.comment = opts.comment !== undefined ? opts.comment : this.comment;

    // Some props can't be updated.
    clone.created = this.created;
    clone.updated = this.updated;
    clone.relScopeToWorlds = this.relScopeToWorlds;
    clone.worlds = this.worlds;

    return clone;
  }
}

export interface ScopeDto extends EntityWithIdDto {
  code: string;
  name: string;
  historical_name: string;
  city: string;
  comment: string;
}

export interface ScopeDtoWithRels extends ScopeDto {
  worlds: World[];
}

export class ScopeDEPRECATED extends EntityWithId {
  code: string; // required
  name: string; // required
  historical_name: string | undefined;
  city: string | undefined;
  comment: string | undefined;

  worlds: World[];

  constructor(opts: Partial<ScopeDtoWithRels> = {}) {
    super(opts);

    this.code = opts.code!;
    this.name = opts.name!;
    this.historical_name = opts.historical_name;
    this.city = opts.city;
    this.comment = opts.comment;

    this.worlds = opts.worlds || [];
  }

  clone(opts: Partial<ScopeDtoWithRels> = {}) {
    const clone = new ScopeDEPRECATED({
      ...this.cloneEntityProps(opts),

      code: opts.code || this.code,
      name: opts.name || this.name,
      historical_name: opts.historical_name || this.historical_name,
      city: opts.city || this.city,
      comment: opts.comment || this.comment,

      worlds: opts.worlds || [...this.worlds],
    });
    return clone;
  }
}
