import { HALDeserializeFrom } from '@app/common/services/hal-serializer.service';
import { Goal2 } from './goal.model';
import { Person } from './person.model';

export class RelProjectToGoal {
  @HALDeserializeFrom()
  projects_id: number = 0;

  @HALDeserializeFrom()
  goals_id: number = 0;

  @HALDeserializeFrom()
  goal!: Goal2;

  @HALDeserializeFrom()
  status: number = 0;

  @HALDeserializeFrom()
  comment: string = '';

  // Cannot be embedded in the JSON
  // Must be populated via a separate API call
  persons: Person[] = [];
}
