import { State } from '@app/common/store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { DialogItemSelectorState } from '../../common/components/dialog-item-selector/dialog-item-selector.component';
import { Person } from '../models';
import { selectProjectModuleState } from './index';
import * as fromProjectForm from './project-form.reducers';

const selectProjectFormFeatureState: MemoizedSelector<State, fromProjectForm.State> = createSelector(
  selectProjectModuleState,
  (state) => state.projectForm
);

//
// ---------- Projects List
//

export const selectProjectsListState = createSelector(selectProjectFormFeatureState, (state) => ({
  allProjects: state.projectsList.allProjects,
  isLoading: state.projectsList.isLoading,
  highlightedProject: state.projectsList.highlightedProject,
}));

//
// ---------- Project Form
//

export const selectProjectFormState = createSelector(selectProjectFormFeatureState, (state) => ({
  project: state.projectForm.project,
  isNew: state.projectForm.isNew,
  projectTemplates: state.db.allTemplates,
  template: state.db.allTemplates.find((t) => t.id === state.projectForm.templateId),
  allStandardFolders: state.db.allStandardFolders,
  allProjectTypes: state.db.allProjectTypes,
  formSectionsState: state.projectForm.formSectionsState,
}));

export const selectProject = createSelector(selectProjectFormState, (formState) => formState.project);

export const selectProjectLongName = createSelector(selectProject, (project) =>
  project === undefined ? '' : project.longName
);

//
// ---------- Goal Selector
//

export const selectDialogGoalSelectorState = createSelector(selectProjectFormFeatureState, (state) => ({
  allGoals: state.db.allGoals,
  selectedGoals: state.projectForm.project.relProjectToGoals.map((rel) => rel.goal),
  // selectedGoals: projectGoalsToGoals(state.dialogGoalSelectorAndEnricher.projectGoals),
}));

//
// ---------- Goal Enricher
//

// -------------------- Person for Goal Form
export const selectPersonForGoalFormState = createSelector(selectProjectFormFeatureState, (state) => ({
  person: state.dialogPersonForGoalForm.person,
  fundManager: state.dialogPersonForGoalForm.fundManager,
  // supporting data
  allLegalEntityTypes: state.db.allLegalEntityTypes,
  allFundTypes: state.db.allFundTypes,
}));

//
// ---------- Fund Manager
//

// -------------------- Fund Manager Selector
export const selectDialogFundManagerSelectorState: MemoizedSelector<
  State,
  DialogItemSelectorState<Person>
> = createSelector(selectProjectFormFeatureState, (state) => ({
  allItems: state.dialogFundManagerSelector.legalPersons,
  selectedItems: state.dialogFundManagerSelector.selectedLegalPerson
    ? [state.dialogFundManagerSelector.selectedLegalPerson]
    : [],
  newItem: state.dialogFundManagerSelector.newLegalPerson,
  // dialog customisations
  forEntityName: '',
  dialogTitleTranskateKey: '',
  hideAddItemButton: state.defaults.hideAddItemButtonInItemSelector,
}));

// -------------------- Fund Manager Form
export const selectFundManagerFormState = createSelector(selectProjectFormFeatureState, (state) => ({
  person: state.dialogFundManagerForm.person,
  // supporting data
  allLegalEntityTypes: state.db.allLegalEntityTypes,
  allFundTypes: state.db.allFundTypes,
}));
