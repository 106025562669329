import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AngularMaterialModule } from '@app/common/angular-material.module';
import { ProjectTypeNamePipe } from '@app/common/pipes/project-type-name.pipe';
import { Project } from '@app/project/models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    AngularMaterialModule,
    TranslateModule,
    ProjectTypeNamePipe,
  ],
  standalone: true,
  selector: 'parteng-projects-list-highlighted-project',
  template: `
    <div class="list-detail min-h-[95px]" [ngClass]="{ selected: !!project }">
      <h3 class="mb-2 text-xs text-neutral-400">{{ 'project.pageProjectsList.additionalInfo.title' | translate }}</h3>
      <ng-container *ngIf="project">
        <p>
          <ng-container *ngIf="project.comment; else noComment">
            <!-- prettier-ignore -->
            <div class="whitespace-pre-line break-all">{{project.comment}}</div>
          </ng-container>
          <ng-template #noComment>-</ng-template>
        </p>
        <div class="mb-2 text-xs hidden">
          <span class=" text-neutral-400" data-testId="project-type-label">{{
            'project.pageProjectsList.additionalInfo.projectType' | translate
          }}</span>
          <span data-testId="project-type-value">
            {{ project.projectTypeId | projectTypeNamePipe }}
          </span>
        </div>
        <h3 class="mb-2 text-xs text-neutral-400">
          {{ 'project.pageProjectsList.additionalInfo.goalsAndPeople' | translate }}
        </h3>

        <div class="grid grid-cols-4 gap-2">
          <div
            *ngFor="let relProjectToGoal of project.relProjectToGoals"
            class="border-l border-blue-ptg-primary-500 pl-2 pr-6"
          >
            <span class="font-semibold">{{ relProjectToGoal.goal.name }}</span
            ><br />
            <span *ngFor="let person of relProjectToGoal.persons; let isLast = last">
              {{ person.name }}<ng-container *ngIf="!isLast">, </ng-container>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsListHighlightedProjectComponent {
  @Input() project: Project | undefined;
}
