import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DIALOG_WIDTH_LARGE } from '@app/common/common.constants';
import { HALResource, QueryParams } from '@app/common/models';
import { ConfigService, PartengApiService, RestService } from '@app/common/services';
import { SettingsService } from '@app/data-entry/services/settings.service';
import { Folder } from '@app/project/models';
import { Observable, firstValueFrom, forkJoin, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { ProjectAndFolderLightService } from '../../../project/services/project-and-folder-light.service';
import { DialogMoveTransferComponent } from '../components/dialog-move-transfer/dialog-move-transfer.component';
import { Transfer, TransferDto, TransferSetup } from '../models';
import { TransferInstrumentService } from './transfer-instrument.service';
import { TransferPersonService } from './transfer-person.service';
import { TransferSerializerService } from './transfer-serializer.service';
import { TransferSetupService } from './transfer-setup.service';

@Injectable({ providedIn: 'root' })
export class TransferService extends PartengApiService<Transfer, HALResource<TransferDto>> {
  private _transferSetups!: TransferSetup[];

  constructor(
    rest: RestService,
    serializer: TransferSerializerService,
    private projectAndFolderLightService: ProjectAndFolderLightService,
    private transferSetupService: TransferSetupService,
    private transferInstrumentService: TransferInstrumentService,
    private transferPersonService: TransferPersonService,
    private config: ConfigService,
    private dialog: MatDialog,
    private settingsService: SettingsService
  ) {
    super(rest, serializer, '/transfers', 'transfers');
  }

  getAll$(opts: { queryParams?: QueryParams } = {}): Observable<Transfer[]> {
    return this.getCollection$({ queryParams: { sets: 'full', ...opts.queryParams } });
  }

  getByFolderId$(folderId: number): Observable<Transfer[]> {
    return this.getAll$({ queryParams: { folders_id: `${folderId}` } });
  }

  getByProjectId$(projectId: number): Observable<Transfer[]> {
    return this.getAll$({ queryParams: { projects_id: `${projectId}` } });
  }

  override getById$(
    id: number,
    opts: { projectId: number; createDuplicate?: boolean; queryParams?: QueryParams }
  ): Observable<Transfer> {
    const createDuplicate = opts.createDuplicate ?? false;

    return super.getById$(id, opts).pipe(
      mergeMap((transfer) => {
        const getTransferSetupAndType$ = this.transferSetupService.getById$(transfer.setup_transfers_id).pipe(
          map((transferSetup) => {
            const transferTypes = this.config.getAllTransferTypes();
            const transferType = transferTypes.find((tt) => tt.id === transferSetup.transfer_types_id);
            return { transferSetup, transferType };
          })
        );

        return forkJoin({
          projectAndFolderLight: this.projectAndFolderLightService.getProjectAndFolder$({
            projectId: opts.projectId,
            folderId: transfer.folders_id,
          }),
          transferSetupAndType: getTransferSetupAndType$,
        }).pipe(
          map(({ projectAndFolderLight, transferSetupAndType }) =>
            transfer.clone({
              parentProject: projectAndFolderLight.project,
              parentFolder: projectAndFolderLight.folder,
              transferSetup: transferSetupAndType.transferSetup,
              transferType: transferSetupAndType.transferType,
            })
          ),
          map((transfer) => this.resolveTransferInstrumentsOwnershipTypes(transfer)),
          map((transfer) => (createDuplicate ? transfer.cloneAsNew() : transfer))
        );
      })
    );
  }

  private resolveTransferInstrumentsOwnershipTypes(transfer: Transfer): Transfer {
    const ownershipTypes = this.config.getAllOwnershipTypes();
    for (const transfInstrument of transfer.transferInstruments) {
      // find the setup instr info for the current transfer instrument
      const setupInstr = transfer.transferSetup.setupInstruments.find(
        (setupInstr) => (setupInstr.instrument_number = transfInstrument.instrument_number)
      )!;
      const $ownershipType = ownershipTypes.find((ownshipType) => ownshipType.id === setupInstr.ownership_types_id);
      transfer = transfer.updateTransfInstrument(transfInstrument.instrument_number - 1, { $ownershipType });
    }
    return transfer;
  }

  /**
   * Return the list of all transfer setups, either from the cache if it's set,
   * or from the backend if the cache isn't set.
   */
  getAllTransferSetups$(): Observable<TransferSetup[]> {
    return of(this._transferSetups).pipe(
      mergeMap((transferSetups) => (transferSetups ? of(transferSetups) : this.transferSetupService.getAll$())),
      tap((transferSetups) => (this._transferSetups = transferSetups))
    );
  }

  save$(transfer: Transfer): Observable<Transfer> {
    const saveTransfer$ = transfer.id ? this.update$(transfer) : this.postOne$(transfer);

    return saveTransfer$.pipe(
      // Copy parent project and folder onto the saved transfer (to avoid reloading it completely)
      // (these info are used to build the redirection URL after save)
      map((savedTransfer) =>
        savedTransfer.clone({ parentProject: transfer.parentProject, parentFolder: transfer.parentFolder })
      )
    );
  }

  /**
   * Update an existing transfer.
   *
   * Why a dedicated method?
   * Because sending JSON with "_embedded" related entities is only supported for HTTP POST.
   */
  update$(transfer: Transfer): Observable<Transfer> {
    const saveTransfer$ = this.putOne$(transfer, transfer.id);
    const saveTransferInstruments$ = this.transferInstrumentService.saveAll$(transfer.transferInstruments);
    const saveTransferPersons$ = this.transferPersonService.saveAll$(transfer.transferPersons);

    return forkJoin([saveTransfer$, saveTransferPersons$, saveTransferInstruments$]).pipe(
      switchMap(() => this.getById$(transfer.id, { projectId: transfer.parentProject.id }))
    );
  }

  delete$(transfer: Transfer): Observable<Transfer> {
    return this.deleteById$(transfer.id).pipe(map(() => transfer));
  }

  async showMoveTransferDialog(transfer: Transfer) {
    return firstValueFrom(
      this.dialog.open(DialogMoveTransferComponent, { width: DIALOG_WIDTH_LARGE, data: { transfer } }).afterClosed()
    );
  }

  moveTransfer$(transfer: Transfer, newFolder: Folder): Observable<any> {
    return this.patchOne$(transfer.id, { folders_id: newFolder.id });
  }

  isTransferReviewed(transfer: Transfer) {
    return transfer.validation_status === this.settingsService.get<number>('TRANSFER_VALIDATION_STATUS_REVIEWED')!;
  }

  isTransferNotReviewed(transfer: Transfer) {
    return transfer.validation_status === this.settingsService.get<number>('TRANSFER_VALIDATION_STATUS_NOT_REVIEWED')!;
  }

  isTransferValidated(transfer: Transfer): boolean {
    return transfer.validation_status === this.settingsService.get<number>('TRANSFER_VALIDATION_STATUS_VALIDATED');
  }

  setTransferReviewed(transfer: Transfer) {
    return this.patchOne$(transfer.id, {
      validation_status: this.settingsService.get('TRANSFER_VALIDATION_STATUS_REVIEWED'),
    });
  }

  setTransferNotReviewed(transfer: Transfer) {
    return this.patchOne$(transfer.id, {
      validation_status: this.settingsService.get('TRANSFER_VALIDATION_STATUS_NOT_REVIEWED'),
    });
  }
}
