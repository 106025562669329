import { HALDeserializeFrom, HALOptional } from '@app/common/services/hal-serializer.service';
import { World2 } from './world.model';

export class RelScopeToWorld {
  @HALDeserializeFrom()
  scopes_id: number = 0;

  @HALDeserializeFrom()
  worlds_id: number = 0;

  @HALDeserializeFrom()
  world!: World2;

  @HALDeserializeFrom()
  status: number = 0;

  @HALDeserializeFrom()
  @HALOptional()
  comment: string = '';
}
