import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JsHelper } from '@app/common/helpers';
import { TableColumnDef } from '@app/common/models';
import { PersonTypeToTranslatedLabelPipe } from '@app/common/pipes/person-type-to-translated-label.pipe';
import { Person, Project } from '@app/project/models';
import { PersonService } from '@app/project/services/person.service';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, lastValueFrom, of } from 'rxjs';
import { DialogItemSelectorComponent } from '../dialog-item-selector/dialog-item-selector.component';
import { PersonAdditionalInfoComponent } from '../person-additional-info/person-additional-info.component';

export interface DialogFundManagerSelectorSharedData {
  project?: Project;
  disablePersonCreation?: boolean;
}

@Component({  standalone: true, imports:[DialogItemSelectorComponent, TranslateModule, NgIf, AsyncPipe, PersonTypeToTranslatedLabelPipe, PersonAdditionalInfoComponent, MatButtonModule],
  selector: 'parteng-dialog-fund-manager-selector-shared',
  template: `
    <section class="dialog-fund-manager-selector">
      <parteng-dialog-item-selector
        *ngIf="fundManagers$ | async as fundManagers"
        [dialogTitle]="'project.dialogFundManagerSelector.title' | translate"
        [dialogDescription]="'project.dialogFundManagerSelector.description' | translate"
        [selectedItemsTitle]="'project.dialogFundManagerSelector.selectedItemsTitle' | translate"
        [selectedItemsDescription]="'project.dialogFundManagerSelector.selectedItemsDescription' | translate"
        [itemAdditionalInfoTitle]="'shared.dialogPersonSelector.itemAdditionalInfoTitle' | translate"
        [itemAdditionalInfoHTML]="itemAdditionalInfoHTML"
        [selectedItemPreviewHTML]="selectedItemPreviewHTML"
        [columnDefs]="columnDefs"
        [allItems]="fundManagers"
        [filterItemFn]="filterItemFn"
        [isMonoSelection]="true"
        data-testId="dialog-fund-manager-selector"
      >
        <!-- Additional Info -->
        <ng-template #itemAdditionalInfoHTML let-person="item">
          <parteng-person-additional-info [person]="person"></parteng-person-additional-info>
        </ng-template>
        <!-- Selected Item Preview -->
        <ng-template #selectedItemPreviewHTML let-person="item">
          <div class="flex text-sm">
            <div class="w-16">{{ person.id }}</div>
            <div class="flex-auto">{{ person.name }}</div>
            <div class="flex-none">{{ person.person_type | personTypeToTranslatedLabel }}</div>
          </div>
        </ng-template>
        <!-- Add Item Button -->
        <button
          mat-stroked-button
          color="primary"
          *ngIf="!dialogParams.disablePersonCreation"
          type="button"
          (click)="openCreateFundManagerDialog()"
        >
          {{ 'project.dialogFundManagerSelector.createItem' | translate }}
        </button>
      </parteng-dialog-item-selector>
    </section>
  `,
})
export class DialogFundManagerSelectorSharedComponent implements OnInit {
  fundManagers$: Observable<Person[]> = of([]);
  columnDefs: TableColumnDef[] = [
    { key: 'id', labelTranslateKey: 'shared.dialogPersonSelector.columnId' },
    { key: 'name', labelTranslateKey: 'shared.dialogPersonSelector.columnName' },
    { key: 'short_name', labelTranslateKey: 'shared.dialogPersonSelector.columnShortName' },
    {
      key: 'person_type',
      labelTranslateKey: 'shared.dialogPersonSelector.columnType',
      pipeName: 'personTypeToTranslatedLabel',
    },
  ];

  constructor(
    private personService: PersonService,
    private diaglogRef: MatDialogRef<DialogFundManagerSelectorSharedComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: DialogFundManagerSelectorSharedData
  ) {}

  async ngOnInit() {
    this.refreshList();
  }

  filterItemFn(item: Person, filter: string) {
    return JsHelper.ObjPropsContainString(item, filter, [
      'id',
      'name',
      'legal_entity_identifier',
      'comment',
      'short_name',
    ]);
  }

  async openCreateFundManagerDialog() {
    const newFundManager = await lastValueFrom(
      this.personService.showFundManagerCreationDialog(this.dialogParams.project)
    );
    if (newFundManager) {
      const createdFundManager = await lastValueFrom(this.personService.save$(newFundManager));
      this.diaglogRef.close([createdFundManager]);
    }
    this.refreshList();
  }

  async refreshList() {
    this.fundManagers$ = of(
      (await lastValueFrom(this.personService.getAll$())).filter((person) => this.personService.isLegalPerson(person))
    );
  }
}
