import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

export interface DialogConfirmData {
  title: string;
  description: string;
  labelConfirm: string;
  labelCancel: string;
}

@Component({  standalone: true, imports:[MatIconModule, TranslateModule, MatDialogModule, MatButtonModule],
  selector: 'parteng-dialog-confirm',
  template: `
     <!-- Close Button -->
     <h2 mat-dialog-title align="end" class="px-5">
      <mat-icon
        aria-hidden="false"
        [mat-dialog-close]="false"
        [attr.aria-label]="'shared.dialog.closeAriaLabel' | translate"
        class="text-neutral-500 cursor-pointer picto"
        >close</mat-icon
      >
    </h2>

    <div class="mx-auto py-16">
      <h1 mat-dialog-title class="text-center text-2xl font-semibold">
        {{ data.title }}
      </h1>
      <div mat-dialog-content class="flex flex-col items-center">
        <div class="border-t border-neutral-500 w-[280px] pb-4 mb-4"></div>
        <p class="text-base text-center w-[600px]" [innerHTML]="data.description"></p>
        <div class="border-t border-neutral-500 w-[280px] pb-4 mt-4"></div>
      </div>
      <mat-dialog-actions class="flex justify-around items-center">
        <button
          mat-raised-button
          [color]="'primary'"
          [mat-dialog-close]="true"
          cdkFocusInitial
        >
          {{ data.labelConfirm }}
        </button>
        <button mat-button [mat-dialog-close]="false">
          {{ data.labelCancel }}
        </button>
      </mat-dialog-actions>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogConfirmComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogConfirmData) {}
}
