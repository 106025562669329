import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ICompletedService } from './task.service';

@Injectable({ providedIn: 'root' })
export class CompletedService implements ICompletedService {
  
  #snackBar = inject(MatSnackBar);

  onCompleted(param: string): void {
    if (!param) {
      return;
    }
    this.#snackBar.open(param, '', {
      duration: 3000,
      panelClass: ['success-snackbar'],
    });
  }
}
