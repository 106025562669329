import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '@app/common/angular-material.module';
import { ProjectValidationStatusComponent } from '@app/common/components/project-validation-status.component';
import { ProjectValidationStatusIdPipe } from '@app/common/pipes/project-validation-status-id.pipe';
import { ProjectValidationStatusLabelPipe } from '@app/common/pipes/project-validation-status-label.pipe';
import { ProjectValidationStatus } from '@app/project/models/project-validation-status.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    AngularMaterialModule,
    TranslateModule,
    ProjectValidationStatusComponent,
    ProjectValidationStatusIdPipe,
    ProjectValidationStatusLabelPipe,
  ],
  standalone: true,
  selector: 'parteng-projects-list-exclusive-filters',
  template: `
    <div class="border border-blue-ptg-primary-300 rounded h-12 inline-block flex items-center max-w-fit">
      <mat-radio-group
        [attr.aria-label]="'project.projectStatusRadioGroup.ariaLabel' | translate"
        class="text-sm"
        (change)="onStatusFilterChanged($event)"
      >
        <mat-radio-button
          *ngFor="let statusFilter of statusFilters"
          [value]="statusFilter"
          [checked]="statusFilter === initialFilter"
          class="mr-4"
        >
          <parteng-project-validation-status
            *ngIf="statusFilter !== 'PROJECT_VALIDATION_STATUS_ALL'"
            [projectValidationStatusId]="statusFilter | projectValidationStatusId"
          ></parteng-project-validation-status>
          {{ statusFilter | projectValidationStatusLabel }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsListExclusiveFiltersComponent {
  @Input() initialFilter: ProjectValidationStatus = 'PROJECT_VALIDATION_STATUS_ALL';
  @Input() statusFilters: Array<ProjectValidationStatus> = [];

  @Output() statusFilterChanged = new EventEmitter<ProjectValidationStatus[]>();

  onStatusFilterChanged(event: MatRadioChange): void {
    this.statusFilterChanged.emit(event.value !== 'PROJECT_VALIDATION_STATUS_ALL' ? [event.value] : this.statusFilters);
  }
}
