export interface EnvironmentColor {
  envName: EnvironmentName;
  headerColor: string;
}

export enum EnvironmentName {
  'development' = 'Développement',
  'e2e' = 'Testing',
  'production' = 'Production',
  'testing' = 'UAT',
  'training' = 'Training',
  'unknown' = 'Env Inconnu',
}

export interface EnvironmentColors {
  [subDomain: string]: EnvironmentColor;
}

export const environmentColorsByApiEnvName: EnvironmentColors = {
  development: { envName: EnvironmentName.development, headerColor: 'bg-orange-ptg-dev' },
  e2e: { envName: EnvironmentName.e2e, headerColor: 'bg-red-ptg-e2e' },
  production: { envName: EnvironmentName.production, headerColor: 'bg-blue-ptg-primary' },
  testing: { envName: EnvironmentName.testing, headerColor: 'bg-red-ptg-uat' },
  training: { envName: EnvironmentName.training, headerColor: 'bg-green-ptg-training' },
};

export const unknownEnvironmentColor: EnvironmentColor = {
  envName: EnvironmentName.unknown,
  headerColor: '#00000',
};

export const environmentNicknameMapping: Record<EnvironmentName, string> = {
  [EnvironmentName.development]: 'Dev',
  [EnvironmentName.e2e]: 'Testing',
  [EnvironmentName.production]: 'Prod',
  [EnvironmentName.testing]: 'UAT',
  [EnvironmentName.training]: 'Training',
  [EnvironmentName.unknown]: '',
};
